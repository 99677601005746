import moment, { Moment } from 'moment';
import { observable } from 'mobx';
import { EquipmentFileMetadataResponseDTO } from '../dto/response/equipment-file-metadata.response.dto';

export class EquipmentFileModel {
  @observable id?: string;
  @observable creationDate: Moment;
  @observable fileName: string;
  @observable fileSize: number;

  constructor(data: EquipmentFileModel) {
    this.id = data.id;
    this.creationDate = data.creationDate;
    this.fileName = data.fileName;
    this.fileSize = data.fileSize;
  }

  static createFromDTO(dto: EquipmentFileMetadataResponseDTO) {
    return new EquipmentFileModel({
      id: dto.id,
      creationDate: moment(dto.creationDate),
      fileName: dto.fileName,
      fileSize: dto.fileSize,
    });
  }
}
