import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, Form, Input, Spin } from 'antd';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { LockTwoTone } from '@ant-design/icons';
import { Helmet } from 'react-helmet-async';
import AuthFormContainer from '../../../components/AuthFormContainer/index';
import { lazyInject } from '../../../utils/IoC';
import { FinishResetPasswordStore } from '../store/reset-password/FinishResetPasswordStore';

import styles from '../index.module.less';
import { action, observable } from 'mobx';
import { FormError } from '../../../utils/error/FormError';
import { RouterStore } from 'mobx-react-router';

type Params = {
  token: string,
}

type Props = RouteComponentProps<Params>;

@observer
class FinishResetPasswordPage extends Component<Props> {
  @lazyInject(FinishResetPasswordStore)
  store!: FinishResetPasswordStore;

  @lazyInject(RouterStore)
  routerStore!: RouterStore;

  @observable error?: FormError;

  componentDidMount() {
    const { token } = this.props.match.params;
    this.store.fetchResetTokenStatus(token).finally();
  }

  @action
  handleChange = () => {
    this.error = undefined;
  };

  @action
  handleFinish = ({ password, repeatPassword }: { password: string, repeatPassword: string }) => {
    this.error = undefined;

    if (password !== repeatPassword) {
      const description = 'Введенные пароли не совпадают';

      this.error = new FormError({
        password: description,
        repeatPassword: description,
      });

      return;
    }

    const { token } = this.props.match.params;
    this.store.resetPasswordAttempt(token, password)
      .then(this.handleResetPasswordAttemptSuccess)
      .catch(this.handleResetPasswordAttemptError)
    ;
  };

  @action.bound
  handleResetPasswordAttemptSuccess() {
    this.routerStore.push('/');
  }

  @action.bound
  handleResetPasswordAttemptError(error: FormError) {
    this.error = error;
  }

  renderTokenStatusLoading() {
    return (
      <>
        <Form.Item>
          <h1>Восстановление пароля</h1>
        </Form.Item>

        <Form.Item style={{ textAlign: 'center' }}>
          <Spin size='large' />
        </Form.Item>

        <div className={styles.cancel}>
          <Link to='/auth/reset-password'>
            Отмена
          </Link>
        </div>
      </>
    )
  }

  renderInvalidToken() {
    return (
      <>
        <Form.Item>
          <h1>Восстановление пароля</h1>
          <p>Используемая ссылка недействительна</p>
        </Form.Item>

        <div className={styles.cancel}>
          <Link to='/auth/reset-password'>
            Назад
          </Link>
        </div>
      </>
    );
  }

  renderChangePassword() {
    const hasPasswordError = !!(this.error?.errors?.password);
    const hasRepeatPasswordError = !!(this.error?.errors?.repeatPassword);

    return (
      <>
        <Form.Item>
          <h1>Восстановление пароля</h1>
          <p>Для восстановления доступа укажите ваш новый пароль и нажмите кнопку "Продолжить"</p>
        </Form.Item>

        <Form.Item
          name="password"
          hasFeedback={hasPasswordError}
          validateStatus={hasPasswordError ? 'error' : undefined}
          help={this.error?.errors?.password}
        >
          <Input.Password
            id="password"
            placeholder='Придумайте новый пароль'
            size='large'
            prefix={<LockTwoTone twoToneColor='#D21919'/>}
            onChange={this.handleChange}
          />
        </Form.Item>

        <Form.Item
          name="repeatPassword"
          hasFeedback={hasRepeatPasswordError}
          validateStatus={hasRepeatPasswordError ? 'error' : undefined}
          help={this.error?.errors?.repeatPassword}
        >
          <Input.Password
            id="repeatPassword"
            placeholder='Повторите новый пароль'
            size='large'
            prefix={<LockTwoTone twoToneColor='#D21919'/>}
            onChange={this.handleChange}
          />
        </Form.Item>

        <Form.Item>
          <Button
            disabled={this.store.loading}
            loading={this.store.loading}
            size="large"
            className={styles.submit}
            type="primary"
            htmlType="submit"
          >
            Продолжить
          </Button>
        </Form.Item>

        <div className={styles.cancel}>
          <Link to='/auth/login'>
            Отмена
          </Link>
        </div>
      </>
    );
  }

  render() {
    return (
      <AuthFormContainer>
        <Helmet>
          <title>Integrity | Восстановление пароля</title>
        </Helmet>

        <Form
          onFinish={this.handleFinish}
        >
          {!this.store.isStatusLoaded
            ? this.renderTokenStatusLoading()
            : (!this.store.isTokenValid ? this.renderInvalidToken() : this.renderChangePassword())
          }
        </Form>
      </AuthFormContainer>
    );
  }
}

export default withRouter(FinishResetPasswordPage);
