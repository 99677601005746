import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { CheckCircleTwoTone, StopTwoTone, InfoCircleTwoTone } from '@ant-design/icons';
import { Button, Card, Col, notification, Row, Skeleton, Table } from 'antd';
import { observer } from 'mobx-react';
import { action, observable, toJS } from 'mobx';
import { lazyInject } from '../../utils/IoC';
import { Redirect } from 'react-router';
import { Moment } from 'moment';
import { MomentDateTimeTzFormat } from '../../utils/MomentDateFormat';
import { CategoryAccessStore } from '../../stores/users/CategoryAccessStore';
import { getTableTextSearchDropdownProps } from '../../components/Table/TableTextSearchDropdown';
import { getTableDateFilterDropdownProps } from '../../components/Table/TableDateFilterDropdown';
import { getTableFactoryFilterDropdownProps } from '../../components/Table/TableFactoryFilterDropdown';
import { getTableStatusPrinterControllerFilterDropdownProps } from '../../components/Table/TableStatusPrinterControllerDropdown';
import { getTableNumberRangeFilterDropdownProps } from '../../components/Table/TableNumberRangeFilterDropdown';

import { PrinterControllerTableStore } from '../../stores/equipment/PrinterControllerTableStore';
import EquipmentTableRowAction from './component/EquipmentTableRowAction/index';
import { PrinterControllerModel } from '../../stores/equipment/model/PrinterControllerModel';
import PrinterControllerEditModal from './component/PrinterControllerEditModal/index';

@observer
class PrinterControllerPage extends Component {
	@lazyInject(PrinterControllerTableStore)
	store!: PrinterControllerTableStore;

	@lazyInject(CategoryAccessStore)
	categoryAccessStore!: CategoryAccessStore;

	@observable isModalVisible = { create: false, edit: false };
	@observable currentEditId: string = "";

	deleteRecord = (id: number) => {
		this.store.deleteItem(id).catch(() => notification.error({ message: 'Не удалось удалить оборудование' }));
	};

	@action
	openCreateModal = () => {
		this.isModalVisible.create = true;
	};

	@action
	closeCreateModal = () => {
		this.isModalVisible.create = false;
	};

	@action
	openEditModal = (id: string) => {
		this.currentEditId = id;
		this.isModalVisible.edit = true;
	};

	@action
	closeEditModal = () => {
		this.isModalVisible.edit = false;
		this.currentEditId = "";
	};

	componentDidMount() {
		this.store.fetchAll();
	}

	render() {
		if (!this.categoryAccessStore.loading && !this.categoryAccessStore.hasEquipmentAccess) {
			return <Redirect to='/' />;
		}

		const columns = [
			{
				title: null,
				key: "index",
				render: (value: any, item: any, index: number) => {
					const p = this.store.tablePagination;
					return (<a href="#" onClick={() => this.openEditModal(item.id)}>{p.pageSize * (p.current-1)+1+index}</a>);
				},
			},
			{
				title: "№",
				dataIndex: "serial_number",
				sorter: { multiple: -1 },
				sortOrder: this.store.tableAppliedSorters["serial_number"],
			}, {
				title: "Завод",
				dataIndex: "factory",
				sorter: { multiple: -1 },
				sortOrder: this.store.tableAppliedSorters["factory"],
				filteredValue: (this.store.filters.factory as string[]) || null,
				...getTableFactoryFilterDropdownProps(),

				render: (factory: { id: string; name: string }) => {
					return (
						<div className="editable-cell-value-wrap">
							{factory?.name || "Отсутствует"}
						</div>
					);
				},
			}, {
				title: "Модель принтера",
				dataIndex: "printer_model",
				sorter: { multiple: -1 },
				sortOrder: this.store.tableAppliedSorters["printer_model"],
				filteredValue: (this.store.filters.printer_model as string[]) || null,
				...getTableTextSearchDropdownProps("Поиск по модели принтера"),
			}, {
				title: "Статус",
				dataIndex: "status",
				sorter: { multiple: -1 },
				sortOrder: this.store.tableAppliedSorters["status"],
				filteredValue: (this.store.filters.status as string[]) || null,
				...getTableStatusPrinterControllerFilterDropdownProps(),
				render: (status: string) => {
					let ret;
					if(status === "printing") {
						ret = (<CheckCircleTwoTone twoToneColor="#52c41a" title="Печатает" />);
					} else if(status === "online") {
						ret = (<InfoCircleTwoTone twoToneColor="#ffa500" title="В сети, не печатает" />);
					} else {
						ret = (<StopTwoTone twoToneColor="#ff0000" title="Не в сети" />);
					}
					return ret;
				},
			}, {
				title: "Последний раз в сети",
				dataIndex: "last_online",
				sorter: { multiple: -1 },
				sortOrder: this.store.tableAppliedSorters["last_online"],
				filteredValue: (this.store.filters.last_online as string[]) || null,
				...getTableDateFilterDropdownProps(),
				render: (date: Moment) => {
					return (
						<div className="editable-cell-value-wrap">
							{(date) ? date.format(MomentDateTimeTzFormat) : ""}
						</div>
					);
				},
			}, {
				title: "Последняя выгрузка кодов",
				dataIndex: "last_sync",
				sorter: { multiple: -1 },
				sortOrder: this.store.tableAppliedSorters["last_sync"],
				filteredValue: (this.store.filters.last_sync as string[]) || null,
				...getTableDateFilterDropdownProps(),
				render: (date: Moment) => {
					return (
						<div className="editable-cell-value-wrap">
							{(date) ? date.format(MomentDateTimeTzFormat) : ""}
						</div>
					);
				},
			}, {
				title: "Кодов в очереди синхронизации",
				dataIndex: "num_codes_to_sync",
				sorter: { multiple: -1 },
				sortOrder: this.store.tableAppliedSorters["num_codes_to_sync"],
				//filteredValue: (this.store.filters.num_codes_to_sync as string[]) || null,
				render: (n: number) => {
					if(n <= 0) { return; }
					return (<div className="editable-cell-value-wrap">{n}</div>);
				},
			}, {
				title: "Версия",
				dataIndex: "version",
				sorter: { multiple: -1 },
				sortOrder: this.store.tableAppliedSorters["version"],
				filteredValue: (this.store.filters.version as string[]) || null,
				...getTableNumberRangeFilterDropdownProps(),
			}, {
				title: "Обновить до версии",
				dataIndex: "update_to_version",
				sorter: { multiple: -1 },
				sortOrder: this.store.tableAppliedSorters["update_to_version"],
				filteredValue: (this.store.filters.update_to_version as string[]) || null,
				...getTableNumberRangeFilterDropdownProps(),
			}, {
				title: "Действия",
				render: (text: string, record: PrinterControllerModel) => {
					return (
						<EquipmentTableRowAction
							id={record.id as any}
							onEdit={(id) => this.openEditModal(id as string)}
						/>
					);
				},
			},
		];

		return (
			<PageHeaderWrapper>
				{this.isModalVisible.edit && this.currentEditId !== "" &&
					<PrinterControllerEditModal
						visible={this.isModalVisible.edit}
						onClose={this.closeEditModal}
						id={this.currentEditId}
					/>
				}

				<Card>
				  <Row gutter={[16, 16]}>
					<Col
					  xs={{ span: 24 }}
					  sm={{ span: 24 }}
					  md={{ span: 8 }}
					  lg={{ span: 8 }}
					  xl={{ span: 6 }}
					>
					  {/*<Button
						size="large"
						block
						type="primary"
						onClick={this.openCreateModal}
					  >
						Добавить оборудование
					  </Button>*/}
					</Col>
					<Col
					  xs={{ span: 24 }}
					  sm={{ span: 24 }}
					  md={{ span: 5, offset: 6 }}
					  lg={{ span: 4, offset: 8 }}
					  xl={{ span: 4, offset: 10 }}
					>
					  <Button size="large" block onClick={this.store.handleTableRefresh}>
						Обновить
					  </Button>
					</Col>
					<Col
					  xs={{ span: 24 }}
					  sm={{ span: 24 }}
					  md={{ span: 5 }}
					  lg={{ span: 4 }}
					  xl={{ span: 4 }}
					>
					  <Button size="large" block onClick={this.store.handleTableReset}>
						Сбросить
					  </Button>
					</Col>
				  </Row>

				  <div style={{overflow: "auto"}}>
				  <Table
					loading={this.store.loading}
					columns={columns}
					rowKey={(record) => record.id as string}
					dataSource={toJS(this.store.items)}
					pagination={{
					  position: ["bottomRight"],
					  showSizeChanger: true,
					  ...this.store.tablePagination,
					}}
					locale={{
					  emptyText: this.store.loading ? (
						<>
						  <Skeleton />
						  <Skeleton />
						  <Skeleton />
						</>
					  ) : (
						<p>Данные отсутствуют</p>
					  ),
					}}
					onChange={this.store.handleTableChange}
					onRow={(record, rowIndex) => {
						return {
							onDoubleClick: event => {
								this.openEditModal(record.id as string);
							},
						};
					}}
				  />
				  </div>
				</Card>
			</PageHeaderWrapper>
		);
	}
};

export default PrinterControllerPage;
