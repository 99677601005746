import { Moment } from 'moment';
import { lazyInject, provide } from '../../utils/IoC';
import { HttpClientV1TwoApi } from '../../utils/api/HttpClientV1twoApi';
import { BaseTableStore } from '../../utils/store/BaseTableStore';
import { PrinterControllerModel } from './model/PrinterControllerModel';
import { PrinterControllerResponseDTO } from './dto/response/printer-controller.response.dto';

@provide.singleton()
export class PrinterControllerTableStore
		extends BaseTableStore<PrinterControllerModel, PrinterControllerResponseDTO> {
	@lazyInject(HttpClientV1TwoApi)
	protected readonly httpClientV1!: HttpClientV1TwoApi;
	protected readonly httpClient = this.httpClientV1;
	protected readonly path = '/equipment/printer_controllers';

	protected get searchDTO(): object {
		return {
		};
	}

	protected get filterDTO(): object {
		return {
			factory_id: this.filters.factory
				? (this.filters.factory as Array<{ id: string }>).map((v) => v.id)
				: undefined,
			last_online: this.filters.last_online
				? {
					from: this.filters.last_online[0]
						? (this.filters.last_online[0] as Moment).toDate().getTime()
						: undefined,
					to: this.filters.last_online[1]
						? (this.filters.last_online[1] as Moment).toDate().getTime()
						: undefined,
				} : undefined,
			last_sync: this.filters.last_sync
				? {
					from: this.filters.last_sync[0]
						? (this.filters.last_sync[0] as Moment).toDate().getTime()
						: undefined,
					to: this.filters.last_sync[1]
						? (this.filters.last_sync[1] as Moment).toDate().getTime()
						: undefined,
				} : undefined,
			status: this.filters.status
				? (this.filters.status as Array<string>)[0]
				: undefined,
			printer_model: this.filters.printer_model
				? this.filters.printer_model
				: undefined,
			version: this.filters.version
				? {
					from: this.filters.version[0],
					to: this.filters.version[1],
				} : undefined,
			update_to_version: this.filters.update_to_version
				? {
					from: this.filters.update_to_version[0],
					to: this.filters.update_to_version[1],
				} : undefined,
		};
	}

	protected createModelFromResponseDTO(dto: PrinterControllerResponseDTO)
			: PrinterControllerModel {
		return PrinterControllerModel.createFromDTO(dto);
	}
};
