import React, { Component } from 'react';
import TableButtonFiltersDropdown from './TableButtonFiltersDropdown';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import PriceAutoCompleteSelect from '../AutoCompleteSelect/PriceAutoCompleteSelect/index';

interface Props extends FilterDropdownProps {
  placeholder?: string;
}

export const getTablePriceFilterDropdownProps = () => ({
  filterDropdown: (props: FilterDropdownProps) => (
    <TablePriceFilterDropdown {...props} />
  ),
});

class TablePriceFilterDropdown extends Component<Props> {
  handleChange = (price?: { id: string; name: string }) => {
    this.props.setSelectedKeys(
      !price ? [] : [(price as unknown) as string]
    );
  };

  render() {
    const { clearFilters, confirm } = this.props;

    return (
      <div style={{ padding: "8px" }}>
        <PriceAutoCompleteSelect
          style={{ width: "100%", marginBottom: "8px" }}
          onChange={this.handleChange}
        />

        <TableButtonFiltersDropdown
          handleReset={clearFilters}
          handleSearch={confirm}
        />
      </div>
    );
  }
}

export default TablePriceFilterDropdown;
