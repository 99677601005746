import { lazyInject, provide } from '../../../utils/IoC';
import { HttpClientV1 } from '../../../utils/api/HttpClientV1';
import { action, computed } from 'mobx';
import { PricesTableStore } from './PricesTableStore';
import { AxiosResponse } from 'axios';
import { FormError } from '../../../utils/error/FormError';
import { PriceModel } from './model/PriceModel';
import { UpdatePriceRequestDTO } from './dto/request/update-price.request.dto';
import { PriceResponseDTO } from './dto/response/prices.response.dto';

@provide.singleton()
export class PriceEditModalStore {
  @lazyInject(HttpClientV1)
  protected readonly httpClientV1!: HttpClientV1;

  @lazyInject(PricesTableStore)
  tableStore!: PricesTableStore;

  @computed
  get itemsMap() {
    return this.tableStore.itemsMap;
  }

  @action
  updatePrice(id: string, model: PriceModel) {
    return this.httpClientV1.put<UpdatePriceRequestDTO, PriceResponseDTO>(
      `prices/${id}`,
      {
        name: model.name!,
        datamatrix: model.datamatrix!,
        barcode: model.barcode!,
        startDate: model.startDate
          ? model.startDate.toDate().getTime()
          : undefined,
        endDate: model.endDate
          ? model.endDate.toDate().getTime()
          : undefined,
		barcode_pricing: model.barcode_pricing,
      },
    )
      .then(this.onUpdatePriceSuccess)
      .catch(this.onUpdatePriceError) as Promise<PriceModel>;
  }

  @action.bound
  protected onUpdatePriceSuccess(data: PriceResponseDTO) {
    const model = PriceModel.createFromDTO(data);

    if (this.itemsMap.has(data.id)) {
      this.itemsMap.set(model.id as string, model);
    }

    return model;
  }

  @action.bound
  protected onUpdatePriceError(reason: AxiosResponse) {
    if (reason.status === 400) {
      throw FormError.createFromBadRequestResponseDTO(reason.data);
    }

    const { description } = reason.data.message[0];
    throw new Error(description);
  }
}
