import React, { Component } from 'react';
import { Button, Col, DatePicker, Form, Input, Modal, notification, Row, Upload, Select, Collapse } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { observer } from 'mobx-react';
import FactoryAutoCompleteSelect from '../../../../components/AutoCompleteSelect/FactoryAutoCompleteSelect/index';

import styles from './index.module.less';
import { lazyInject } from '../../../../utils/IoC';
import { action, computed, observable } from 'mobx';
import { FormInstance } from 'antd/lib/form';
import moment, { Moment } from 'moment';
import { FormError } from '../../../../utils/error/FormError';
import { EquipmentFactoryModel } from '../../../../stores/equipment/model/EquipmentFactoryModel';
import { PrinterControllerModel } from '../../../../stores/equipment/model/PrinterControllerModel';
import {
	PrinterControllerConfigWiredModel,
	PrinterControllerConfigWirelessModel,
} from '../../../../stores/equipment/model/PrinterControllerConfigModel';
import { PrinterControllerEditModalStore } from '../../../../stores/equipment/PrinterControllerEditModalStore';
import { MomentDateTimeTzFormat } from '../../../../utils/MomentDateFormat';

type FormParams = PrinterControllerModel;

interface Props {
  visible: boolean;
  onClose: () => void;
  id: string;
}

@observer
class PrinterControllerEditModal extends Component<Props> {
	@lazyInject(PrinterControllerEditModalStore)
	store!: PrinterControllerEditModalStore;

	@observable loading: boolean = false;
	@observable error?: FormError;

	refForm = React.createRef<FormInstance>();
	model_loaded = false;
	update_versions: Array<number> = [];
	selected_update_version: number = 0;
	upgrade_log = "";

	@computed
	get model() {
		return this.store.itemsMap.get(this.props.id);
	}

	@action
	handleChange = (key: string) => {
		delete this.error?.errors[key];
	}

	@action
	handleSave = () => {
		const t = this;
		this.saveFormToModel();
		if(this.model) {
			this.store.updatePrinterController(this.model)
			.then((r) => {
				t.handleSaveSuccess();
			})
			.catch((e) => {
				t.handleSaveError(e);
			});
		}
	};

	@action.bound
	handleSaveSuccess() {
		this.loading = false;
		this.refForm.current?.resetFields();

		notification.success({ message: `Оборудование успешно сохранено` });
		this.props.onClose();
	}

	@action.bound
	handleSaveError(error: FormError) {
		this.error = error;
		this.loading = false;

		if(!(error instanceof FormError)) {
			console.log(error);
			let err = 'Не удалось сохранить оборудование';
			let e = error as any;
			console.log(e);
			if(e.data?.message) {
				err = err + ": " + e.data?.message;
			}
			notification.error({ message: err});
		}
	}

	@action
	handleCancel = () => {
		this.loading = false;
		this.refForm.current?.resetFields();

		this.props.onClose();
	}

	@action
	onUpdateVersionChange = (v: any) => {
		this.selected_update_version = (v as number);
		this.forceUpdate();
	};

	@action
	onUpdateButtonClick = () => {
		if(!this.model || this.selected_update_version == 0) {
			return;
		}
		this.store.handleUpdateRequest(this.model, this.selected_update_version).then((r) => {
			//console.log(r);
			notification.success({message: "Запланировано обновление до версии "+this.selected_update_version});
			if(this.model) {
				this.model.update_to_version = this.selected_update_version;
				this.saveFormToModel();
				this.forceUpdate();
			}
		}).catch((e) => {
			notification.error({message: "Ошибка обновления: "+e});
			console.error(e);
		});
	};

	@action
	onCancelUpdateButtonClick = () => {
		if(!this.model || this.model?.version === undefined) {
			return;
		}
		this.store.handleUpdateRequest(this.model, this.model.version).then((r) => {
			//console.log(r);
			notification.success({ message: "Обновление отменено" });
			if(this.model) {
				this.model.update_to_version = this.model.version;
				this.saveFormToModel();
				this.forceUpdate();
			}
			this.forceUpdate();
		}).catch((e) => {
			notification.error({message: "Ошибка отмены обновления: "+e});
			console.error(e);
		});
	};

	addWireless = () => {
		this.saveFormToModel();
		if(this.model?.config && !this.model?.config?.wireless) {
			this.model.config.wireless = new Array<PrinterControllerConfigWirelessModel>();
		}
		this.model?.config?.wireless?.push(new PrinterControllerConfigWirelessModel({
			ssid: "",
			psk: "",
		}));
		this.forceUpdate();
		this.refForm.current?.setFieldsValue(this.model);
	};

	deleteWireless = (i: number) => {
		this.saveFormToModel();
		this.model?.config?.wireless?.splice(i, 1);
		this.forceUpdate();
		this.refForm.current?.setFieldsValue(this.model);
	};

	loadModel = () => {
		this.model_loaded = true;

		if(this.model) {
			const t = this;
			this.store.fetchPrinterControllerUpgradeVersions(this.model).then((r) => {
				if(r?.versions) {
					t.update_versions = (r.versions.map((v) => (v.version as number)));
				}
				t.forceUpdate();
			}).catch((e) => {console.error(e)});

			this.store.fetchUpgradeLog(this.model).then((r) => {
				if(r) {
					t.upgrade_log = r;
				}
				t.forceUpdate();
			}).catch((e) => {console.error(e)});

			if(this.model.num_codes_to_sync < 0) {
				this.model.num_codes_to_sync = 0;
			}
		}
	};

	saveFormToModel = () => {
		const { model } = this;
		const value = this.refForm.current?.getFieldsValue() as FormParams;
		const m_a = (model as any);
		const v_a = (value as any);
		if(!model || !value) { return; }

		Object.keys(value).forEach((k) => {
			if(v_a[k]) {
				m_a[k] = v_a[k];
			}
		});
	};

	render() {
		if(!this.model_loaded) {
			this.loadModel();
		}

		const { visible } = this.props;
		const { model } = this;
		const value = this.refForm.current?.getFieldsValue() as FormParams;

		const hasFactoryError = !!(this.error?.errors?.factoryId);

		return (
			<Modal
				title="Изменение настроек контроллера принтера"
				visible={visible}
				width="1000px" centered
				onCancel={this.handleCancel}
				footer={[
					<Button
						key="back"
						onClick={this.handleCancel}
					>
						Отмена
					</Button>,

					<Button
						key="submit"
						type="primary"
						disabled={this.loading}
						loading={this.loading}
						onClick={this.handleSave}
					>
						Сохранить
					</Button>,
				]}
			>
				<Form ref={this.refForm} layout="vertical">
					<Row gutter={[16, 16]}>
						<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
							<Form.Item
								label="UUID"
								name="id"
								key="id"
								initialValue={model?.id}
							>
								<Input
									placeholder="UUID"
									readOnly
								/>
							</Form.Item>

							<Form.Item
								label="Завод"
								name="factory"
								key="factory"

								hasFeedback={hasFactoryError}
								validateStatus={hasFactoryError ? 'error' : undefined}
								help={this.error?.errors?.factoryId}
							>
								<FactoryAutoCompleteSelect
									initialValue={model?.factory ? {
										id: model.factory.id as string,
										name: model.factory.name as string,
									} : undefined}

									onChange={() => this.handleChange("factoryId")}
									allowClear
								/>
							</Form.Item>

							<Form.Item
								label="Серийный номер"
								name="serial_number"
								initialValue={model?.serial_number}
							>
								<Input placeholder="Серийный номер" />
							</Form.Item>

							<Form.Item
								label="Комментарий"
								name="description"
								initialValue={model?.description}
							>
								<Input placeholder="Комментарий" />
							</Form.Item>

							<Form.Item
								label="Последний раз в сети"
								name="last_online"
								key="last_online"
								initialValue={model?.last_online}
							>
								<DatePicker
									format={MomentDateTimeTzFormat}
									className={styles.datepicker}
									disabled
								/>
							</Form.Item>

							<Form.Item
								label="Последняя выгрузка кодов"
								name="last_sync"
								key="last_sync"
								initialValue={model?.last_sync}
							>
								<DatePicker
									format={MomentDateTimeTzFormat}
									className={styles.datepicker}
									disabled
								/>
							</Form.Item>

							<Form.Item
								label="Кодов в очереди выгрузки"
								name="num_codes_to_sync"
								key="num_codes_to_sync"
								initialValue={model?.num_codes_to_sync}
							>
								<Input
									placeholder="#"
									readOnly
								/>
							</Form.Item>

							<span>Версия ПО</span>
							<Row>
							<Form.Item
								name="version"
								initialValue={model?.version}
							>
								<Input
									placeholder="#"
									readOnly
								/>
							</Form.Item>

							{(this.update_versions.length > 0 && (this.model?.update_to_version === undefined ||
								(this.model?.version && this.model?.update_to_version &&
									this.model?.update_to_version <= this.model?.version))) &&
							<>
								<Form.Item name="selected_update_version">
								<Select onChange={this.onUpdateVersionChange}>
									{this.update_versions.map((v) => {
										return (<Select.Option key={v} value={v}>{v}</Select.Option>)
									})}
								</Select>
								</Form.Item>

								{(this.selected_update_version && this.selected_update_version != this.model?.version &&
									this.selected_update_version != this.model?.update_to_version) != 0 &&
									<Button
										key="update_button"
										onClick={this.onUpdateButtonClick}>Обновить</Button>}
							</>
							}
							</Row>
							{(this.model?.update_to_version && this.model?.update_to_version != this.model?.version) &&
							<>
							<span>Запланировано обновление до версии {this.model?.update_to_version}</span>
							<br/>
							<a href="#" onClick={this.onCancelUpdateButtonClick}>отменить</a>
							</>
							}
						</Col>

						<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
							<Form.Item
								label="Модель принтера"
								name={["config", "printer_model"]}
								initialValue={model?.config?.printer_model}
							>
								<Input
									placeholder="Модель принтера"
								/>
							</Form.Item>

							<Form.Item
								label="Адрес принтера"
								name={["config", "printer_address"]}
								initialValue={model?.config?.printer_address}
							>
								<Input
									placeholder="IP адрес принтера"
								/>
							</Form.Item>

							<Form.Item
								label="Порт принтера"
								name={["config", "printer_port"]}
								initialValue={model?.config?.printer_port}
							>
								<Input
									placeholder="Порт принтера"
								/>
							</Form.Item>

							<Form.Item
								label="IP адрес контроллера"
								name={["config", "wired", "address"]}
								initialValue={model?.config?.wired?.address}
							>
								<Input
									placeholder="IP адрес контроллера"
								/>
							</Form.Item>

							<Form.Item
								label="Wifi"
								name="wireless"
								key="wireless"
							>
								<Button
									key="add_wireless"
									type="primary"
									onClick={this.addWireless}
								>Добавить</Button>

								<p/>

								<Input.Group>
								{model?.config?.wireless?.map((item, i) => {
									return <div key={"wireless_"+i}>
										<Row>
											<Form.Item
												name={["config", "wireless", i, "ssid"]}
												initialValue={item.ssid}
											>
												<Input placeholder="ssid" />
											</Form.Item>

											<Form.Item
												name={["config", "wireless", i, "psk"]}
												initialValue={item.psk}
											>
												<Input.Password placeholder="password"
													iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
												/>
											</Form.Item>

											<Button
												key={"delete_wireless_"+i}
												type="primary"
												onClick={() => this.deleteWireless(i)}
											>-
											</Button>
										</Row>
									</div>;
								})}
								</Input.Group>
							</Form.Item>

							<Collapse>
								<Collapse.Panel header="Журнал обновления" key="1">
									<Form.Item
										key="update_log"
									>
										<Input.TextArea maxLength={30} defaultValue={this.upgrade_log} rows={30} />
									</Form.Item>
								</Collapse.Panel>
							</Collapse>
						</Col>
					</Row>
				</Form>
			</Modal>
		);
	}
}

export default PrinterControllerEditModal;
