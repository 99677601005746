import React from 'react';

import { RouteSyntax } from './utils/router';
import { container } from './utils/IoC';

import BlankLayout from './layouts/BlankLayout';
import AuthLayout from './layouts/AuthLayout/index';
import NotFoundPage from './pages/exception/404';
import DashboardLayout from './layouts/DashboardLayout/index';

import IndexPage from './pages/index';
import LoginPage from './pages/auth/LoginPage';
import StartResetPasswordPage from './pages/auth/reset-password/StartResetPasswordPage';
import FinishResetPasswordPage from './pages/auth/reset-password/FinishResetPasswordPage';
import EquipmentPage from './pages/equipment/EquipmentPage';
import PrinterControllerPage from './pages/equipment/PrinterControllerPage';
import UsersPage from './pages/users/UsersPage';
import PricesPage from './pages/finance/prices/PricesPage';
import FactoryPage from './pages/factory/FactoryPage';
import TableProduct from './pages/factory/TableProduct';

import BalancePage from './pages/factory/BalancePage';
import HistoryFinance from './pages/factory/HistoryFinance';
import Codes from './pages/factory/Codes';
import FactoryStatPage from './pages/factory/FactoryStatPage';
import FactoryListStat from './pages/factory/FactoryListStat';
import RibbonImportList from './pages/factory/RibbonImportList';
import CodeSearchPage from './pages/factory/CodeSearchPage';
import FactoriesPricesPage from './pages/finance/factories-prices/FactoriesPricesPage';
import UsersIcon from './components/Icons/UsersIcon';
import FinancesIcon from './components/Icons/FinancesIcon';
import EquipmentIcon from './components/Icons/EquipmentIcon';
import { SecureLayout } from './layouts/SecureLayout';

import { CategoryAccessStore } from './stores/users/CategoryAccessStore';
import FactoryIcon from './components/Icons/FactoryIcon';

export const routes: RouteSyntax[] = [
  {
    path: '/',
    component: BlankLayout,
    routes: [
      {
        path: '/auth',
        component: AuthLayout,
        routes: [
          {
            path: '/auth/login',
            exact: true,
            component: LoginPage,
          },
          {
            path: '/auth/reset-password',
            exact: true,
            component: StartResetPasswordPage,
          },
          {
            path: '/auth/reset-password/:token',
            exact: true,
            component: FinishResetPasswordPage,
          },
          {
            component: NotFoundPage,
          },
        ],
      },
      {
        path: '/',
        component: SecureLayout,
        routes: [
          {
            path: '/',
            component: DashboardLayout,
            routes: [
              {
                path: '/',
                exact: true,
                component: IndexPage,
              },
              {
                name: 'Пользователи',
                icon: <UsersIcon />,
                path: '/users',
                exact: true,
                component: UsersPage,
                hasAccess: () => container.get(CategoryAccessStore).hasUsersAccess,
              },
              {
                name: 'Финансы',
                icon: <FinancesIcon />,
                path: '/finance',
                hasAccess: () => container.get(CategoryAccessStore).hasFinancesAccess,
                routes: [
                  {
                    name: 'Все тарифы',
                    path: '/finance/prices',
                    exact: true,
                    component: PricesPage,
                  },
                  {
                    name: 'Тарифы для заводов',
                    path: '/finance/factories-prices',
                    exact: true,
                    component: FactoriesPricesPage,
                  }
                ]
              },
              {
                name: 'Заводы',
                icon: <FactoryIcon />,
                path: '/factory',
                hasAccess: () => container.get(CategoryAccessStore).hasFactyoryAccess,
                routes: [
                  {
                    name: 'Все заводы',
                    path: '/factory/all',
                    exact: true,
                    component: FactoryPage,
                  },
                  {
                    name: 'Завод',
                    path: '/factory/stat',
                    exact: true,
                    component: FactoryStatPage,
                  },
                  {
                    name: 'Таблица изделий',
                    path: '/factory/product',
                    exact: true,
                    component: TableProduct,
                  },
                  {
                    name: 'Корректировка баланса',
                    path: '/factory/balance',
                    exact: true,
                    component: BalancePage,
                  },
                  {
                    name: 'История финансов',
                    path: '/factory/history',
                    exact: true,
                    component: HistoryFinance,
                  },
                  {
                    name: 'Коды',
                    path: '/factory/codes',
                    exact: true,
                    component: Codes,
                  },
                  {
                    name: 'Загрузка лент',
                    path: '/factory/ribbon_import',
                    exact: true,
                    component: RibbonImportList,
                  },
				  {
				  	name: 'Поиск кодов',
					path: '/factory/code_search',
					exact: true,
					component: CodeSearchPage,
				  },
                ]
              },
              {
                name: 'Оборудование',
                icon: <EquipmentIcon />,
                path: '/equipment',
                hasAccess: () => container.get(CategoryAccessStore).hasEquipmentAccess,
				routes: [
					{
						name: 'Список оборудования',
						path: '/equipment/equipment',
						exact: true,
						component: EquipmentPage,
					},
					{
						name: 'Принтеры',
						path: '/equipment/printer_controller',
						exact: true,
						component: PrinterControllerPage,
					},
				],
              },
              {
                component: NotFoundPage,
              },
            ]
          }
        ]
      },
    ]
  },
];
