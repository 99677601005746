import { Button, Modal, notification } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { UserRole } from '../../../../stores/users/const/user-role.enum';
import { lazyInject } from '../../../../utils/IoC';
import UserModalForm, { UserModalFormValue } from '../UserModalForm/index';
import { UserCreateModalStore } from '../../../../stores/users/UserCreateModalStore';
import { FormError } from '../../../../utils/error/FormError';
import { UserModel } from '../../../../stores/users/model/UserModel';

interface Props {
  visible: boolean;
  handler?: UserRole;
  onClose(): void;
}

@observer
class UserCreateModal extends Component<Props> {
  @lazyInject(UserCreateModalStore)
  store!: UserCreateModalStore;

  formRef = React.createRef<FormInstance>();

  @observable loading: boolean = false;
  @observable error?: FormError;

  @action
  resetError = (key: string) => {
    delete this.error?.errors[key];
  }

  handleCancel = () => {
    this.props.onClose();
  };

  @action
  handleCreate = () => {
    const value = this.formRef.current?.getFieldsValue() as UserModalFormValue | undefined;
    if (!value) {
      return;
    }

    const { fullName, email, phoneNumber, role, factory, pin, permissions, password } = value;

    const model = new UserModel({ fullName, email, phoneNumber, role, factory, pin, permissions, password });

    this.loading = true;
    this.store.createUser(model).then(this.handleCreateSuccess).catch(this.handleCreateError);
  };

  @action.bound
  handleCreateSuccess(model: UserModel) {
    this.loading = false;
    this.formRef.current?.resetFields();

    notification.success({ message: `Пользователь успешно создан (id: ${model.id})` });

    this.props.onClose();
  }

  @action.bound
  handleCreateError(error: FormError | Error) {
    if (error instanceof FormError) {
      this.error = error;
    } else {
      notification.error({ message: error.message });
    }

    this.loading = false;
  }

  render() {
    const { visible } = this.props;

    return (
      <Modal
        title="Создание пользователя"
        visible={visible}
        width="650px" centered
        onCancel={this.handleCancel}
        footer={[
          <Button
            key="back"
            onClick={this.handleCancel}
          >
            Отмена
          </Button>,

          <Button
            key="submit"
            type="primary"
            disabled={this.loading}
            loading={this.loading}
            onClick={this.handleCreate}
          >
            Создать
          </Button>,
        ]}
      >
        <UserModalForm
          formRef={this.formRef}
          error={this.error}
          onResetError={this.resetError}
        />
      </Modal>
    );
  }
}

export default UserCreateModal;
