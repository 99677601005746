import { observable } from 'mobx';
import moment, { Moment } from 'moment';
import { EquipmentFactoryModel } from './EquipmentFactoryModel';
import { EquipmentFileModel } from './EquipmentFileModel';
import { EquipmentResponseDTO } from '../dto/response/equipment.response.dto';

export class EquipmentModel {
  @observable id?: number;
  @observable name: string;
  @observable factory?: EquipmentFactoryModel;
  @observable serialNumber: string;
  @observable deliveryDate?: Moment;
  @observable leaseEndDate?: Moment;
  @observable comment?: string;
  @observable files?: EquipmentFileModel[];
  @observable deviceUuid?: string;

  constructor(data: EquipmentModel) {
    this.id = data.id;
    this.name = data.name;
    this.factory = data.factory;
    this.serialNumber = data.serialNumber;
    this.deliveryDate = data.deliveryDate;
    this.leaseEndDate = data.leaseEndDate;
    this.comment = data.comment;
    this.files = data.files;
    this.deviceUuid = data.deviceUuid;
  }

  static createFromDTO(dto: EquipmentResponseDTO) {
    return new EquipmentModel({
      id: dto.id,
      name: dto.name,
      factory: dto.factory ? EquipmentFactoryModel.createFromDTO(dto.factory) : undefined,
      serialNumber: dto.serialNumber,
      deliveryDate: dto.deliveryDate ? moment(dto.deliveryDate) : undefined,
      leaseEndDate: dto.leaseEndDate ? moment(dto.leaseEndDate) : undefined,
      comment: dto.comment,
      files: dto.files.map((f) => EquipmentFileModel.createFromDTO(f)),
      deviceUuid: dto.deviceUuid,
    })
  }
}
