import { observable } from 'mobx';
import { BalanceResponseDTO } from '../dto/response/balance.response.dto';

function detailsToTextDescription(details: object) {
	try {
		const details_any = details as any;
		return ("codes_qty" in details_any)
			? "Выпуск кодов: " + details_any.codes_qty
			: details_any.description;
	} catch {
		return '';
	}
}

export class BalanceModel {
  @observable id?: string;
  @observable date_created!: string;
  @observable time_created!: string;
  @observable amount!: number;

  @observable factory?: string;
  @observable detail?: string;

  constructor(data: BalanceModel) {
    this.id = data.id;
    this.date_created = data.date_created;
    this.time_created = data.time_created;
    this.amount = data.amount;
    this.factory = data.factory;
    this.detail = data.detail;

  }

  static createFromDTO(dto: BalanceResponseDTO) {
    const detail = detailsToTextDescription(dto.details);

    return new BalanceModel({
      id: dto.id,
      date_created: dto.date_created.toString().slice(0,10),
      time_created: dto.time_created.toString().slice(11, 19),
      amount:  dto.amount,
      factory: dto.factory,
      detail:  detail
    })
  }
  static createFromFilterDTO(dto: BalanceResponseDTO) {
    const detail = detailsToTextDescription(dto.details);

    return new BalanceModel({
      id: dto.id,
      date_created: dto.date_created.toString().slice(0,10),
      time_created: dto.time_created.toString().slice(11, 19),
      amount:  dto.amount,
      factory: dto.factory,
      detail:  detail
    })
  }
}
