import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { lazyInject } from '../../../../../utils/IoC';
import { FormInstance } from 'antd/lib/form';
import { action, computed, observable } from 'mobx';
import { FormError } from '../../../../../utils/error/FormError';
import { PriceEditModalStore } from '../../../../../stores/finances/prices/PriceEditModalStore';
import { PriceModel } from '../../../../../stores/finances/prices/model/PriceModel';
import { Button, Modal, notification } from 'antd';
import PricesModalForm, { PriceModalFormValue } from '../PricesModalForm/index';

interface Props {
  visible: boolean;
  id: string;
  onClose(): void;
}

@observer
class PriceEditModal extends Component<Props> {
  @lazyInject(PriceEditModalStore)
  store!: PriceEditModalStore;

  formRef = React.createRef<FormInstance>();

  @observable loading: boolean = false;
  @observable error?: FormError;

  @computed
  get price(): PriceModel | undefined {
    return this.store.itemsMap.get(this.props.id);
  }

  @action
  resetError = (key: string) => {
    delete this.error?.errors[key];
  }

  handleCancel = () => {
    this.props.onClose();
  };

  @action
  handleSave = () => {
    const value = this.formRef.current?.getFieldsValue() as PriceModalFormValue | undefined;
    if (!value) {
      return;
    }

    const { name, datamatrix, barcode, startDate, endDate, barcode_pricing } = value;
    const model = new PriceModel({
      name,
      datamatrix: datamatrix?.toString(),
      barcode: barcode?.toString(),
      startDate,
      endDate,
	  barcode_pricing,
    });

    this.loading = true;
    this.store.updatePrice(this.props.id, model).then(this.handleSaveSuccess).catch(this.handleSaveError);
  };

  @action.bound
  handleSaveSuccess(model: PriceModel) {
    this.loading = false;
    this.formRef.current?.resetFields();

    notification.success({ message: `Тариф успешно сохранен` });

    this.props.onClose();
  }

  @action.bound
  handleSaveError(error: FormError | Error) {
    if (error instanceof FormError) {
      this.error = error;
    } else {
      notification.error({ message: error.message });
    }

    this.loading = false;
  }

  render() {
    const { visible } = this.props;
    const { price } = this;

    if (!price) {
      this.handleCancel();
      return null;
    }

    const isDefaultPrice = price.id === "1";

    return (
      <Modal
        title="Изменение тарифа"
        visible={visible}
        centered
        onCancel={this.handleCancel}
        footer={[
          <Button
            key="back"
            onClick={this.handleCancel}
          >
            Отмена
          </Button>,

          <Button
            key="submit"
            type="primary"
            disabled={this.loading}
            loading={this.loading}
            onClick={this.handleSave}
          >
            Сохранить
          </Button>,
        ]}
      >
        <PricesModalForm
          formRef={this.formRef}
          error={this.error}
          onResetError={this.resetError}
          initialValues={price}
          showDates={!isDefaultPrice}
        />
      </Modal>
    );
  }
}

export default PriceEditModal;
