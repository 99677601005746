import { action, observable } from 'mobx';
import { AxiosResponse } from 'axios';
import { lazyInject, provide } from '../../../../utils/IoC';
import { HttpClientV1 } from '../../../../utils/api/HttpClientV1';
import { FormError } from '../../../../utils/error/FormError';
import { RequestResetPasswordRequestDTO } from '../dto/reset-password/request-reset-password.request.dto';

@provide.singleton()
export class StartResetPasswordStore {
  @lazyInject(HttpClientV1)
  protected readonly httpClientV1!: HttpClientV1;

  @observable loading: boolean = false;

  @action
  resetPasswordAttempt(email: string) {
    this.loading = true;

    return this.httpClientV1.post<RequestResetPasswordRequestDTO>(
      '/auth/reset-password',
      { email }
    ).then(this.onResetPasswordAttemptSuccess).catch(this.onResetPasswordAttemptError);
  }

  @action.bound
  protected onResetPasswordAttemptSuccess() {
    this.loading = false;
  }

  @action.bound
  protected onResetPasswordAttemptError(reason: AxiosResponse) {
    this.loading = false;

    if (reason.status === 400) {
      throw FormError.createFromBadRequestResponseDTO(reason.data);
    }
  }
}
