import React, { Component } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { observer } from 'mobx-react';
import { getTypeCodeName, TypeCode } from '../../stores/factory/const/type-code.enum';

interface Props extends Omit<SelectProps<TypeCode>, 'children'> {
  onlyAllowedByStatus?: boolean;
}

@observer
class TypeCodeSelect extends Component<Props> {

  render() {

    return (
      <Select {...this.props}>
          <>
            {Object.values(TypeCode).map((type) => (
                <Select.Option key={type} value={type}>
                  {getTypeCodeName(type)}
                </Select.Option>
              ))
            }
          </>

      </Select>
    );
  }
}

export default TypeCodeSelect;
