import { lazyInject, provide } from '../../utils/IoC';
import { HttpClientV1 } from '../../utils/api/HttpClientV1';
import { action, computed } from 'mobx';
import { FactoryModel } from './model/FactoryModel';
import { FactoryResponseDTO } from './dto/response/factory.response.dto';
import { AxiosResponse } from 'axios';
import { FormError } from '../../utils/error/FormError';
import { HttpClientV1TwoApi } from '../../utils/api/HttpClientV1twoApi';
import { CablesTableStore } from './CablesTableStore';
import { UpdateCablesRequestDTO } from './dto/request/update-cables.request.dto';
import { CablesModel } from './model/CablesModel';
import { CablesResponseDTO } from './dto/response/cables.response.dto';

@provide.singleton()
export class CablesEditModalStore {
  @lazyInject(HttpClientV1TwoApi)
  protected readonly httpClientV1Two!: HttpClientV1TwoApi;

  @lazyInject(CablesTableStore)
  tableStore!: CablesTableStore;

  @computed
  get itemsMap() {
    return this.tableStore.itemsMap;
  }

  @action
  updateCables(factory_id: string, product_id: string, model: CablesModel) {
    return this.httpClientV1Two.put<UpdateCablesRequestDTO, CablesResponseDTO>(
      `/product`,
      {
        factory_id: factory_id,
        product_id: product_id,
        product: model
      },
    ).then(this.onUpdateCablesSuccess).catch(this.onUpdateCablesError) as Promise<CablesModel>;
  }

  @action.bound
  protected onUpdateCablesSuccess(data: CablesResponseDTO) {
    const model = CablesModel.createFromDTO(data);

    if (this.itemsMap.has(data.id)) {
      this.itemsMap.set(model.id as string, model);
    }

    return model;
  }

  @action.bound
  protected onUpdateCablesError(reason: AxiosResponse) {
    if (reason.status === 400) {
      const data = Object.entries(reason.data as Record<string, string>)
        .map(([ field, value ]) => [ field.replace('body.', ''), value ])
        .reduce((obj, [field, value]) => ({ ...obj, [field]: value }), {})
      ;

      throw FormError.createFromBadRequestResponseDTO(data);
    }
  }

}
