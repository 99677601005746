import React, { Component } from 'react';
import TableButtonFiltersDropdown from './TableButtonFiltersDropdown';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import UserRoleSelect from '../UserRoleSelect/index';
import { UserRole } from '../../stores/users/const/user-role.enum';

interface Props extends FilterDropdownProps {
  placeholder?: string;
}

export const getTableUserRoleFilterDropdownProps = () => ({
  filterDropdown: (props: FilterDropdownProps) => (
    <TableUserRoleFilterDropdown {...props} />
  ),
});

class TableUserRoleFilterDropdown extends Component<Props> {
  handleChange = (role: UserRole) => {
    this.props.setSelectedKeys(
      !role ? [] : [(role as unknown) as string]
    );
  };

  render() {
    const { clearFilters, confirm } = this.props;

    return (
      <div style={{ padding: "8px" }}>
        <UserRoleSelect

          placeholder='Выберите роль'
          style={{ minWidth: '200px', width: "100%", marginBottom: "8px" }}
          onChange={this.handleChange}
        />

        <TableButtonFiltersDropdown
          handleReset={clearFilters}
          handleSearch={confirm}
        />
      </div>
    );
  }
}

export default TableUserRoleFilterDropdown;
