import { lazyInject, provide } from '../../utils/IoC';
import { UserModel } from './model/UserModel';
import { HttpClientV1 } from '../../utils/api/HttpClientV1';
import { AxiosResponse } from 'axios';
import { UserResponseDTO } from './dto/response/user.response.dto';
import { action, autorun, observable } from 'mobx';
import { AuthStore } from '../auth/AuthStore';

@provide.singleton()
export class CurrentUserStore {
  @lazyInject(HttpClientV1)
  protected readonly httpClientV1!: HttpClientV1;

  @lazyInject(AuthStore)
  protected readonly authStore!: AuthStore;

  @observable user?: UserModel;
  @observable loading: boolean = false;

  constructor() {
    autorun(() => {
      if (!this.authStore.isRefreshTokenValid) {
        this.clearCurrentUser();
        return;
      }

      if (this.authStore.accessTokenCache && this.authStore.isAccessTokenValid) {
        this.fetchCurrentUser().finally();
      }
    });
  }

  @action
  fetchCurrentUser() {
    this.loading = true;

    return this.httpClientV1.get<UserResponseDTO>('/users/current')
      .then(this.onFetchCurrentUserSuccess)
      .catch(this.onFetchCurrentUserError)
    ;
  }

  @action.bound
  protected onFetchCurrentUserSuccess(data: UserResponseDTO) {
    this.user = UserModel.createFromDTO(data);
    this.loading = false;
  }

  @action.bound
  protected onFetchCurrentUserError(reason: AxiosResponse) {
    this.clearCurrentUser();
    this.loading = false;
  }

  @action
  clearCurrentUser() {
    this.user = undefined;
  }
}
