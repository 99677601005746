import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import {  Card, Col, notification,  Row} from 'antd';
import { action, observable} from 'mobx';
import EquipmentCreateModal from './component/FactoryCreateModal/index';
import EquipmentEditModal from './component/FactoryEditModal/index';

import { FormInstance } from 'antd/lib/form';
import { observer } from 'mobx-react';
import { lazyInject } from '../../utils/IoC';
import { FactoryTableStore } from '../../stores/factory/FactoryTableStore';
import { CategoryAccessStore } from '../../stores/users/CategoryAccessStore';
import { Redirect } from 'react-router';
import axios from 'axios';
import { ACCESS_TOKEN_STORAGE_KEY } from '../../stores/auth/const/AuthTokensStorageKeys';
export const MomentDateFormat = 'DD.MM.YYYY';


const api_url = process.env.REACT_APP_API_SERVER_HOST
@observer
class FactoryListStat extends Component {
  @lazyInject(FactoryTableStore)
  store!: FactoryTableStore;

  @lazyInject(CategoryAccessStore)
  categoryAccessStore!: CategoryAccessStore;

  @observable isModalVisible = { create: false, edit: false };
  @observable currentEditId = '';
  @observable option = [];


  @observable allCabel = 0;

  @observable ReserveCode = 0;
  @observable printCode = 0;

  refForm = React.createRef<FormInstance>();

  componentDidMount() {
    this.store.fetchAll();
  }

  //region Handlers
  deleteRecord = (id: string) => {
    this.store.deleteItem(id).catch(() => notification.error({ message: 'Не удалось удалить оборудование' }));
  };

  @action
  openCreateModal = () => {
    this.isModalVisible.create = true;
  };

  @action
  closeCreateModal = () => {
    this.isModalVisible.create = false;
  };



  @action
  closeEditModal = () => {
    this.isModalVisible.edit = false;
    this.currentEditId = '';
  };
  //endregion
  
  state = {

  };
  render() {
    if (!this.categoryAccessStore.loading && !this.categoryAccessStore.hasFactyoryAccess) {

      return <Redirect to='/' />;

    }

    const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) || undefined
    axios
    .get(api_url+'/v1/admin/stats_summary',{ headers:{'Authorization':'Bearer ' + token}})
    .then(resp =>(this.allCabel=resp.data.codes_total, this.ReserveCode=resp.data.codes_ribbon_unassigned_total, this.printCode=resp.data.codes_printed_total));
    

    return (
      <PageHeaderWrapper>
      <EquipmentCreateModal
        visible={this.isModalVisible.create}
        onClose={this.closeCreateModal}
      />

      {this.isModalVisible.edit && this.currentEditId !== '' &&
        <EquipmentEditModal
          visible={this.isModalVisible.edit}
          onClose={this.closeEditModal}
          id={this.currentEditId}
        />
      }

      <Card>

      
        <Row gutter={[16, 16]}>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12}}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
          >
          
            <p>Всего кодов в базе: {this.allCabel}</p>

            <p>Кодов на ленте в резерве: {this.ReserveCode}</p>

            <p>Кодов напечатано на принтере: {this.printCode}</p>
            </Col>
          </Row>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default FactoryListStat;
