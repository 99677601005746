import { observable } from 'mobx';
import moment, { Moment } from 'moment';
import { UserResponseDTO } from '../../users/dto/response/user.response.dto';
import { getStatusProductName } from '../const/status-product.enum';
import { CablesResponseDTO } from '../dto/response/cables.response.dto';
import { FactoryResponseDTO } from '../dto/response/factory.response.dto';

export class CablesModel {
  @observable id?: string;
  @observable factory?: FactoryResponseDTO;
  @observable factory_name?: string;
  @observable date_created!: Moment;
  @observable date_released?: Moment;
  @observable length!: number ;
  @observable codes?: number | undefined;
  @observable type?: string;
  @observable drum_number?: string;
  @observable otk?: UserResponseDTO;
  @observable status?: string;
  @observable bounding_codes?: any;
  @observable joints?: Array<Array<string>>;
  @observable price?: number;


  constructor(data: CablesModel) {
    this.id = data.id;
    this.factory = data.factory;
	this.factory_name = data.factory_name;
    this.date_created = data.date_created;
    this.date_released = data.date_released;
    this.length = data.length;
    this.codes = data.codes;
    this.type = data.type;
    this.drum_number = data.drum_number;
    this.price = data.price;
    this.otk = data.otk;
    this.status = data.status;
    this.bounding_codes = data.bounding_codes;
    this.joints = data.joints;
  }

  static createFromDTO(dto: CablesResponseDTO) {
    return new CablesModel({
      id: dto.id,
	  date_created: moment(dto.date_created),
	  date_released: (dto.date_released) ? moment(dto.date_released) : undefined,
/*      date_created: dto.date_created.toString().slice(0,10),
      time_created: dto.time_created.toString().slice(11, 19),
      date_released: dto.date_released?.toString().slice(0,10),
      time_released: dto.time_released?.toString().slice(11, 19),*/
      length: dto.length,
      codes: dto.num_codes,
      type: dto.type,
      drum_number: dto.drum_number,
      otk: dto.operator_name,
      status: getStatusProductName(dto.status),
      factory: dto.factory_id,
	  factory_name: dto.factory_name,
    })
  }
}
