import { injectable } from 'inversify';
import { action, computed, observable, toJS } from 'mobx';
import { SortRuleModel } from '../model/SortRuleModel';
import { ModelFiltersType } from '../type/ModelFiltersType';
import { SortRequestDTO } from '../dto/sort.request.dto';
import { PaginationRequestDTO } from '../dto/pagination.request.dto';
import { PaginationResponseDTO } from '../dto/pagination.response.dto';
import { AxiosResponse } from 'axios';
import { Key, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { isEqual } from 'lodash';
import { HttpClient } from '../api/abstract/HttpClient';
import qs from 'qs';

type BaseModelType = {
  id?: string | number;
};

@injectable()
export abstract class BaseTableStore<ModelType extends BaseModelType, ResponseDTOType> {
  @observable itemsMap: Map<string | number, ModelType> = new Map();

  @observable loading: boolean = false;

  @observable currentPage: number = 1;
  @observable pageSizeOptions: number[] = [20, 50, 100];
  @observable pageSize: number = this.pageSizeOptions[0];
  @observable totalItems: number = 0;

  @observable sortRules: SortRuleModel<ModelType>[] = [];
  @observable filters: ModelFiltersType<ModelType> = {};

  @computed
  get items(): ModelType[] {
    return Array.from(this.itemsMap.values());
  }

  @computed
  get tablePagination() {
    return {
      pageSizeOptions: this.pageSizeOptions.map((v) => v.toString()),
      pageSize: this.pageSize,
      current: this.currentPage,
      total: this.totalItems,
    };
  }

  @computed
  get tableAppliedSorters() {
    return this.sortRules
      .map((v) => v.toAntdSorter())
      .reduce((obj, item) => {
        return {
          ...obj,
          [item.field as string]: item.order,
        };
      }, {}) as Record<string, "ascend" | "descend">;
  }

  protected get paginationDTO(): PaginationRequestDTO {
    return {
      limit: this.pageSize,
      page: this.currentPage,
    };
  }

  protected get sortDTO(): SortRequestDTO[] {
    const result: SortRequestDTO[] = [];

    for (const sortRule of this.sortRules) {
      const field = sortRule.field;
      const direction = sortRule.order;

      if (field && direction) {
        result.push({ field, direction });
      }
    }

    return result;
  }

  protected abstract readonly path: string;
  protected abstract readonly httpClient: HttpClient;
  protected readonly download_xlsx_path: string = "";
  protected readonly delete_path?: string = undefined;

  protected abstract get searchDTO(): object;
  protected abstract get filterDTO(): object;

  protected abstract createModelFromResponseDTO(dto: ResponseDTOType): ModelType;

  @action.bound
  downloadXlsxSave(data: Blob, filename: string) {
	  try {
		  var element = document.createElement('a');
		  element.href = URL.createObjectURL(data);
		  element.setAttribute('download', filename);
		  element.style.display = 'none';
		  document.body.appendChild(element);
		  element.click();
		  document.body.removeChild(element);
	  } catch(e) {}
	  this.loading = false;
  }

  @action
  downloadXlsx(filename: string) {
	  if(!this.download_xlsx_path) {
		  console.log("no xlsx path");
		  return;
	  }

	  this.loading = true;
	  this.httpClient.get(this.download_xlsx_path, {
		  responseType: "blob",
		  params: {
			  pagination: this.paginationDTO,
			  sort: this.sortDTO,
			  search: this.searchDTO,
			  filter: this.filterDTO,
		  },
		  paramsSerializer: (params) => qs.stringify(params),
	  })
	  .then((d: Blob) => {this.downloadXlsxSave(d, filename)})
	  .catch((e) => {this.loading=false});
  }

  @action
  fetchAll() {
    this.loading = true;

    this.httpClient.get(this.path, {
      params: {
        pagination: this.paginationDTO,
        sort: this.sortDTO,
        search: this.searchDTO,
        filter: this.filterDTO,
      },
      paramsSerializer: (params) => qs.stringify(params),
    })
      .then(this.onFetchAllSuccess)
      .catch(this.onFetchAllError);
  }

  @action.bound
  protected onFetchAllSuccess(data: PaginationResponseDTO<ResponseDTOType>) {
    this.totalItems = data.totalItems;

    this.itemsMap.clear();
    data.items.forEach(item => {
      const model = this.createModelFromResponseDTO(item);
      this.itemsMap.set(model.id!, model);
    });

    this.loading = false;
  }

  @action.bound
  protected onFetchAllError(reason: AxiosResponse) {
    this.loading = false;
    throw reason;
  }

  @action
  deleteItem(id: number | string) {
	const delete_path = this.delete_path ?? this.path;
    return this.httpClient.delete(`${delete_path}/${id}`).then(this.onDeleteItemSuccess);
  }

  @action.bound
  protected onDeleteItemSuccess() {
    this.fetchAll();
  }

  @action
  setCurrentPage(value: number) {
    this.currentPage = value;
  }

  @action
  setPageSize(value: number) {
    this.pageSize = value;
  }

  @action
  setSortRules(rules: SortRuleModel<ModelType>[]) {
    this.sortRules = rules;
  }

  @action
  setFilters(filters: ModelFiltersType<ModelType>) {
    this.filters = observable(filters);
  }

  @action
  handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, Key[] | null>,
    sorter: SorterResult<ModelType> | SorterResult<ModelType>[]
  ) => {
    const { current: currentPage, pageSize } = pagination;

    let isPaginationResetNeeded = false;

    const sortRules = (Array.isArray(sorter) ? sorter : [sorter])
      .map((v) => SortRuleModel.createFromAntdSorter(v))
      .filter((v) => !!v.order);

    const sortRulesChanged = !isEqual(toJS(this.sortRules), sortRules);
    isPaginationResetNeeded = isPaginationResetNeeded || sortRulesChanged;

    const filtersChanged = !isEqual(toJS(this.filters), filters);
    isPaginationResetNeeded = isPaginationResetNeeded || filtersChanged;

    this.setFilters(filters as ModelFiltersType<ModelType>);
    this.setSortRules(sortRules);
    this.setCurrentPage(isPaginationResetNeeded ? 1 : currentPage || this.currentPage);
    this.setPageSize(pageSize || this.pageSize);

    this.fetchAll();
  };

  @action
  handleTableRefresh = () => {
    this.fetchAll();
  };

  @action
  handleTableReset = () => {
    this.setFilters({});
    this.setSortRules([]);

    this.fetchAll();
  };
}
