import React, { Component } from 'react';
import { UserPermissionModel } from '../../../../stores/users/model/UserPermissionModel';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { Checkbox, Space } from 'antd';
import { UserRole } from '../../../../stores/users/const/user-role.enum';

interface Props {
  disabled?: boolean;
  value?: UserPermissionModel;
  role: UserRole;
  onChange?: (value: UserPermissionModel) => void;
}

@observer
class UserPermissions extends Component<Props> {
  @observable value = new UserPermissionModel(this.props.value || {
    hasProductsAccess: false,
    hasAnalyticsAccess: false,
    hasAdsAccess: false,
    hasUsersAccess: false,
    hasFinancesAccess: false,
    hasActOfReconciliationAccess: false,
    hasEquipmentAccess: false,

    hasFactoryAccess: false,
  });

  @action
  handleChange = (key: keyof UserPermissionModel, value: boolean) => {
    this.value[key] = value;
    this.props.onChange && this.props.onChange(this.value);
  };

  renderManagerRole() {
    const { disabled } = this.props;

    return (
      <>
        <Checkbox
          disabled={disabled}
          checked={this.value.hasUsersAccess}
          onChange={(e) => this.handleChange('hasUsersAccess', e.target.checked)}
        >
          Пользователи
        </Checkbox>

        <Checkbox
          disabled={disabled}
          checked={this.value.hasFinancesAccess}
          onChange={(e) => this.handleChange('hasFinancesAccess', e.target.checked)}
        >
          Финансы
        </Checkbox>

        <Checkbox
          disabled={disabled}
          checked={this.value.hasEquipmentAccess}
          onChange={(e) => this.handleChange('hasEquipmentAccess', e.target.checked)}
        >
          Оборудование
        </Checkbox>

        <Checkbox
          disabled={disabled}
          checked={this.value.hasFactoryAccess}
          onChange={(e) => this.handleChange('hasFactoryAccess', e.target.checked)}
        >
        Заводы 
        </Checkbox>
      </>
    );
  }

  renderFactoryAdminRole() {
    const { disabled } = this.props;

    return (
      <>
        <Checkbox
          disabled={disabled}
          checked={this.value.hasAnalyticsAccess}
          onChange={(e) => this.handleChange('hasAnalyticsAccess', e.target.checked)}
        >
          Аналитка
        </Checkbox>

        <Checkbox
          disabled={disabled}
          checked={this.value.hasAdsAccess}
          onChange={(e) => this.handleChange('hasAdsAccess', e.target.checked)}
        >
          Реклама
        </Checkbox>

        <Checkbox
          disabled={disabled}
          checked={this.value.hasUsersAccess}
          onChange={(e) => this.handleChange('hasUsersAccess', e.target.checked)}
        >
          Пользователи
        </Checkbox>

        <Checkbox
          disabled={disabled}
          checked={this.value.hasProductsAccess}
          onChange={(e) => this.handleChange('hasProductsAccess', e.target.checked)}
        >
          Продукция
        </Checkbox>

        <Checkbox
          disabled={disabled}
          checked={this.value.hasFinancesAccess}
          onChange={(e) => this.handleChange('hasFinancesAccess', e.target.checked)}
        >
          Финансы
        </Checkbox>

          <Checkbox
            disabled={disabled}
            checked={this.value.hasActOfReconciliationAccess}
            onChange={(e) => this.handleChange('hasActOfReconciliationAccess', e.target.checked)}
            style={{ marginLeft: '25px' }}
          >
            Доступ к акту сверки
          </Checkbox>
      </>
    );
  }

  render() {
    const { role } = this.props;

    const isManagerRole = [UserRole.IntegrityManager, UserRole.IntegrityAdmin].includes(role);
    const isFactoryAdminRole = [UserRole.FactoryAdmin].includes(role);

    return (
      <Space direction="vertical">
        {isFactoryAdminRole && this.renderFactoryAdminRole()}
        {isManagerRole && this.renderManagerRole()}
      </Space>
    )
  }
}

export default UserPermissions;
