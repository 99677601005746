import React, { Component } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { getUserRoleName, UserRole } from '../../stores/users/const/user-role.enum';
import { observer } from 'mobx-react';
import { lazyInject } from '../../utils/IoC';
import { CurrentUserStore } from '../../stores/users/CurrentUserStore';

interface Props extends Omit<SelectProps<UserRole>, 'children'> {
  onlyAllowedByRole?: boolean;
}

@observer
class UserRoleSelect extends Component<Props> {
  @lazyInject(CurrentUserStore)
  currentUserStore!: CurrentUserStore;

  render() {
    const currentUserRole = this.currentUserStore.user?.role;

    return (
      <Select {...this.props}>
        {this.props.onlyAllowedByRole
          ?
            <>
              {[UserRole.IntegrityManager, UserRole.IntegrityAdmin].includes(currentUserRole!) &&
                [UserRole.OTK, UserRole.FactoryAdmin].map((role) => (
                  <Select.Option key={role} value={role}>
                    {getUserRoleName(role)}
                  </Select.Option>
                ))
              }

              {[UserRole.IntegrityAdmin].includes(currentUserRole!) &&
                [UserRole.IntegrityManager, UserRole.IntegrityAdmin].map((role) => (
                  <Select.Option key={role} value={role}>
                    {getUserRoleName(role)}
                  </Select.Option>
                ))
              }
            </>
          :
          <>
            {Object.values(UserRole).map((role) => (
                <Select.Option key={role} value={role}>
                  {getUserRoleName(role)}
                </Select.Option>
              ))
            }
          </>
        }

      </Select>
    );
  }
}

export default UserRoleSelect;
