import { UserResponseDTO } from '../dto/response/user.response.dto';
import { observable } from 'mobx';
import { UserRole } from '../const/user-role.enum';
import { UserFactoryModel } from './UserFactoryModel';
import { UserPermissionModel } from './UserPermissionModel';

export class UserModel {
  @observable id?: string;
  @observable email?: string;
  @observable role?: UserRole;
  @observable pin?: string;
  @observable factory?: UserFactoryModel;
  @observable fullName?: string;
  @observable permissions!: UserPermissionModel;
  @observable status?: string;
  @observable phoneNumber?: string;
  @observable password?: string;

  constructor(model: UserModel) {
    this.id = model.id;
    this.email = model.email;
    this.role = model.role;
    this.pin = model.pin;
    this.factory = model.factory;
    this.fullName = model.fullName;
    this.permissions = model.permissions;
    this.status = model.status;
    this.phoneNumber = model.phoneNumber;
	this.password = model.password;
  }

  static createFromDTO(dto: UserResponseDTO) {
    return new UserModel({
      id: dto.id,
      fullName: dto.fullName,
      email: dto.email,
      phoneNumber: dto.phoneNumber,
      pin: dto.pin,
      factory: dto.factory
        ? UserFactoryModel.createFromDTO(dto.factory)
        : undefined,
      permissions: UserPermissionModel.createFromDTO(dto.permissions),
      role: dto.role,
      status: dto.status,
    });
  }
}
