import React, { Component, RefObject } from 'react';
import { FormInstance } from 'antd/lib/form';
import { FormError } from '../../../../../utils/error/FormError';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { Moment } from 'moment';
import { Col, DatePicker, Form, Row } from 'antd';
import FactoryAutoCompleteSelect from '../../../../../components/AutoCompleteSelect/FactoryAutoCompleteSelect/index';
import PriceAutoCompleteSelect from '../../../../../components/AutoCompleteSelect/PriceAutoCompleteSelect/index';
import { FactoryPriceModel } from '../../../../../stores/finances/factories-prices/model/FactoryPriceModel';

import styles from './index.module.less';
import { MomentDateFormat } from '../../../../../utils/MomentDateFormat';

export interface FactoryPriceModalFormValue {
  factory?: { id: string, name: string };
  price?: { id: number, name: string };
  startDate?: Moment;
  endDate?: Moment;
}

interface Props {
  formRef: RefObject<FormInstance>;
  initialValues?: FactoryPriceModel;
  error?: FormError;
  onResetError?: (key: string) => void;
}

@observer
class FactoryPriceModalForm extends Component<Props> {
  @observable startDate?: Moment = this.props.initialValues?.startDate;
  @observable endDate?: Moment = this.props.initialValues?.endDate;

  resetError = (key: string) => {
    this.props.onResetError && this.props.onResetError(key);
  };

  @action
  handleStartDateChange = (value?: Moment) => {
    this.startDate = value ? value.set({hour: 0, minute: 0, seconds: 0, milliseconds: 0}) : undefined;
    this.props.formRef.current?.setFields([{ name: 'startDate', value: this.startDate }]);
  };

  @action
  handleEndDateChange = (value?: Moment) => {
    this.endDate = value ? value.set({hour: 23, minute: 59, seconds: 59, milliseconds: 999}) : undefined;
    this.props.formRef.current?.setFields([{ name: 'endDate', value: this.endDate }]);
  };

  render() {
    const { formRef, initialValues, error } = this.props;

    const hasFactoryError = !!(error?.errors?.factoryId);
    const hasPriceError = !!(error?.errors?.priceId);
    const hasStartDateError = !!(error?.errors?.startDate);
    const hasEndDateError = !!(error?.errors?.endDate);

    return (
      <Form
        ref={formRef}
        layout='vertical'
        initialValues={initialValues}
      >
        <Form.Item
          label='Завод'
          name='factory'
          key='factory'

          hasFeedback={hasFactoryError}
          validateStatus={hasFactoryError ? 'error' : undefined}
          help={error?.errors?.factoryId}
        >
          <FactoryAutoCompleteSelect
            onChange={() => this.resetError("factoryId")}
            allowClear
          />
        </Form.Item>

        <Form.Item
          label='Тариф'
          name='price'
          key='price'

          hasFeedback={hasPriceError}
          validateStatus={hasPriceError ? 'error' : undefined}
          help={error?.errors?.priceId}
        >
          <PriceAutoCompleteSelect
            onChange={() => this.resetError("priceId")}
            allowClear
          />
        </Form.Item>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label='Дата начала действия'
              name='startDate'
              key='startDate'

              hasFeedback={hasStartDateError}
              validateStatus={hasStartDateError ? 'error' : undefined}
              help={error?.errors?.startDate}
            >
              <DatePicker
                placeholder='Выберите дату'
                format={MomentDateFormat}
                className={styles.datepicker}
                disabledDate={d => this.endDate !== undefined ? d.isAfter(this.endDate) : false}
                onChange={(value?: Moment | null) => {
                  this.handleStartDateChange(value || undefined);
                  this.resetError("startDate");
                }}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label='Дата окончания действия'
              name='endDate'
              key='endDate'

              hasFeedback={hasEndDateError}
              validateStatus={hasEndDateError ? 'error' : undefined}
              help={error?.errors?.endDate}
            >
              <DatePicker
                placeholder='Выберите дату'
                format={MomentDateFormat}
                className={styles.datepicker}
                disabledDate={d => this.startDate !== undefined ? d.isBefore(this.startDate) : false}
                onChange={(value?: Moment | null) => {
                  this.handleEndDateChange(value || undefined);
                  this.resetError("endDate")
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default FactoryPriceModalForm;
