import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { Button, Card, Col, notification, Row, Skeleton, Table } from 'antd';
import { action, observable, toJS } from 'mobx';
import EquipmentCreateModal from './component/FactoryCreateModal/index';
import EquipmentEditModal from './component/FactoryEditModal/index';
import { observer } from 'mobx-react';
import { lazyInject } from '../../utils/IoC';
import { BalanceTableStore } from '../../stores/factory/BalanceTableStore';
import { CategoryAccessStore } from '../../stores/users/CategoryAccessStore';
import { Redirect } from 'react-router';
import { BalanceModel } from '../../stores/factory/model/BalanceModel';
import { getTableDateFilterDropdownProps } from '../../components/Table/TableDateFilterDropdown';
import { getTableNumberRangeFilterDropdownProps } from '../../components/Table/TableNumberRangeFilterDropdown';
import { getTableFactoryFilterDropdownProps } from '../../components/Table/TableFactoryFilterDropdown';
import { getTableTextSearchDropdownProps } from '../../components/Table/TableTextSearchDropdown';

@observer
class TableProduct extends Component {
  @lazyInject(BalanceTableStore)
  store!: BalanceTableStore;

  @lazyInject(CategoryAccessStore)
  categoryAccessStore!: CategoryAccessStore;

  @observable isModalVisible = { create: false, edit: false };
  @observable currentEditId = '';

  componentDidMount() {
    this.store.fetchAll();
  }


  @action
  openCreateModal = () => {
    this.isModalVisible.create = true;
  };

  @action
  closeCreateModal = () => {
    this.isModalVisible.create = false;
  };

  @action
  openEditModal = (id: string) => {
    this.currentEditId = id;
    this.isModalVisible.edit = true;
  };

  @action
  closeEditModal = () => {
    this.isModalVisible.edit = false;
    this.currentEditId = '';
  };

  @action
  handleDownloadXlsx = () => {
  	this.store.downloadXlsx("finances_history.xlsx");
  };
  //endregion

  render() {
    if (!this.categoryAccessStore.loading && !this.categoryAccessStore.hasFactyoryAccess) {
      return <Redirect to='/' />;
    }

    const columns = [
      {
        title: null,
        key: "index",
        render: (value: any, item: any, index: number) => {
            const p = this.store.tablePagination;
            return (<span>{p.pageSize * (p.current-1)+1+index}</span>);
        },
      },
      {
        title: "Дата",
        dataIndex: "date_created",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["date_created"],
        filteredValue: (this.store.filters.date_created as string[]) || null,
        ...getTableDateFilterDropdownProps()
      },
      {
        title: "Время",
        dataIndex: "time_created",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["time_created"],
      },

      {
        title: "Сумма",
        dataIndex: "amount",
        sorter: { multiple: -1 },
		sortOrder: this.store.tableAppliedSorters["amount"],
		filteredValue: (this.store.filters.amount as string[]) || null,
        render: (text: number, record: BalanceModel) =>
        (
          <span style={{ color: record.amount > 0 ? 'green':'red' }}>{record.amount}</span>
        ),
		...getTableNumberRangeFilterDropdownProps()
      },


      {
        title: "Завод",
        dataIndex: "factory",
		sorter: { multiple: -1 },
		sortOrder: this.store.tableAppliedSorters["factory"],
		filteredValue: (this.store.filters.factory as string[]) || null,
		...getTableFactoryFilterDropdownProps()
      },
      {
        title: "Основание",
        dataIndex: "detail",
        filteredValue: (this.store.filters.detail as string[]) || null,
        ...getTableTextSearchDropdownProps("Поиск по основанию"),
      },

    ];

    return (
      <PageHeaderWrapper>
        <EquipmentCreateModal
          visible={this.isModalVisible.create}
          onClose={this.closeCreateModal}
        />

        {this.isModalVisible.edit && this.currentEditId !== '' &&
          <EquipmentEditModal
            visible={this.isModalVisible.edit}
            onClose={this.closeEditModal}
            id={this.currentEditId}
          />
        }

        <Card>
          <Row gutter={[16, 16]}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 5, offset: 6 }}
              lg={{ span: 4, offset: 8 }}
              xl={{ span: 4, offset: 8 }}
            >
              <Button block onClick={this.store.handleTableRefresh}>
                Обновить список
              </Button>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 5 }}
              lg={{ span: 4 }}
              xl={{ span: 4 }}
            >
              <Button block onClick={this.store.handleTableReset}>
                Сбросить фильтры
              </Button>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 5 }}
              lg={{ span: 4 }}
              xl={{ span: 2 }}
            >
			  <Button block onClick={this.handleDownloadXlsx}>
			  XLS
			  </Button>
			</Col>
          </Row>
          <Table
            loading={this.store.loading}
            columns={columns}
            rowKey={(record) => record.id as string}
            dataSource={toJS(this.store.items)}
            pagination={{
              position: ["bottomRight"],
              showSizeChanger: true,
              ...this.store.tablePagination,
            }}
            locale={{
              emptyText: this.store.loading ? (
                <>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </>
              ) : (
                <p>Данные отсутствуют</p>
              ),
            }}
            onChange={this.store.handleTableChange}
          />
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default TableProduct;
function getTableDateFilterDropdown(arg0: string) {
  throw new Error('Function not implemented.');
}

