import React, { Component } from 'react';
import { Button, Col, Form, Input, Modal, notification, Row, Select, Collapse } from 'antd';
import { observer } from 'mobx-react';
import moment, { Moment } from 'moment';
import { action, observable } from 'mobx';
import { FormInstance } from 'antd/lib/form';
import { FormError } from '../../../../utils/error/FormError';
import axios from 'axios';
import { ACCESS_TOKEN_STORAGE_KEY } from '../../../../stores/auth/const/AuthTokensStorageKeys';
import { CablesModel } from '../../../../stores/factory/model/CablesModel';
import { lazyInject } from '../../../../utils/IoC';
import { CablesEditModalStore } from '../../../../stores/factory/CablesEditModalStore';
import { DeleteOutlined } from '@ant-design/icons';

const api_url = process.env.REACT_APP_API_SERVER_HOST

const { Option } = Select;

interface Props {
  visible: boolean;
  onClose: () => void;
  id: string;
  factory: string;
}

// noinspection DuplicatedCode
@observer
class CablesEditModal extends Component<Props> {
  @lazyInject(CablesEditModalStore)
  store!: CablesEditModalStore;

  @observable loading: boolean = false;
  @observable error?: FormError;

  refForm = React.createRef<FormInstance>();

  state = {
    bounding_codes_0: '',
    bounding_codes_1: '',
    number_tumb: '',
    type: '',
    joints: [],
    load: false,
    length: '',
    length_joints: '',
    price: '',
    codes: '',
	factory_id: '',
	factory_name: '',
	status: '',
	certificate_url: '',
	files: [],
	show_add_file_ui: false,
	new_file: null,
	new_file_desc: '',
	new_file_type: '',
	new_file_link: '',
	errors: {},
	parent: null,
	child_products: [],
	history: [],
	showHistoryRows: 3,
  }

  @action
  handleChange = (key: string) => {
    delete this.error?.errors[key];
  }

  @action
  addJoints = () => {
    if (!this.state) {
      return
    } else {
	  const value = this.refForm.current?.getFieldsValue();
	  let {joints} = value;
	  if(!joints) { joints = []; }
	  joints.push(["", ""]);

      this.setState({ joints, length_joints: parseInt(this.state.length_joints) + 1 },
		() => {
			this.forceUpdate();
			this.refForm.current?.setFieldsValue(this.state)
	  });
    }
  };
  @action
  deleteJoints = (i: number) => {
    if (!this.state) {
      return
    } else {
	  const value = this.refForm.current?.getFieldsValue();
	  let {joints} = value;

      if (i > -1) {
        joints?.splice(i, 1);
      }
      this.setState({ joints, length_joints: parseInt(this.state.length_joints) - 1 },
	  	() => {
	      this.refForm.current?.setFieldsValue(this.state)
        });
    }
  };
  @action
  handleSave = () => {
    const value = this.refForm.current?.getFieldsValue();
    if (!value) {
      return
    }
    const { type = '', bounding_codes_0 = '', bounding_codes_1 = '', drum_number = '', joints = ''} = value;
    const bounding_codes = [bounding_codes_0, bounding_codes_1]
    const date_created = moment();
    const time_created = ''
    const product = { bounding_codes: bounding_codes, type: type, drum_number: drum_number, joints: joints }
    const length =  parseInt(value.length)
    const model = new CablesModel({
      bounding_codes,
      type,
      drum_number,
      joints,
      length,
      date_created,
//      time_created

    });
    this.loading = true;
    this.store.updateCables(this.props.factory,this.props.id, model)
    .then(this.handleSaveSuccess)
    .catch(this.handleSaveError);
  };
  @action.bound
  handleSaveSuccess() {
    this.loading = false;
    this.refForm.current?.resetFields();

    notification.success({ message: `Изделие успешно сохранено` });
    this.props.onClose();
  }
  @action
  load() {
    const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) || undefined

    axios
      .get(api_url + '/v1/admin/product', { params: { factory_id: this.props.factory, product_id: this.props.id }, headers: { 'Authorization': 'Bearer ' + token } })
      .then(resp => (
        this.setState({
          bounding_codes_0: resp.data.bounding_codes[0],
          bounding_codes_1: resp.data.bounding_codes[1],
          drum_number: resp.data.drum_number,
          length: resp.data.length,
          type: resp.data.type,
          joints: resp.data.joints,
          length_joints: resp.data.joints.length,
          load: true,
          price: resp.data.price,
          codes: resp.data.num_codes,
		  factory_id: resp.data.factory_id,
		  factory_name: resp.data.factory_name,
		  status: resp.data.status,
		  certificate_url: resp.data.certificate_url,
		  files: resp.data.files,
		  new_file_link: "",
		  new_file_desc: "",
		  new_file_type: "",
		  new_file: null,
		  show_add_file_ui: false,
		  errors: resp.data.errors,
		  parent: resp.data.parent,
		  child_products: resp.data.child_products,
		  history: resp.data.history,
        }, () => {
        this.refForm.current?.setFieldsValue(this.state);
      })))
      .catch((response)=>(
        notification.error(
          { message: response.message}
          )))
  }

  @action.bound
  handleSaveError() {
    this.loading = false;

    notification.error({ message: `Ошибка сохранения` });
  }

  @action
  handleCancel = () => {
    this.loading = false;
    this.refForm.current?.resetFields();

    this.props.onClose();
  }

  @action
  handleUndelete = () => {
	this.changeStatus("new");
  }

  @action
  handleUnrelease = () => {
	this.changeStatus("new");
  }

  @action
  handleSoftDelete = () => {
		const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) || undefined;
		axios.interceptors.response.use(undefined, function (error) {
			  (error as any).originalMessage = error.message;
			  Object.defineProperty(error, "message", { get: function () {
					  if (!error.response) {
					  return (error as any).originalMessage;
					  }
					  return JSON.stringify(error.response.data);
					  }});
			  return Promise.reject(error);
			  });
		axios.delete(api_url+"/v1/admin/product", {
			data: {
				product_id: this.props.id,
				factory_id: this.props.factory,
			},
			headers: {
				Authorization: "Bearer "+token,
			},
		})
		.then(this.handleSaveSuccess)
		.catch((error) => {console.log(JSON.stringify(error.message));
			notification.error({message: JSON.parse(error.message).message})});
  }

  @action
  handleRelease = () => {
  	this.changeStatus("released");
  }

  changeStatus(status: string) {
		const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) || undefined;
		axios.interceptors.response.use(undefined, function (error) {
			  (error as any).originalMessage = error.message;
			  Object.defineProperty(error, "message", { get: function () {
					  if (!error.response) {
					  return (error as any).originalMessage;
					  }
					  return JSON.stringify(error.response.data);
					  }});
			  return Promise.reject(error);
			  });
		axios.post(api_url+"/v1/admin/product/set_status", {
			product_id: this.props.id,
			factory_id: this.props.factory,
			status,
		}, {
			headers: {
				Authorization: "Bearer "+token,
			},
		})
		.then(this.handleSaveSuccess)
		.catch((error) => {console.log(JSON.stringify(error.message));
			notification.error({message: JSON.parse(error.message).message})});
  }

  @action
  handleCertificateUpload(evt: any) {
  	const selectedFile = evt.target.files[0];
      const formData = new FormData();
	  formData.set("product_id", this.props.id);
	  formData.set("factory_id", this.props.factory);
      formData.set("file", selectedFile);

	  const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) || undefined;
      axios.put(api_url+"/v1/admin/product/certificate", formData, {
		headers: {
			Authorization: "Bearer "+token,
			'Content-Type': 'multipart/form-data',
		},
	  })
	  .then(() => { this.load()})
	  .catch((error) => {console.log(JSON.stringify(error.message))});
  }

  @action
  handleAddMaterial = () => {
    const value = this.refForm.current?.getFieldsValue();
	const {new_file_type, new_file_link, new_file_desc} = value;
	const new_file = this.state.new_file as any;

	if(!new_file_desc) {
		notification.error({message: "Не заполнен заголовок"}); return;
	}
	if(!new_file_type) {
		notification.error({message: "Не выбран тип материала"}); return;
	}
	if(!new_file && !new_file_link) {
		notification.error({message: "Не заполнена ссылка или не выбран файл"}); return;
	}

	const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) || undefined;
	let url = "";
	let data;
	let content_type;
	if(new_file_link) {
		data = {
product_id: this.props.id,
			factory_id: this.props.factory,
			title: new_file_desc,
			type: new_file_type,
			link: new_file_link,
		};
		url = "/v1/admin/product/link";
	} else {
		data = new FormData();
		data.set("product_id", this.props.id);
		data.set("factory_id", this.props.factory);
		data.set("title", new_file_desc);
		data.set("type", new_file_type);
		data.set("file", new_file);
		url = "/v1/admin/product/attachment";
		content_type = 'multipart/form-data';
	}

	axios.put(api_url+url, data, {
		headers: {
			Authorization: "Bearer "+token,
			"Content-Type": content_type ? content_type : "application/json",
		},
	})
	.then(() => { this.load()})
	.catch((error) => {notification.error({message: "Ошибка загрузки материалов"})});
  }

  @action
  handleDeleteAttachment = (i: number) => {
  	const file: any = this.state.files[i];
	const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) || undefined;
	axios.delete(api_url+"/v1/admin/product/attachment", {
		data: {
			product_id: this.props.id,
			factory_id: this.props.factory,
			link: file.link,
		},
		headers: {
			Authorization: "Bearer "+token,
		},
	})
	.then(() => { this.load()})
	.catch((error) => {notification.error({message: "Ошибка удаления материалов"})});
  }

  historyItemToDescription(h: any) {
	  try {
		  const file_description = (v: any) => {
			  let o = undefined;
			  try {
				  o = JSON.parse(v);
			  } catch(e) {
				  o = undefined;
			  }
			  if(!o) {
				  return v;
			  }
			  if(o.title) {
				  return o.title;
			  }
			  return v;
		  };

		  switch(h.name) {
		  case "length":
			  return `Изменена длина с ${h.old_value} на ${h.new_value}`;
			  break;
		  case "description":
			  return `Изменено описание продукции на "${h.new_value}"`;
			  break;
		  case "add-file":
			  return `Добавлен файл ${file_description(h.new_value)}`;
			  break;
		  case "remove-file":
			  return `Удален файл ${file_description(h.old_value)}`;
			  break;
		  case "replace-cert":
			  if(h.old_value) {
				  return `Замена паспорта качества`;
			  } else {
				  return `Прикреплен паспорт качества`;
			  }
			  break;
		  case "created":
			  return "Изделие выгружено из приложения Integrity-ОТК";
			  break;
		  case "release":
			  return "Выпуск";
			  break;
		  case "unrelease":
			  return "Отмена выпуска";
			  break;
		  case "delete":
			  return "Удаление изделия";
			  break;
		  case "undelete":
			  return "Отмена удаления изделия";
			  break;
		  default:
			  return h.name;
			  return "Неизвестное действие";
		  }
	  } catch(e) {
		  return "Неизвестное действие";
	  }
  }

  render() {
    if (this.state.load === false) {
      this.load()
    }

    const { visible } = this.props;

	// optional buttons
	let add_joints_button;
	if(this.state.type === 'datamatrix') {
		add_joints_button = <>
              <p></p>
              <Button
                key="addJoints"
                type="primary"
                disabled={this.loading}
                loading={this.loading}
                onClick={this.addJoints}
              >
                Добавить соединение
              </Button>
		</>;
	}

	let undelete_button;
	if(this.state.status == "deleted") {
		undelete_button = <>
              <p></p>
			  <Button
				  key="undelete"
				  type="primary"
				  disabled={this.loading}
				  loading={this.loading}
		  		  onClick={this.handleUndelete}
				>
					Отменить удаление изделия
			  </Button>
		</>;
	}

	let unrelease_button;
	if(this.state.status == "released") {
		undelete_button = <>
              <p></p>
			  <Button
				  key="unrelease"
				  type="primary"
				  disabled={this.loading}
				  loading={this.loading}
		  		  onClick={this.handleUnrelease}
				>
					Отменить выпуск изделия
			  </Button>
		</>;
	}

	let softdelete_button;
	if(this.state.status == "new") {
		softdelete_button = <>
              <p></p>
			  <Button
				  key="softdelete"
				  type="primary"
				  disabled={this.loading}
				  loading={this.loading}
		  		  onClick={this.handleSoftDelete}
				>
					Удалить изделие
			  </Button>
		</>;
	}

	let release_button;
	if(this.state.status == "new") {
		release_button = <>
              <p></p>
			<Button
				key="release"
				type="primary"
				disabled={this.loading}
				loading={this.loading}
				onClick={this.handleRelease}
			>
				Выпустить изделие
			</Button>
		</>;
	}

    return (
      <Modal
        title="Изменение изделия"
        visible={visible}
        width="800px" centered
        onCancel={this.handleCancel}
        footer={[
          <Button
            key="back"
            onClick={this.handleCancel}
          >
            Отмена
          </Button>,

          <Button
            key="submit"
            type="primary"
            disabled={this.loading}
            loading={this.loading}
            onClick={this.handleSave}
          >
            Сохранить
          </Button>,

        ]}


      >
        <Form ref={this.refForm} layout="vertical">
          <Row gutter={[16, 16]}>
			{// xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}
			}
            <Col lg={12}>
              <Form.Item
                label="Завод"
                name="factory_name"
                key="factory_name"
              >
                <Input
				  disabled
				  readOnly
                  placeholder="Завод"
                />
              </Form.Item>

              <Form.Item
                label="Номер барабана"
                name="drum_number"
                key="drum_number"
              >
                <Input
                  placeholder="Номер барабана"
                />
              </Form.Item>

              <Form.Item
                label="Первый код"
                name="bounding_codes_0"
                key="bounding_codes_0"
              >
                <Input
                  placeholder="Первый код"
                />
              </Form.Item>

              <Form.Item
                label="Последний код"
                name="bounding_codes_1"
                key="bounding_codes_1"
              >
                <Input
                  placeholder="Последний код"
                />
              </Form.Item>

              <Form.Item
                label="Длина"
                name="length"
                key="length"
              >
                <Input
                  placeholder="Длина"
                />
              </Form.Item>
              <Form.Item
                label="Тип кода"
                name="type"
                key="type"
              >
                <Select
                  placeholder="Тип кода"
				  disabled
                >
                  <Option value="code128">Code128</Option>
                  <Option value="datamatrix">DataMatrix</Option>
                </Select>
              </Form.Item>
              {this.state.joints.map((item: Array<string>, id: number) => {

                return <div key={"joints_"+id}>
                  <Form.Item
                    label="Список соединений"
                  >
                    <Input.Group >
                      {
                        item.map((code: string, id_code: number) => {

                          var name = 'joints';
                          return <div key={"joints_"+id+"_"+id_code}>
                            <Form.Item
                              name={[name, id, id_code]}
                              initialValue={code}
                            >
                              <Input placeholder="Соединение" />
                            </Form.Item><p></p>
                          </div>
                        })
                      }

                    </Input.Group>

                    <Button
                      key={"delete_joint"+id}
                      type="primary"
                      disabled={this.loading}
                      loading={this.loading}
                      onClick={() => this.deleteJoints(id)}
                    >
                      Удалить соединение
                  </Button>
                  </Form.Item>
                </div>

              })}
			  {add_joints_button}
              <p></p>
              <Form.Item
                label="Количество кодов"
                name="codes"
                key="codes"
              >
                <Input
                  readOnly
                  disabled
                  placeholder="Количество кодов"
                  onChange={() => this.handleChange("codes")}
                />
              </Form.Item>
              <Form.Item
                label="Цена"
                name="price"
                key="price"
              >
                <Input
                  readOnly
                  disabled
                  placeholder="Цена"
                  onChange={() => this.handleChange("price")}
                />
              </Form.Item>
            </Col>

            <Col lg={12}>
				<Form.Item
					label="Сертификат качества"
					key="certificate_url"
					name="certificate_url"
				>
					{(this.state.certificate_url && this.state.status === 'released') &&
						<a target="_blank" rel="noopener noreferrer" href={this.state.certificate_url+"?cache="+Date.now()}>Скачать сертификат</a>}
					{(this.state.certificate_url && this.state.status !== 'released') &&
						<a target="_blank" rel="noopener noreferrer" onClick={async () => {
							try {
								const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) || undefined;
								const resp = await axios({
									url: api_url + "/v1/auth/admin/download_token",
									method: "POST",
									headers: { 'Authorization': 'Bearer ' + token }});
								const dtoken = resp.data.token;
								const link =
									`${api_url}/v1/factory/products/attachment/${dtoken}/${this.props.id}/cert/cert.pdf`;
								window.open(link + '?cache='+Date.now(), "cert");
							} catch(e) {
								console.error("download cert error:", e);
							}
						}}>Скачать сертификат</a>
					}

					<Input
						id="uploadCertInput"
						type="file"
						hidden
						onChange={(evt) => this.handleCertificateUpload(evt)}
						/>
					<p/>
					<Button
						onClick={() =>
						{(document?.querySelector('#uploadCertInput') as
						HTMLElement)?.click()}}
					>Заменить сертификат</Button>
				</Form.Item>

				<p/>

				<Form.Item
					label="Дополнительные материалы"
					key="files"
					name="files"
				>
                    <Input.Group >
                      {
                        this.state.files.map((f: any, i: number) => {
						  let l = f.link;
						  if(f.is_external_link != undefined && !f.is_external_link) {
						  	l = l + "?cache="+Date.now();
						  }
                          return <div key={"files_"+i}>
						  <Row>
						    <Col>
						  	<a target="_blank" rel="noopener noreferrer" href={l}>{f.title}</a>
							</Col>
							<Col style={{flex: 1, textAlign: "right"}}>
							<DeleteOutlined onClick={() => this.handleDeleteAttachment(i)}/>
							</Col>
							</Row>
                          </div>;
                        })
                      }
                    </Input.Group>

				  {!this.state.show_add_file_ui &&
				  <Button
					key="addFileShowUI"
					disabled={this.loading}
					loading={this.loading}
					onClick={() => {this.setState({show_add_file_ui: true})}}
				  >Добавить материалы</Button>}

				  {this.state.show_add_file_ui &&
					  <Form.Item
					  >
						<Form.Item name="new_file_desc" key="new_file_desc">
						  	<Input placeholder="Заголовок"/>
						</Form.Item>
						<Form.Item name="new_file_link" key="new_file_link">
							<Input placeholder="Ссылка"/>
						</Form.Item>
						<Form.Item name="new_file_type" key="new_file_type">
							<Select placeholder="Тип файла">
								<Option value="doc">Документ</Option>
								<Option value="image">Фото</Option>
								<Option value="video">Видео</Option>
							</Select>
						</Form.Item>

					  	<Input type="file"
							onChange={(evt: any) => {this.setState({new_file:
							evt.target.files[0]})}}
						/>
						<Button onClick={this.handleAddMaterial}>Добавить файл</Button>
					  </Form.Item>
				  }

				  {this.state.parent && <>
				  <br/>
				  <span>Входит в состав изделия {this.state.parent as any}</span>
				  </>}

				  {this.state.child_products && this.state.child_products.length > 0 && <>
				  <br/>
				  <span>В состав изделия входят следующие типы маркировки:</span>
				  <br/>
				  {this.state.child_products.map((c: any) => {
				  	return <>
					<span>{c.uuid} ({c.codes_qty} кодов {c.type})</span>
					<br/>
					</>;
				  })}
				  <br/>
				  </>}

				  {this.state.errors && (this.state.errors as any).error_code &&
				  	<>
					<br/>
					<span>При выпуске изделия произошли ошибки: {(this.state.errors as any).error_description}</span>
					<br/>
					</>
				  }
				  {this.state.errors && (this.state.errors as any).error_code &&
				  Object.keys((this.state.errors as any).collisions).map((c_uuid: string) => {
				  	return <>
						<span>{c_uuid + " (" + (this.state.errors as any).collisions[c_uuid] + " кодов)"}</span>
						<br/>
					</>;
				  })
				  }

				  {this.state.history && (this.state.history as any).length > 0 &&
				  	<Collapse defaultActiveKey={[1]} style={{marginTop: '10px'}}>
						<Collapse.Panel header="История изменений" key="1">
						{(this.state.history as any).slice().reverse().slice(0, this.state.showHistoryRows).map((hi: any, idx: number) => {
							return <div style={{display: "flex", flexDirection: "column", gap: "8px"}} id={this.state.showHistoryRows as unknown as string}>
								<div style={{display: "flex", flexDirection: "row", gap: "8px"}}>
									<span style={{color: "#D21919"}}>{this.state.history.length-idx}</span>
									<span>{moment(hi.timestamp).format("MM.DD.YYYY HH:mm:ss")}</span>
								</div>
								<span>{hi.user_display_name}</span>
								<span>{this.historyItemToDescription(hi)}</span>
								{(idx < this.state.history.length-1) && <hr style={{width: "100%"}}/>}
								{idx === this.state.showHistoryRows-1 && this.state.history.length > this.state.showHistoryRows &&
									<a href="#" onClick={()=>{
										this.state.showHistoryRows = this.state.history.length;
										this.forceUpdate();
										}
									}>Показать все</a>
								}
							</div>;
						})}
						</Collapse.Panel>
					</Collapse>
				  }
				</Form.Item>

			  {undelete_button}
			  {unrelease_button}
			  {softdelete_button}
			  {release_button}
			</Col>
          </Row>
        </Form>

      </Modal>
    );
  }
}

export default CablesEditModal;
