import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { lazyInject } from '../utils/IoC';
import { AuthStore } from '../stores/auth/AuthStore';

@observer
class BlankLayout extends Component {
  @lazyInject(AuthStore)
  authStore!: AuthStore;

  componentDidMount() {
    this.authStore.initialize();
  }

  render() {
    if (this.authStore.isRefreshTokenValid === null) {
      return null;
    }

    return (<>{this.props.children}</>);
  }
}

export default BlankLayout;
