import React from 'react';
import { Button, Result } from 'antd';
import { history } from '../../utils/router';

const NotFoundPage: React.FC = () => (
  <Result
    status="404"
    title="404"
    subTitle="Страница, которую Вы ищете, не может быть найдена"
    extra={
      <Button type="primary" onClick={() => history.push('/')}>
        На главную
      </Button>
    }
  />
);

export default NotFoundPage;

