import { BadRequestResponseDTO } from '../dto/bad-request.response.dto';
import { observable } from 'mobx';

type ErrorsType = { [property: string]: string };

export class FormError extends Error {
  @observable errors: ErrorsType;

  constructor(errors: ErrorsType) {
    super();
    this.errors = errors;
  }

  static createFromBadRequestResponseDTO(dto: BadRequestResponseDTO) {
    return new FormError(
      Object.entries(dto)
        .map(([property, errors]) => ({ property, error: errors[0] }))
        .reduce((obj, item) => {
          return {
            ...obj,
            [item.property]: item.error,
          }
        }, {})
    )
  }
}
