import { lazyInject, provide } from '../../utils/IoC';
import { HttpClientV1 } from '../../utils/api/HttpClientV1';
import { action } from 'mobx';
import { UserModel } from './model/UserModel';
import { UserRegisterRequestDTO } from './dto/request/user-register.request.dto';
import { BaseTableStore } from '../../utils/store/BaseTableStore';
import { UserResponseDTO } from './dto/response/user.response.dto';

@provide.singleton()
export class UsersTableStore extends BaseTableStore<UserModel, UserResponseDTO> {
  @lazyInject(HttpClientV1)
  protected readonly httpClientV1!: HttpClientV1;

  protected readonly path = '/users';
  protected readonly httpClient = this.httpClientV1;

  protected readonly download_xlsx_path = '/users/xlsx';

  protected get searchDTO(): object {
    return {
      fullName: this.filters.fullName
        ? this.filters.fullName[0]
        : undefined,
      phoneNumber: this.filters.phoneNumber
        ? this.filters.phoneNumber[0]
        : undefined,
      email: this.filters.email ? this.filters.email[0] : undefined,
    };
  }

  protected get filterDTO(): object {
    return {
      factoryId: this.filters.factory
        ? (this.filters.factory as Array<{ id: string }>).map((v) => v.id)
        : undefined,

      role: this.filters.role
        ? this.filters.role.map((r) => r)
        : undefined,
    };
  }

  protected createModelFromResponseDTO(dto: UserResponseDTO): UserModel {
    return UserModel.createFromDTO(dto);
  }

  @action
  createUser(payload: UserRegisterRequestDTO) {
    return this.httpClientV1
      .post<UserRegisterRequestDTO>("users/register", payload)
      .then(this.createUserSuccess);
  }

  @action.bound
  protected createUserSuccess() {
    //
  }
}
