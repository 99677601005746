import React, { Component, RefObject } from 'react';
import { Col, DatePicker, Form, Input, InputNumber, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { FormError } from '../../../../../utils/error/FormError';
import { PriceModel } from '../../../../../stores/finances/prices/model/PriceModel';
import { observer } from 'mobx-react';
import { Moment } from 'moment';
import { action, observable } from 'mobx';

import styles from './index.module.less';
import { MomentDateFormat } from '../../../../../utils/MomentDateFormat';

const { Option } = Select;

export interface PriceModalFormValue {
  name?: string;
  datamatrix?: number;
  barcode?: number;
  startDate?: Moment;
  endDate?: Moment;
  barcode_pricing?: string;
}

interface Props {
  formRef: RefObject<FormInstance>;
  initialValues?: PriceModel;
  error?: FormError;
  onResetError?: (key: string) => void;
  showDates?: boolean;
}

@observer
class PricesModalForm extends Component<Props> {
  @observable startDate?: Moment = this.props.initialValues?.startDate;
  @observable endDate?: Moment = this.props.initialValues?.endDate;

  resetError = (key: string) => {
    this.props.onResetError && this.props.onResetError(key);
  };

  @action
  handleStartDateChange = (value?: Moment) => {
    this.startDate = value ? value.set({hour: 0, minute: 0, seconds: 0, milliseconds: 0}) : undefined;
    this.props.formRef.current?.setFields([{ name: 'startDate', value: this.startDate }]);
  };

  @action
  handleEndDateChange = (value?: Moment) => {
    this.endDate = value ? value.set({hour: 23, minute: 59, seconds: 59, milliseconds: 999}) : undefined;
    this.props.formRef.current?.setFields([{ name: 'endDate', value: this.endDate }]);
  };

  render() {
    const { formRef, initialValues, error } = this.props;

    const hasNameError = !!(error?.errors?.name);
    const hasBarcodeError = !!(error?.errors?.barcode);
    const hasDatamatrixError = !!(error?.errors?.datamatrix);
    const hasStartDateError = !!(error?.errors?.startDate);
    const hasEndDateError = !!(error?.errors?.endDate);

    return (
      <Form
        ref={formRef}
        layout='vertical'
        initialValues={{
          ...initialValues,
          datamatrix: +(initialValues?.datamatrix || 0) || 0,
          barcode: +(initialValues?.barcode || 0) || 0,
        }}
      >
        <Form.Item
          label='Наименование'
          name="name"
          key='name'

          hasFeedback={hasNameError}
          validateStatus={hasNameError ? 'error' : undefined}
          help={error?.errors?.name}
        >
          <Input
            placeholder='Введите наименование'
            onChange={() => this.resetError("name")}
          />
        </Form.Item>

        <Form.Item
          label='Цена за код, нанесенный принтером'
          name='barcode'
          key='barcode'

          hasFeedback={hasBarcodeError}
          validateStatus={hasBarcodeError ? 'error' : undefined}
          help={error?.errors?.barcode}
        >
          <InputNumber
            placeholder='Укажите цену за код, нанесенный принтером'
            className={styles.inputnumber}
            min={0}
            precision={2}
            step={0.01}
            onChange={() => this.resetError("barcode")}
          />
        </Form.Item>

        <Form.Item
          label='Цена за код на ленте'
          name='datamatrix'
          key='datamatrix'

          hasFeedback={hasDatamatrixError}
          validateStatus={hasDatamatrixError ? 'error' : undefined}
          help={error?.errors?.datamatrix}
        >
          <InputNumber
            placeholder='Укажите цену за код на ленте'
            className={styles.inputnumber}
            min={0}
            precision={2}
            step={0.01}
            onChange={() => this.resetError("datamatrix")}
          />
        </Form.Item>

        {this.props.showDates &&
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label='Дата начала'
                name='startDate'
                key='startDate'

                hasFeedback={hasStartDateError}
                validateStatus={hasStartDateError ? 'error' : undefined}
                help={error?.errors?.startDate}
              >
                <DatePicker
                  placeholder='Выберите дату'
                  format={MomentDateFormat}
                  className={styles.datepicker}
                  disabledDate={d => this.endDate !== undefined ? d.isAfter(this.endDate) : false}
                  onChange={(value?: Moment | null) => {
                    this.handleStartDateChange(value || undefined);
                    this.resetError("startDate");
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label='Дата окончания'
                name='endDate'
                key='endDate'

                hasFeedback={hasEndDateError}
                validateStatus={hasEndDateError ? 'error' : undefined}
                help={error?.errors?.endDate}
              >
                <DatePicker
                  placeholder='Выберите дату'
                  format={MomentDateFormat}
                  className={styles.datepicker}
                  disabledDate={d => this.startDate !== undefined ? d.isBefore(this.startDate) : false}
                  onChange={(value?: Moment | null) => {
                    this.handleEndDateChange(value || undefined);
                    this.resetError("endDate")
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        }

          <Row gutter={[16, 16]}>
            <Col span={12}>
			  <Form.Item
			  	label="Способ расчета цены за коды, нанесенные принтером"
				name="barcode_pricing"
				key="barcode_pricing"
			  >
			  	<Select
				  defaultValue="code">
					<Option value="code" default>Цена за код</Option>
					<Option value="meter">Цена за метр</Option>
				</Select>
			  </Form.Item>
			</Col>
		  </Row>
      </Form>
    );
  }
}

export default PricesModalForm;
