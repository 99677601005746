import React, { Component } from 'react';
import { DatePicker } from 'antd';
import TableButtonFiltersDropdown from './TableButtonFiltersDropdown';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import moment, { Moment } from 'moment';
import { MomentDateFormat } from '../../utils/MomentDateFormat';

interface Props extends FilterDropdownProps {
  dataIndex?: string;
}

class TableDateFilterDropdown extends Component<Props> {
  handleFromDatePickerChange = (value: Moment | null) => {
    if(value) value = value.utc();
    this.props.setSelectedKeys(
      value
        ? [
          (value.set({hour: 0, minute: 0, seconds: 0, milliseconds: 0}) as unknown) as string,
          this.props.selectedKeys[1]
        ]
        : [
          (undefined as unknown) as string,
          this.props.selectedKeys[1]
        ]
    );
  };

  handleToDatePickerChange = (value: Moment | null) => {
    if(value) value = value.utc();
    this.props.setSelectedKeys(
      value
        ? [
          this.props.selectedKeys[0],
          (value.set({hour: 23, minute: 59, seconds: 59, milliseconds: 999}) as unknown) as string
        ]
        : [
          this.props.selectedKeys[0],
          (undefined as unknown) as string
        ]
    );
  };

  render() {
    const { clearFilters, selectedKeys, confirm } = this.props;

    return (
      <div style={{ padding: "8px" }}>
        <DatePicker
          placeholder="От"
          format={MomentDateFormat}
          disabledDate={d => selectedKeys[1] !== undefined ? d.isAfter(moment(selectedKeys[1])) : false}
          value={selectedKeys[0] === undefined ? null : moment(selectedKeys[0])}
          onChange={this.handleFromDatePickerChange}
          style={{ marginBottom: 8, display: "flex" }}
        />
        <DatePicker
          placeholder="До"
          format={MomentDateFormat}
          disabledDate={d => selectedKeys[0] !== undefined ? d.isBefore(moment(selectedKeys[0])) : false}
          onChange={this.handleToDatePickerChange}
          value={selectedKeys[1] === undefined ? null : moment(selectedKeys[1])}
          style={{ marginBottom: 8, display: "flex" }}
        />
        <TableButtonFiltersDropdown
          handleReset={clearFilters}
          handleSearch={confirm}
        />
      </div>
    );
  }
}

export const getTableDateFilterDropdownProps = () => ({
  filterDropdown: (props: FilterDropdownProps) => (
    <TableDateFilterDropdown {...props} />
  ),
});

export default TableDateFilterDropdown;
