import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { Button, Card, Col, notification, Row, Skeleton, Table } from 'antd';
import EquipmentTableRowAction from './component/FactoryTableRowAction/index';
import { action, observable, toJS } from 'mobx';
import FactoryCreateModal from './component/FactoryCreateModal/index';
import FactoryEditModal from './component/FactoryEditModal/index';
import { observer } from 'mobx-react';
import { getTableTextSearchDropdownProps } from '../../components/Table/TableTextSearchDropdown';
import { lazyInject } from '../../utils/IoC';
import { FactoryTableStore } from '../../stores/factory/FactoryTableStore';
import { FactoryModel } from '../../stores/factory/model/FactoryModel';
import { CategoryAccessStore } from '../../stores/users/CategoryAccessStore';
import { Redirect } from 'react-router';

@observer
class FactoryPage extends Component {
  @lazyInject(FactoryTableStore)
  store!: FactoryTableStore;

  @lazyInject(CategoryAccessStore)
  categoryAccessStore!: CategoryAccessStore;

  @observable isModalVisible = { create: false, edit: false };
  @observable currentEditId = '';

  componentDidMount() {
    this.store.fetchAll();
  }

  //region Handlers
  deleteRecord = (id: string) => {
    this.store.deleteItem(id).catch(() => notification.error({ message: 'Не удалось удалить завод' }));
  };

  @action
  openCreateModal = () => {
    this.isModalVisible.create = true;
  };

  @action
  closeCreateModal = () => {
    this.isModalVisible.create = false;
  };

  @action
  openEditModal = (id: string) => {
    this.currentEditId = id;
    this.isModalVisible.edit = true;
  };

  @action
  closeEditModal = () => {
    this.isModalVisible.edit = false;
    this.currentEditId = '';
  };

  @action
  handleDownloadXlsx = () => {
  	this.store.downloadXlsx("factories.xlsx");
  };
  //endregion

  render() {
    if (!this.categoryAccessStore.loading && !this.categoryAccessStore.hasFactyoryAccess) {
      return <Redirect to='/' />;
    }

    const columns = [
      {
        title: null,
        key: "index",
        render: (value: any, item: any, index: number) => {
            const p = this.store.tablePagination;
            return (<a href="#" onClick={() => this.openEditModal(item.id)}>{p.pageSize * (p.current-1)+1+index}</a>);
        },
      },
      {
        title: "Наименование",
        dataIndex: "name",
        key: 'name',
        filteredValue: (this.store.filters.name as string[]) || null,
        ...getTableTextSearchDropdownProps("Наименование"),
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["name"],
      },
      {
        title: "Полное Наименование",
        dataIndex: "fullName",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["fullName"],
        filteredValue: (this.store.filters.fullName as string[]) || null,
        ...getTableTextSearchDropdownProps("Полное наименование"),
      },

      {
        title: "Страна",
        dataIndex: "country",
        filteredValue: (this.store.filters.country as string[]) || null,
        ...getTableTextSearchDropdownProps("Поиск страны"),
      },

      {
        title: "Город",
        dataIndex: "city",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["city"],
        filteredValue: (this.store.filters.city as string[]) || null,
        ...getTableTextSearchDropdownProps("Поиск города"),
      },

      {
        title: "Адрес",
        dataIndex: "address",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["address"],
        filteredValue: (this.store.filters.address as string[]) || null,
        ...getTableTextSearchDropdownProps("Поиск адреса"),
      },

      {
        title: "Начальник ОТК",
        dataIndex: "otk",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["otk"],
        filteredValue: (this.store.filters.otk as string[]) || null,
        ...getTableTextSearchDropdownProps("Поиск OTK"),
      },
      {
        title: "Действия",
        render: (text: string, record: FactoryModel) => (
          <EquipmentTableRowAction
            id={record.id as any}
            onEdit={(id) => this.openEditModal(id as string)}
//            onDelete={(id) => this.deleteRecord(id as string)}
          />
        ),
      },
    ];

    return (
      <PageHeaderWrapper>
        <FactoryCreateModal
          visible={this.isModalVisible.create}
          onClose={this.closeCreateModal}
        />

        {this.isModalVisible.edit && this.currentEditId !== '' &&
          <FactoryEditModal
            visible={this.isModalVisible.edit}
            onClose={this.closeEditModal}
            id={this.currentEditId}
          />
        }

        <Card>
          <Row gutter={[16, 16]}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Button
                size="large"
                block
                type="primary"
                onClick={this.openCreateModal}
              >
                Добавить завод
              </Button>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 5, offset: 6 }}
              lg={{ span: 4, offset: 8 }}
              xl={{ span: 4, offset: 8 }}
            >
              <Button block onClick={this.store.handleTableRefresh}>
                Обновить список
              </Button>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 5 }}
              lg={{ span: 4 }}
              xl={{ span: 4 }}
            >
              <Button block onClick={this.store.handleTableReset}>
                Сбросить фильтры
              </Button>
            </Col>

			<Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 5 }}
              lg={{ span: 4 }}
              xl={{ span: 2 }}
            >
			  <Button block onClick={this.handleDownloadXlsx}>
			  XLS
			  </Button>
			</Col>
          </Row>
          <Table
            loading={this.store.loading}
            columns={columns}
            rowKey={(record) => record.id as string}
            dataSource={toJS(this.store.items)}
            
            pagination={{
              position: ["bottomRight"],
              showSizeChanger: true,
              ...this.store.tablePagination,
            }}
            locale={{
              emptyText: this.store.loading ? (
                <>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </>
              ) : (
                <p>Данные отсутствуют</p>
              ),
            }}
            onChange={this.store.handleTableChange}
            onRow={(record, rowIndex) => {
                return {
                    onDoubleClick: event => {
                      this.openEditModal(record.id as string);
                    },
                };
            }}
          />
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default FactoryPage;
