import { lazyInject, provide } from '../../../utils/IoC';
import { HttpClientV1 } from '../../../utils/api/HttpClientV1';
import { FactoriesPricesTableStore } from './FactoriesPricesTableStore';
import { action } from 'mobx';
import { AxiosResponse } from 'axios';
import { FormError } from '../../../utils/error/FormError';
import { FactoryPriceModel } from './model/FactoryPriceModel';
import { FactoryPriceResponseDTO } from './dto/response/factory-price.response.dto';
import { AddFactoryPriceRequestDTO } from './dto/request/add-factory-price.request.dto';

@provide.singleton()
export class FactoryPriceCreateModalStore {
  @lazyInject(HttpClientV1)
  protected readonly httpClientV1!: HttpClientV1;

  @lazyInject(FactoriesPricesTableStore)
  tableStore!: FactoriesPricesTableStore;

  @action
  createFactoryPrice(payload: AddFactoryPriceRequestDTO) {
    return this.httpClientV1.post<AddFactoryPriceRequestDTO, FactoryPriceResponseDTO>(
      '/factories-prices', payload,
    ).then(this.onCreateFactoryPriceSuccess).catch(this.onCreateFactoryPriceError) as Promise<FactoryPriceModel>;
  }

  @action.bound
  protected onCreateFactoryPriceSuccess(data: FactoryPriceResponseDTO) {
    this.tableStore.fetchAll();
    return FactoryPriceModel.createFromDTO(data);
  }

  @action.bound
  protected onCreateFactoryPriceError(reason: AxiosResponse) {
    if (reason.status === 400) {
      throw FormError.createFromBadRequestResponseDTO(reason.data);
    }

    const { description } = reason.data.message[0];
    throw new Error(description);
  }
}
