import { observable } from 'mobx';
import moment, { Moment } from 'moment';
import { PriceResponseDTO } from '../dto/response/prices.response.dto';

export class PriceModel {
  @observable id?: string;
  @observable name?: string;
  @observable datamatrix?: string; // код на ленте
  @observable barcode?: string; // код нанесенный принтером
  @observable pipe?: string;
  @observable updated?: Moment;
  @observable startDate?: Moment;
  @observable endDate?: Moment;
  @observable barcode_pricing?: string;

  constructor(model: PriceModel) {
    this.id = model.id;
    this.name = model.name;
    this.datamatrix = model.datamatrix;
    this.barcode = model.barcode;
    this.pipe = model.pipe;
    this.updated = model.updated;
    this.startDate = model.startDate;
    this.endDate = model.endDate;
	this.barcode_pricing = model.barcode_pricing;
  }

  static createFromDTO(dto: PriceResponseDTO) {
    return new PriceModel({
      id: dto.id,
      name: dto.name,
      datamatrix: dto.datamatrix,
      barcode: dto.barcode,
      pipe: dto.pipe,
      updated: dto.updated ? moment(dto.updated) : undefined,
      startDate: dto.startDate ? moment(dto.startDate) : undefined,
      endDate: dto.endDate ? moment(dto.endDate) : undefined,
	  barcode_pricing: dto.barcode_pricing,
    });
  }
}
