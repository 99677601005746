import { lazyInject, provide } from "../../utils/IoC";
import { HttpClientV1 } from "../../utils/api/HttpClientV1";
import { action } from "mobx";
import { CablesModel } from "./model/CablesModel";
import { CablesResponseDTO } from "./dto/response/cables.response.dto";
import axios, { AxiosResponse } from "axios";
import { FormError } from "../../utils/error/FormError";
import { CablesTableStore } from "./CablesTableStore";
import { HttpClientV1TwoApi } from "../../utils/api/HttpClientV1twoApi";
import { ACCESS_TOKEN_STORAGE_KEY } from "../auth/const/AuthTokensStorageKeys";
import { AddCablesRequestDTO } from "./dto/request/add-cables.request.dto";

@provide.singleton()
export class CablesCreateModalStore {
  @lazyInject(HttpClientV1TwoApi)
  protected readonly HttpClientTwo!: HttpClientV1TwoApi;

  @lazyInject(CablesTableStore)
  tableStore!: CablesTableStore;

  @action
  createFactory(model: CablesModel) {
    const product = {
      length: model.length,
      codes: model.codes,
      type: model.type,
      drum_number: model.drum_number,
      price: model.price,
      joints: model.joints,
      bounding_codes: model.bounding_codes,
    };

    return this.HttpClientTwo.put<{}, CablesResponseDTO>(
      "/product",
      {},
      {
        data: {
          product_id: null,
          create_new: true,
          factory_id: model.factory?.id,
          product: product,
        },
        headers: { "Content-Type": "application/json" },
      }
    )
      .then(this.onCreateFactorySuccess)
      .catch(this.onCreateFactoryError) as Promise<CablesModel>;
    /*return axios
      .put<{}, CablesResponseDTO>(
        "http://140.238.209.40:3000/v1/admin/product",
        {
          product_id: null,
          create_new: true,
          factory_id: model.factory?.id,
          product: product,
        },
        {
          headers: {
            Authorization:
              "Bearer " + localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) ||
              undefined,
          },
        }
      )
      .then((resp) => this.onCreateFactorySuccess(resp))
      .catch(this.onCreateFactoryError) as Promise<CablesModel>;*/
  }

  @action.bound
  protected onCreateFactorySuccess(data: CablesResponseDTO) {
    console.log(data);
    this.tableStore.fetchAll();
    return CablesModel.createFromDTO(data);
  }

  @action.bound
  protected onCreateFactoryError(reason: AxiosResponse) {
    if (reason.status === 400) {
      const data = Object.entries(reason.data as Record<string, string>)
        .map(([field, value]) => [field.replace("body.", ""), value])
        .reduce((obj, [field, value]) => ({ ...obj, [field]: value }), {});
      throw FormError.createFromBadRequestResponseDTO(data);
    }
    if (reason.status === 422) {
      const data = Object.entries(reason.data as Record<string, string>)
        .map(([field, value]) => [field.replace("body.", ""), value])
        .reduce((obj, [field, value]) => ({ ...obj, [field]: value }), {});
      throw FormError.createFromBadRequestResponseDTO(data);
    }

    throw new FormError({});
  }
}
