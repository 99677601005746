import { lazyInject, provide } from '../../utils/IoC';
import { HttpClientV1 } from '../../utils/api/HttpClientV1';
import { action, observable } from 'mobx';
import { JwtAccessTokenResponseDTO } from './dto/jwt-access-token.response.dto';
import {
  ACCESS_TOKEN_EXPIRES_IN_STORAGE_KEY,
  ACCESS_TOKEN_STORAGE_KEY,
  REFRESH_TOKEN_EXPIRES_IN_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY
} from './const/AuthTokensStorageKeys';
import { AxiosResponse } from 'axios';
import { JwtTokensResponseDTO } from './dto/jwt-tokens.response.dto';
import * as SocketIOClient from 'socket.io-client';
import { WebsocketUserNotificationType } from './const/WebsocketUserNotificationType';
import { notification } from 'antd';

@provide.singleton()
export class AuthStore {
  @lazyInject(HttpClientV1)
  protected readonly httpClientV1!: HttpClientV1;

  protected socket?: SocketIOClient.Socket;

  @observable initialized: boolean = false;

  @observable isRefreshTokenValid: boolean | null = null;
  @observable isAccessTokenValid: boolean | null = null;

  @observable accessTokenCache: string | undefined;

  protected updateAccessTokenTimeout?: NodeJS.Timeout;

  get accessToken(): string | undefined {
    return localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) || undefined;
  }

  get accessTokenExpiresIn(): number | undefined {
    return Number(localStorage.getItem(ACCESS_TOKEN_EXPIRES_IN_STORAGE_KEY)) || undefined;
  }

  get refreshToken(): string | undefined {
    return localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY) || undefined;
  }

  get refreshTokenExpiresIn(): number | undefined {
    return Number(localStorage.getItem(REFRESH_TOKEN_EXPIRES_IN_STORAGE_KEY)) || undefined;
  }

  constructor() {
    this.initialize();
  }

  setAccessToken(value: { token: string, expiresIn: number } | undefined) {
    if (!value) {
      localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
      localStorage.removeItem(ACCESS_TOKEN_EXPIRES_IN_STORAGE_KEY);

      if (this.updateAccessTokenTimeout) {
        clearTimeout(this.updateAccessTokenTimeout);
        this.updateAccessTokenTimeout = undefined;
      }

      this.isAccessTokenValid = false;
      this.accessTokenCache = undefined;
      this.disconnectSocket();
      return;
    }

    localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, value.token);
    localStorage.setItem(ACCESS_TOKEN_EXPIRES_IN_STORAGE_KEY, value.expiresIn.toString());
    this.accessTokenCache = value.token;
    this.connectSocket();

    this.setupAccessTokenRotation();
  }

  setRefreshToken(value: { token: string, expiresIn: number } | undefined) {
    if (!value) {
      localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
      localStorage.removeItem(REFRESH_TOKEN_EXPIRES_IN_STORAGE_KEY);
      this.isRefreshTokenValid = false;
      return;
    }

    localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, value.token);
    localStorage.setItem(REFRESH_TOKEN_EXPIRES_IN_STORAGE_KEY, value.expiresIn.toString());
  }

  @action
  fetchAccessToken() {
    const { refreshToken } = this;
    if (!refreshToken) {
      return;
    }

    return this.httpClientV1.post<{}, JwtAccessTokenResponseDTO>('/auth/access_token', {}, {
      params: { 'refresh_token': refreshToken }
    }).then(this.onFetchAccessTokenSuccess).catch(this.onFetchAccessTokenError);
  };

  @action.bound
  protected onFetchAccessTokenSuccess({ accessToken: token, expiresIn }: JwtAccessTokenResponseDTO) {
    this.setAccessToken({ token, expiresIn });
    this.isAccessTokenValid = true;
  }

  @action.bound
  protected onFetchAccessTokenError(response: AxiosResponse) {
    this.isAccessTokenValid = false;
    if (response.status === 401) {
      this.setAccessToken(undefined);
      this.setRefreshToken(undefined);

      this.isRefreshTokenValid = false;
    }
  }

  @action
  async fetchRefreshTokenStatus() {
    const { refreshToken } = this;
    if (!refreshToken) {
      this.isRefreshTokenValid = false;
      return;
    }

    return this.httpClientV1.get('/auth/refresh_token/status', {
      params: { 'refresh_token': refreshToken }
    }).then(this.onFetchRefreshTokenStatusSuccess).catch(this.onFetchRefreshTokenStatusError);
  }

  @action.bound
  protected onFetchRefreshTokenStatusSuccess() {
    this.isRefreshTokenValid = true;
  }

  @action.bound
  protected onFetchRefreshTokenStatusError(response: AxiosResponse) {
    if (response.status === 401) {
      this.setRefreshToken(undefined);
      this.setAccessToken(undefined);
    }

    // TODO: Handle other responses (i.e. redirect user to service unavailable page)
    this.isRefreshTokenValid = false;
  }

  @action
  setupAccessTokenRotation() {
    const { accessTokenExpiresIn } = this;

    if (!accessTokenExpiresIn) {
      return;
    }

    this.updateAccessTokenTimeout = setTimeout(
      () => this.fetchAccessToken(),
      accessTokenExpiresIn * 1000 - Date.now() - 5000
    );
  }

  @action
  initialize() {
    if (this.initialized) {
      return;
    }

    this.initialized = true;

    const { refreshToken } = this;
    if (!refreshToken) {
      this.isRefreshTokenValid = false;
    }

    this.fetchRefreshTokenStatus().finally(() => {
      this.isRefreshTokenValid && this.fetchAccessToken();
    });
  }

  @action.bound
  onLoginSuccess(data: JwtTokensResponseDTO) {
    this.setAccessToken({ token: data.accessToken, expiresIn: data.accessTokenExpiresIn });
    this.setRefreshToken({ token: data.refreshToken, expiresIn: data.refreshTokenExpiresIn });

    this.isAccessTokenValid = true;
    this.isRefreshTokenValid = true;
  }

  @action
  logout() {
    this.httpClientV1.post('/auth/logout').finally(this.onLogoutResult);
  }

  @action.bound
  protected onLogoutResult() {
    this.setAccessToken(undefined);
    this.setRefreshToken(undefined);

    this.isRefreshTokenValid = false;
  }

  connectSocket() {
    const query = { authorization: this.accessTokenCache };
    if (!this.socket) {
      const isDevelopment = process.env.NODE_ENV === 'development';
      const url = isDevelopment
        ? window.location.host.replace("3000", "4000")
        : window.location.host;

      this.socket = SocketIOClient.connect(url, {
        transports: ["websocket"], query
      });

      this.socket.on('user_notifications', this.handleUserNotifications);
    } else {
      this.socket.io.opts.query = query;
    }

    if (!this.socket.connected) {
      this.socket.connect();
    }
  }

  disconnectSocket() {
    if (!this.socket || !this.socket.connected) {
      return;
    }

    this.socket.disconnect();
  }

  @action.bound
  handleUserNotifications(message: { userNotificationType: WebsocketUserNotificationType }) {
    if (message.userNotificationType === WebsocketUserNotificationType.UserBlocked) {
      notification.info({ message: 'Ваш пользователь был заблокирован' });
      this.logout();
      return;
    }

    if (message.userNotificationType === WebsocketUserNotificationType.UserDeleted) {
      notification.info({ message: 'Ваш пользователь был удален' });
      this.logout();
      return;
    }

    if (message.userNotificationType === WebsocketUserNotificationType.UserRoleChanged) {
      notification.info({ message: 'У Вашего пользователя была изменена роль' });
    }

    if (message.userNotificationType === WebsocketUserNotificationType.UserPermissionsChanged) {
      notification.info({ message: 'У Вашего пользователя были изменены права доступа' });
    }

    this.fetchAccessToken();
  }

}
