import React, { Component } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons/lib';
import { Divider, Popconfirm } from 'antd';

interface Props {
  id: string;
  onEdit(id: string): void;
  onDelete(id: string): void;
}

class UserTableRowAction extends Component<Props> {
  render() {
    const { id, onDelete, onEdit } = this.props;
    return (
      <>
        <EditOutlined onClick={() => onEdit(id)} />
        <Divider type="vertical" />
        <Popconfirm
          title="Вы действительно хотите удалить запись?"
          cancelText="Отмена"
          okText="Подтвердить"
          onConfirm={() => onDelete(id)}
        >
          <DeleteOutlined />
        </Popconfirm>
      </>
    );
  }
}

export default UserTableRowAction;
