import React, { Component } from 'react';
import TableButtonFiltersDropdown from './TableButtonFiltersDropdown';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { StatusProduct } from '../../stores/factory/const/status-product.enum';
import StatusSelect from '../StatusSelect';
import { TypeCode } from '../../stores/factory/const/type-code.enum';
import TypeCodeSelect from '../TypeCodeSelect';

interface Props extends FilterDropdownProps {
  placeholder?: string;
}

export const getTableTypeCodeDropdownProps = () => ({
  filterDropdown: (props: FilterDropdownProps) => (
    <TableTypeCodeFilterDropdown {...props} />
  ),
});

class TableTypeCodeFilterDropdown extends Component<Props> {
  handleChange = (type: TypeCode) => {
    this.props.setSelectedKeys(
      !type ? [] : [(type as unknown) as string]
    );
  };

  render() {
    const { clearFilters, confirm } = this.props;

    return (
      <div style={{ padding: "8px" }}>
        <TypeCodeSelect
          placeholder='Выберите тип кода'
          style={{ minWidth: '200px', width: "100%", marginBottom: "8px" }}
          onChange={this.handleChange}
        />

        <TableButtonFiltersDropdown
          handleReset={clearFilters}
          handleSearch={confirm}
        />
      </div>
    );
  }
}

export default TableTypeCodeFilterDropdown;
