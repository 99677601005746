import { lazyInject, provide } from '../../../utils/IoC';
import { BaseTableStore } from '../../../utils/store/BaseTableStore';
import { FactoryPriceModel } from './model/FactoryPriceModel';
import { FactoryPriceResponseDTO } from './dto/response/factory-price.response.dto';
import { HttpClientV1 } from '../../../utils/api/HttpClientV1';
import { action, observable } from 'mobx';
import { Moment } from 'moment';

@provide.singleton()
export class FactoriesPricesTableStore extends BaseTableStore<FactoryPriceModel, FactoryPriceResponseDTO>{
  @lazyInject(HttpClientV1)
  protected readonly httpClientV1!: HttpClientV1;

  @observable isActual: boolean = true;

  protected readonly path = '/factories-prices';
  protected readonly httpClient = this.httpClientV1;

  protected readonly download_xlsx_path = '/factories-prices/xlsx';

  protected get searchDTO(): object {
    return {};
  }

  protected get filterDTO(): object {
    return {
      isActual: this.isActual,

      factoryId: this.filters.factory
        ? (this.filters.factory as Array<{ id: string }>).map((v) => v.id)
        : undefined,

      priceId: this.filters.price
        ? (this.filters.price as Array<{ id: string }>).map((v) => v.id)
        : undefined,

      datamatrix: this.filters.datamatrix
        ? {
          from: this.filters.datamatrix[0] || undefined,
          to: this.filters.datamatrix[1] || undefined
        }
        : undefined,

      barcode: this.filters.barcode
        ? {
          from: this.filters.barcode[0] || undefined,
          to: this.filters.barcode[1] || undefined
        }
        : undefined,

      startDate: this.filters.startDate
        ? {
          from: this.filters.startDate[0]
            ? (this.filters.startDate[0] as Moment)
              .toDate()
              .getTime()
            : undefined,
          to: this.filters.startDate[1]
            ? (this.filters.startDate[1] as Moment)
              .toDate()
              .getTime()
            : undefined,
        }
        : undefined,

      endDate: this.filters.endDate
        ? {
          from: this.filters.endDate[0]
            ? (this.filters.endDate[0] as Moment)
              .toDate()
              .getTime()
            : undefined,
          to: this.filters.endDate[1]
            ? (this.filters.endDate[1] as Moment)
              .toDate()
              .getTime()
            : undefined,
        }
        : undefined,
    };
  }

  protected createModelFromResponseDTO(dto: FactoryPriceResponseDTO): FactoryPriceModel {
    return FactoryPriceModel.createFromDTO(dto);
  }

  @action
  setIsActual = (value: boolean) => {
    this.isActual = value;
    this.fetchAll();
  }
}
