import { lazyInject, provide } from '../../../utils/IoC';
import { HttpClientV1 } from '../../../utils/api/HttpClientV1';
import { action, observable } from 'mobx';
import { LoginRequestDTO } from './dto/login.request.dto';
import { AuthStore } from '../../../stores/auth/AuthStore';
import { JwtTokensResponseDTO } from '../../../stores/auth/dto/jwt-tokens.response.dto';
import { AxiosResponse } from 'axios';
import { FormError } from '../../../utils/error/FormError';

@provide.singleton()
export class LoginStore {
  @lazyInject(HttpClientV1)
  protected readonly httpClientV1!: HttpClientV1;

  @lazyInject(AuthStore)
  protected readonly authStore!: AuthStore;

  @observable loading: boolean = false;

  @action
  loginAttempt(email: string, password: string) {
    this.loading = true;

    return this.httpClientV1.post<LoginRequestDTO, JwtTokensResponseDTO>(
      '/auth/login',
      { email, password }
    ).then(this.onLoginAttemptSuccess).catch(this.onLoginAttemptError);
  }

  @action.bound
  protected onLoginAttemptSuccess(data: JwtTokensResponseDTO) {
    this.authStore.onLoginSuccess(data);
    this.loading = false;
  }

  @action.bound
  protected onLoginAttemptError(reason: AxiosResponse) {
    this.loading = false;

    if (reason.status === 400) {
      throw FormError.createFromBadRequestResponseDTO(reason.data);
    }

    if (reason.status === 401 || reason.status === 403) {
      const { description } = reason.data.message[0];
      throw new FormError({ email: description, password: description })
    }
  }
}
