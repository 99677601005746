import { observable } from 'mobx';
import { UserFactoryResponseDTO } from '../dto/response/user-factory.response.dto';

export class UserFactoryModel {
  @observable id!: string;
  @observable name?: string;

  constructor(model: UserFactoryModel) {
    this.id = model.id;
    this.name = model.name;
  }

  static createFromDTO(dto: UserFactoryResponseDTO) {
    return new UserFactoryModel({
      id: dto.id,
      name: dto.name,
    });
  }
}
