import { lazyInject, provide } from '../../utils/IoC';
import { HttpClientV1 } from '../../utils/api/HttpClientV1';
import { action } from 'mobx';
import { EquipmentModel } from './model/EquipmentModel';
import { RcFile } from 'antd/lib/upload';
import { AddEquipmentRequestDTO } from './dto/request/add-equipment.request.dto';
import { EquipmentResponseDTO } from './dto/response/equipment.response.dto';
import { AxiosResponse } from 'axios';
import { FormError } from '../../utils/error/FormError';
import { EquipmentTableStore } from './EquipmentTableStore';

@provide.singleton()
export class EquipmentCreateModalStore {
  @lazyInject(HttpClientV1)
  protected readonly httpClientV1!: HttpClientV1;

  @lazyInject(EquipmentTableStore)
  tableStore!: EquipmentTableStore;

  @action
  createEquipment(model: EquipmentModel, files: { name: string, content: RcFile }[]) {
    const body: AddEquipmentRequestDTO = {
      serialNumber: model.serialNumber,
      factoryId: model.factory?.id,
      name: model.name,
      deliveryDate: model.deliveryDate?.toDate().getTime() as number,
      leaseEndDate: model.leaseEndDate?.toDate().getTime() as number,
      comment: model.comment || '',
    };

    const formData = new FormData();
    formData.append('body', JSON.stringify(body));
    files.forEach(f => formData.append('files', f.content, f.name))

    return this.httpClientV1.post<{}, EquipmentResponseDTO>(
      '/equipment',
      {},
      {
        data: formData,
        headers: {'Content-Type': 'multipart/form-data' }
      }
    ).then(this.onCreateEquipmentSuccess).catch(this.onCreateEquipmentError) as Promise<EquipmentModel>;
  }

  @action.bound
  protected onCreateEquipmentSuccess(data: EquipmentResponseDTO) {
    this.tableStore.fetchAll();
    return EquipmentModel.createFromDTO(data);
  }

  @action.bound
  protected onCreateEquipmentError(reason: AxiosResponse) {
    if (reason.status === 400) {
      const data = Object.entries(reason.data as Record<string, string>)
        .map(([ field, value ]) => [ field.replace('body.', ''), value ])
        .reduce((obj, [field, value]) => ({ ...obj, [field]: value }), {})
      ;

      throw FormError.createFromBadRequestResponseDTO(data);
    }

    throw new FormError({ });
  }
}
