import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { Button, Card, Col,  notification, Row, Form, Input } from 'antd';
import { action, observable} from 'mobx';
import EquipmentCreateModal from './component/FactoryCreateModal/index';
import EquipmentEditModal from './component/FactoryEditModal/index';
import { FormInstance } from 'antd/lib/form';
import FactoryAutoCompleteSelect from '../../components/AutoCompleteSelect/FactoryAutoCompleteSelect/index';
import { observer } from 'mobx-react';
import { lazyInject } from '../../utils/IoC';
import { FactoryTableStore } from '../../stores/factory/FactoryTableStore';
import { CategoryAccessStore } from '../../stores/users/CategoryAccessStore';
import { Redirect } from 'react-router';
import styles from './index.module.less';
import axios from 'axios';
import TextArea from 'antd/lib/input/TextArea';
import { ACCESS_TOKEN_STORAGE_KEY } from '../../stores/auth/const/AuthTokensStorageKeys';
export const MomentDateFormat = 'DD.MM.YYYY';

const api_url = process.env.REACT_APP_API_SERVER_HOST
@observer
class FactoryListStat extends Component {
  @lazyInject(FactoryTableStore)
  store!: FactoryTableStore;

  @lazyInject(CategoryAccessStore)
  categoryAccessStore!: CategoryAccessStore;

  @observable isModalVisible = { create: false, edit: false };
  @observable currentEditId = '';
  @observable option = [];


  @observable allCabel = 0;

  @observable countCode = 0;
  @observable printCode = 0;

  refForm = React.createRef<FormInstance>();

  componentDidMount() {
    this.store.fetchAll();
  }

  //region Handlers
  deleteRecord = (id: string) => {
    this.store.deleteItem(id).catch(() => notification.error({ message: 'Не удалось удалить оборудование' }));
  };

  @action
  openCreateModal = () => {
    this.isModalVisible.create = true;
  };

  @action
  closeCreateModal = () => {
    this.isModalVisible.create = false;
  };

  @action
  loadDataChart = () => {

    const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) || undefined
    const value = this.refForm.current?.getFieldsValue();
    axios
      .put(api_url+ '/v1/admin/finances', { factory_id: value.id.id, amount: parseInt(value.amount), description:value.comment }, {headers: { 'Authorization': 'Bearer ' + token } })
      .then(resp => (console.log(resp.data)));


  };

  @action
  closeEditModal = () => {
    this.isModalVisible.edit = false;
    this.currentEditId = '';
  };
  //endregion

  state = {
  };

  render() {
    if (!this.categoryAccessStore.loading && !this.categoryAccessStore.hasFactyoryAccess) {
      return <Redirect to='/' />;
    }

    return (
      <PageHeaderWrapper>
        <EquipmentCreateModal
          visible={this.isModalVisible.create}
          onClose={this.closeCreateModal}
        />

        {this.isModalVisible.edit && this.currentEditId !== '' &&
          <EquipmentEditModal
            visible={this.isModalVisible.edit}
            onClose={this.closeEditModal}
            id={this.currentEditId}
          />
        }

        <Card>
          <Row gutter={[16, 16]}>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form ref={this.refForm} layout="vertical">
                <Row gutter={[16, 16]}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                    xl={{ span: 24 }}
                  >
                    <Form.Item
                      name="id"
                      key="id">
                      <FactoryAutoCompleteSelect
                        className={styles.datepicker}
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                    xl={{ span: 24 }}
                  >
                    <Form.Item
                      name="amount"
                      key="amount">
                      <Input
                        type='Number'
                        placeholder='Сумма'
                        className={styles.datepicker}
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                    xl={{ span: 24 }}
                  >
                    <Form.Item
                      name="comment"
                      key="comment">
                      <TextArea
                        placeholder='Основание'
                        className={styles.datepicker}
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                    xl={{ span: 24 }}
                >
                  <Button
                    size="large"
                    block
                    type="primary"
                    onClick={this.loadDataChart}
                  >
                    Добавить
            </Button>
                </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card>
      </PageHeaderWrapper >
    );
  }
}

export default FactoryListStat;
