import moment, { Moment } from 'moment';
import { observable } from 'mobx';
import { PrinterControllerConfigResponseDTO,
	PrinterControllerConfigWiredResponseDTO,
	PrinterControllerConfigWirelessResponseDTO,
} from '../dto/response/printer-controller.response.dto';

export class PrinterControllerConfigWiredModel {
	@observable address: string;
	@observable dns?: string;
	@observable netmask?: string;
	@observable gateway?: string;

	constructor(data: PrinterControllerConfigWiredModel) {
		this.address = data.address;
		this.dns = data.dns;
		this.netmask = data.netmask;
		this.gateway = data.gateway;
	}

	static createFromDTO(dto: PrinterControllerConfigWiredResponseDTO) {
		return new PrinterControllerConfigWiredModel({
			address: dto.address,
			dns: dto.dns,
			netmask: dto.netmask,
			gateway: dto.gateway,
		});
	}
};

export class PrinterControllerConfigWirelessModel {
	@observable ssid: string;
	@observable psk: string;

	constructor(data: PrinterControllerConfigWirelessModel) {
		this.ssid = data.ssid;
		this.psk = data.psk;
	}

	static createFromDTO(dto: PrinterControllerConfigWirelessResponseDTO) {
		return new PrinterControllerConfigWirelessModel({
			ssid: dto.ssid,
			psk: dto.psk,
		});
	}
};

export class PrinterControllerConfigModel {
	@observable printer_model?: string;
	@observable printer_address?: string;
	@observable printer_port?: string;
	@observable wired?: PrinterControllerConfigWiredModel;
	@observable wireless: PrinterControllerConfigWirelessModel[];

	constructor(data: PrinterControllerConfigModel) {
		this.printer_model = data.printer_model;
		this.printer_address = data.printer_address;
		this.printer_port = data.printer_port;
		this.wired = data.wired;
		this.wireless = data.wireless;
	}

	static createFromDTO(dto: PrinterControllerConfigResponseDTO) {
		return new PrinterControllerConfigModel({
			printer_model: dto.printer_model,
			printer_address: dto.printer_address,
			printer_port: dto.printer_port,
			wired: dto.wired ? PrinterControllerConfigWiredModel.createFromDTO(dto.wired) : undefined,
			wireless: dto.wireless ? dto.wireless.map((f) => PrinterControllerConfigWirelessModel.createFromDTO(f)) : [],
		});
	}
}
