export enum StatusProduct {
  new = "new",
  released = "released",
  checking = "checking",
  deleted = "deleted",
}

export const StatusProductName: Record<StatusProduct, string> = {
  [StatusProduct.new]: 'Ожидает ОТК',
  [StatusProduct.released]: 'Выпущено',
  [StatusProduct.checking]: 'Проверяется',
  [StatusProduct.deleted]: 'Удалено',
};

export function getStatusProductName(status?: StatusProduct) {
  return status ? StatusProductName[status] : status;
}
