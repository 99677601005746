import { SorterResult } from 'antd/lib/table/interface';

export class SortRuleModel<RecordType> {
  field: string;
  order?: 'ASC' | 'DESC';

  constructor(data: Omit<SortRuleModel<RecordType>, 'toAntdSorter'>) {
    this.field = data.field;
    this.order = data.order;
  }

  toAntdSorter(): SorterResult<RecordType> {
    const { field } = this;

    if (this.order === 'ASC') {
      return { field, order: 'ascend' };
    }

    if (this.order === 'DESC') {
      return { field, order: 'descend' };
    }

    return { field, order: undefined };
  }

  static createFromAntdSorter<RecordType>(sorter: SorterResult<RecordType>): SortRuleModel<RecordType> {
    const field = sorter.field as string;

    let order: 'ASC' | 'DESC' | undefined;
    if (sorter.order === 'ascend') {
      order = 'ASC';
    } else if (sorter.order === 'descend') {
      order = 'DESC';
    } else {
      order = undefined;
    }

    return new SortRuleModel({ field, order });
  }
}
