import { action, observable } from 'mobx';
import { lazyInject, provide } from '../../../../utils/IoC';
import { HttpClientV1 } from '../../../../utils/api/HttpClientV1';
import { AxiosResponse } from 'axios';
import { FormError } from '../../../../utils/error/FormError';
import { ResetPasswordTokenInfoResponseDTO } from '../dto/reset-password/reset-password-token-info.response.dto';
import { ResetPasswordRequestDTO } from '../dto/reset-password/reset-password.request.dto';
import { JwtTokensResponseDTO } from '../../../../stores/auth/dto/jwt-tokens.response.dto';
import { AuthStore } from '../../../../stores/auth/AuthStore';

@provide.transient()
export class FinishResetPasswordStore {
  @lazyInject(HttpClientV1)
  protected readonly httpClientV1!: HttpClientV1;

  @lazyInject(AuthStore)
  protected readonly authStore!: AuthStore;

  @observable isStatusLoaded = false;
  @observable isTokenValid = false;

  @observable loading: boolean = false;

  @action
  fetchResetTokenStatus(token: string) {
    this.isStatusLoaded = false;

    return this.httpClientV1.get<ResetPasswordTokenInfoResponseDTO>(
      '/auth/reset-password',
      { params: { token } },
    ).then(this.onFetchResetTokenStatusSuccess).catch(this.onFetchResetTokenStatusError);
  }

  @action.bound
  protected onFetchResetTokenStatusSuccess() {
    this.isStatusLoaded = true;
    this.isTokenValid = true;
  }

  @action.bound
  protected onFetchResetTokenStatusError() {
    this.isStatusLoaded = true;
    this.isTokenValid = false;
  }

  @action
  resetPasswordAttempt(token: string, password: string) {
    this.loading = true;

    return this.httpClientV1.put<ResetPasswordRequestDTO, JwtTokensResponseDTO>(
      '/auth/reset-password',
      { password },
      { params: { token } },
    ).then(this.onResetPasswordAttemptSuccess).catch(this.onResetPasswordAttemptError);
  }

  @action.bound
  protected onResetPasswordAttemptSuccess(data: JwtTokensResponseDTO) {
    this.authStore.onLoginSuccess(data);
    this.loading = false;
  }

  @action.bound
  protected onResetPasswordAttemptError(reason: AxiosResponse) {
    this.loading = false;

    if (reason.status === 400) {
      const error = FormError.createFromBadRequestResponseDTO(reason.data);
      error.errors.repeatPassword = error.errors.password;
      throw error;
    }

    if (reason.status === 404) {
      this.isTokenValid = false;

      const description = 'Ссылка недействительна';
      throw new FormError({
        password: description,
        repeatPassword: description,
      });
    }
  }
}
