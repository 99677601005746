import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { lazyInject } from '../utils/IoC';
import { AuthStore } from '../stores/auth/AuthStore';

@observer
export class SecureLayout extends Component {
  @lazyInject(AuthStore)
  authStore!: AuthStore;

  render() {
    if (!this.authStore.isRefreshTokenValid) {
      return <Redirect to='/auth/login' />;
    }

    if (!this.authStore.isAccessTokenValid) {
      return null;
    }

    return <>{this.props.children}</>;
  }
}
