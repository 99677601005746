import { lazyInject, provide } from '../../utils/IoC';
import { computed } from 'mobx';
import { CurrentUserStore } from './CurrentUserStore';
import { UserRole } from './const/user-role.enum';

@provide.singleton()
export class CategoryAccessStore {
  @lazyInject(CurrentUserStore)
  currentUserStore!: CurrentUserStore

  @computed
  get loading(): boolean {
    return this.currentUserStore.loading;
  }

  @computed
  get isIntegrityAdmin(): boolean {
    return !!(this.currentUserStore.user && this.currentUserStore.user.role === UserRole.IntegrityAdmin);
  }

  @computed
  get hasUsersAccess(): boolean {
    return !!(this.currentUserStore.user && this.currentUserStore.user.permissions.hasUsersAccess);
  }

  @computed
  get hasFinancesAccess(): boolean {
    return !!(this.currentUserStore.user && this.currentUserStore.user.permissions.hasFinancesAccess);
  }

  @computed
  get hasEquipmentAccess(): boolean {
    return !!(this.currentUserStore.user && this.currentUserStore.user.permissions.hasEquipmentAccess);
  }

  @computed
  get hasFactyoryAccess(): boolean {
    return !!(this.currentUserStore.user && this.currentUserStore.user.permissions.hasFactoryAccess);
  }
}
