import { UserPermissionsResponseDTO } from '../dto/response/user-permissions.response.dto';
import { observable } from 'mobx';

export class UserPermissionModel {
  @observable hasProductsAccess!: boolean;
  @observable hasAnalyticsAccess!: boolean;
  @observable hasAdsAccess!: boolean;
  @observable hasUsersAccess!: boolean;
  @observable hasFinancesAccess!: boolean;
  @observable hasActOfReconciliationAccess!: boolean;
  @observable hasEquipmentAccess!: boolean;
  @observable hasFactoryAccess!: boolean;

  constructor(model: UserPermissionModel) {
    this.hasProductsAccess = model.hasProductsAccess;
    this.hasAnalyticsAccess = model.hasAnalyticsAccess;
    this.hasAdsAccess = model.hasAdsAccess;
    this.hasUsersAccess = model.hasUsersAccess;
    this.hasFinancesAccess = model.hasFinancesAccess;
    this.hasActOfReconciliationAccess = model.hasActOfReconciliationAccess;
    this.hasEquipmentAccess = model.hasEquipmentAccess;
    this.hasFactoryAccess = model.hasFactoryAccess;
  }

  static createFromDTO(dto: UserPermissionsResponseDTO) {
    return new UserPermissionModel({
      hasProductsAccess: dto.hasProductsAccess,
      hasAnalyticsAccess: dto.hasAnalyticsAccess,
      hasAdsAccess: dto.hasAdsAccess,
      hasUsersAccess: dto.hasUsersAccess,
      hasFinancesAccess: dto.hasFinancesAccess,
      hasActOfReconciliationAccess: dto.hasActOfReconciliationAccess,
      hasEquipmentAccess: dto.hasEquipmentAccess,

      hasFactoryAccess: dto.hasFactoryAccess,
    });
  }
}
