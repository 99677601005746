import React, { Component, RefObject } from 'react';
import { Checkbox, Col, Form, Input, notification, Row, Space, Switch } from 'antd';
import UserRoleSelect from '../../../../components/UserRoleSelect/index';
import UserPermissions from '../UserPermissions/index';
import { observer } from 'mobx-react';
import { FormInstance } from 'antd/lib/form';
import { UserModel } from '../../../../stores/users/model/UserModel';
import { FormError } from '../../../../utils/error/FormError';
import FactoryAutoCompleteSelect from '../../../../components/AutoCompleteSelect/FactoryAutoCompleteSelect/index';
import { action, observable } from 'mobx';
import { UserRole } from '../../../../stores/users/const/user-role.enum';
import { UserFactoryModel } from '../../../../stores/users/model/UserFactoryModel';
import { UserPermissionModel } from '../../../../stores/users/model/UserPermissionModel';
import { UserStatus } from '../../../../stores/users/const/user-status.enum';
import { lazyInject } from '../../../../utils/IoC';
import { HttpClientV1 } from '../../../../utils/api/HttpClientV1';

export interface UserModalFormValue {
  fullName: string;
  email: string;
  phoneNumber: string;
  pin?: string;
  role: UserRole;
  factory: UserFactoryModel;
  permissions: UserPermissionModel;
  blocked?: boolean;
  resetPassword?: boolean;
  password?: string;
}

interface Props {
  formRef: RefObject<FormInstance>;
  initialValues?: UserModel;
  error?: FormError;
  onResetError?: (key: string) => void;
  showBlockSwitch?: boolean;
  showResetPassword?: boolean;
}

@observer
class UserModalForm extends Component<Props> {
  @lazyInject(HttpClientV1)
  httpClientV1!: HttpClientV1;

  @observable role?: UserRole = this.props.initialValues?.role;
  @observable factory?: UserFactoryModel = this.props.initialValues?.factory;

  resetError = (key: string) => {
    this.props.onResetError && this.props.onResetError(key);
  };

  @action
  handleRoleChange = (role: UserRole) => {
    this.role = role;
  };

  @action
  handleFactoryChange = (factory?: UserFactoryModel) => {
    this.factory = factory;
  };

  @action
  generateFactoryPin = () => {
    if (!this.factory) {
      return;
    }

    this.httpClientV1.post(`/users/pin?factoryId=${this.factory.id}`)
      .then(({ pin }: { pin: string }) => {
        this.props.formRef.current?.setFields([{ name: 'pin', value: pin }])
      })
      .catch(() => notification.error({ message: 'Ошибка при генерации PIN кода' }));
  };

  render() {
    const { formRef, initialValues, error } = this.props;

    const hasFullNameError = !!(error?.errors?.fullName);
    const hasEmailError = !!(error?.errors?.email);
    const hasPhoneNumberError = !!(error?.errors?.phoneNumber);
    const hasPasswordError = !!(error?.errors?.password);
    const hasStatusError = !!(error?.errors?.status);
    const hasPinError = !!(error?.errors?.pin);
    const hasRoleError = !!(error?.errors?.role);
    const hasFactoryError = !!(error?.errors?.factoryId);
    const hasPermissionsError = !!(error?.errors?.permissions);

    return (
      <Form
        ref={formRef}
        layout="vertical"
        initialValues={{
          ...initialValues,
          blocked: (initialValues?.status === UserStatus.Blocked)
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
            <Form.Item
              label="ФИО"
              name="fullName"
              key="fullName"

              hasFeedback={hasFullNameError}
              validateStatus={hasFullNameError ? 'error' : undefined}
              help={error?.errors?.fullName}
            >
              <Input
                placeholder="Введите ФИО"
                onChange={() => this.resetError('fullName')}
              />
            </Form.Item>
            <Form.Item
              label="E-mail"
              name="email"
              key="email"

              hasFeedback={hasEmailError}
              validateStatus={hasEmailError ? 'error' : undefined}
              help={error?.errors?.email}
            >
              <Input
                placeholder="Введите e-mail"
                onChange={() => this.resetError('email')}
              />
            </Form.Item>
            <Form.Item
              label="Телефон"
              name="phoneNumber"
              key="phoneNumber"

              hasFeedback={hasPhoneNumberError}
              validateStatus={hasPhoneNumberError ? 'error' : undefined}
              help={error?.errors?.phoneNumber}
            >
              <Input
                placeholder="+7XXXXXXXXXX"
                onChange={() => this.resetError('phoneNumber')}
              />
            </Form.Item>

            {this.role && this.role !== UserRole.OTK &&
				<Form.Item
					name="password"
					label="Пароль"
					key="password"
					hasFeedback={hasPasswordError}
					validateStatus={hasPasswordError ? 'error' : undefined}
					help={error?.errors?.password}
				>
					<Input
						placeholder="Введите новый пароль"
						onChange={() => this.resetError("password")}
					/>
				</Form.Item>
			}

            {this.props.showBlockSwitch &&
              <Form.Item
                name="resetPassword"
                key="resetPassword"

                hasFeedback={hasPasswordError}
                validateStatus={hasPasswordError ? 'error' : undefined}
                help={error?.errors?.password}

                valuePropName='checked'
              >
                <Checkbox
                  onChange={() => this.resetError('password')}
                >
                  Сгенерировать новый пароль
                </Checkbox>
              </Form.Item>
            }

            {this.props.showBlockSwitch &&
              <Form.Item
                label="Блокировка пользователя"
                name="blocked"
                key="blocked"

                hasFeedback={hasStatusError}
                validateStatus={hasStatusError ? 'error' : undefined}
                help={error?.errors?.status}

                valuePropName='checked'
              >
                <Switch
                  onChange={() => this.resetError('status')}
                />
              </Form.Item>
            }
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
            <Form.Item
              label="Выберите роль"
              key="role"
              name="role"

              hasFeedback={hasRoleError}
              validateStatus={hasRoleError ? 'error' : undefined}
              help={error?.errors?.role}
            >
              <UserRoleSelect
                placeholder='Выберите роль'
                onChange={(role: UserRole) => {
                  this.handleRoleChange(role);
                  this.resetError('role');
                }}
              />
            </Form.Item>

            {[UserRole.OTK, UserRole.FactoryAdmin].includes(this.role!) &&
              <Form.Item
                label="Выберите завод"
                key="factory"
                name="factory"

                hasFeedback={hasFactoryError}
                validateStatus={hasFactoryError ? 'error' : undefined}
                help={error?.errors?.factoryId}
              >
                <FactoryAutoCompleteSelect
                  allowClear={false}
                  onChange={(factory: UserFactoryModel | undefined) => {
                    this.handleFactoryChange(factory);
                    this.resetError('factoryId');
                  }}
                />
              </Form.Item>
            }

            {this.role && this.role === UserRole.OTK && this.factory &&
              <Space direction='horizontal'>
                <Form.Item
                  label="Пин-код"
                  name="pin"
                  key="pin"

                  hasFeedback={hasPinError}
                  validateStatus={hasPinError ? 'error' : undefined}
                  help={error?.errors?.pin}
                >
                  <Input
                    placeholder="XXXX"
                    onChange={() => this.resetError('pin')}
                  />
                </Form.Item>

                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a onClick={this.generateFactoryPin}>Сгенерировать</a>
              </Space>
            }

            {this.role && this.role !== UserRole.OTK &&
              <Form.Item
                key="permissions"
                name="permissions"
                label="Доступ к разделам"

                hasFeedback={hasPermissionsError}
                validateStatus={hasPermissionsError ? 'error' : undefined}
                help={error?.errors?.permissions}
              >
                <UserPermissions
                  role={this.role}
                  onChange={() => this.resetError('permissions')}
                />
              </Form.Item>
            }
          </Col>
        </Row>
      </Form>
    );
  }
}

export default UserModalForm;
