import { RcFile } from 'antd/lib/upload';
import { time } from 'console';
import { observable } from 'mobx';
import { RibbonImportResponseDTO } from '../dto/response/ribbon_import.response.dto';

export class RibbonImportModel {
  @observable id?: string;
  @observable user?: string;
  @observable user_id?: string;
  @observable insert_date?: string;
  @observable insert_time?: string;
  @observable status?: string;
  @observable num_ribbons?: number;
  @observable num_codes?: number;
  @observable ribbon_ids?: Array<string>;
  @observable errors?: string;
  @observable comment?: string;
  @observable file?: RcFile;

  constructor(data: RibbonImportModel) {
    this.id = data.id;
    this.user = data.user;
    this.user_id = data.user_id;
    this.insert_date = data.insert_date;
    this.insert_time = data.insert_time;
    this.status = data.status;
    this.num_ribbons = data.num_ribbons;
    this.num_codes = data.num_codes;
    this.ribbon_ids = data.ribbon_ids;
    this.errors = data.errors;
    this.comment = data.comment;
    this.file = data.file;
  }

  static createFromDTO(dto: RibbonImportResponseDTO) {
    let date = "", time = "";
    if (dto.insert_time) {
      const date_ob = new Date(dto.insert_time);
      let day = ("0" + date_ob.getDate()).slice(-2);
      let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
      let year = date_ob.getFullYear();
      let hours = date_ob.getHours();
      let minutes = date_ob.getMinutes();
      let seconds = date_ob.getSeconds();
      date = `${year}-${month}-${day}`;
      time = `${hours}:${minutes}:${seconds}`;
    }

    return new RibbonImportModel({
      id: dto.id,
      user: dto.user,
      user_id: dto.user_id,
      insert_date: date,
      insert_time: time,
      status: dto.status,
      num_ribbons: dto.num_ribbons,
      num_codes: dto.num_codes,
      ribbon_ids: dto.ribbon_ids,
      errors: dto.errors,
      comment: dto.comment,
    })
  }
}
