import React from 'react';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Menu, Spin } from 'antd';
import { observer } from 'mobx-react';
import { history } from '../../utils/router';
import HeaderDropdown from '../HeaderDropdown/index';
import { lazyInject } from '../../utils/IoC';
import { AuthStore } from '../../stores/auth/AuthStore';

import styles from './index.module.less';
import { CurrentUserStore } from '../../stores/users/CurrentUserStore';

@observer
class AvatarDropdown extends React.Component {
  @lazyInject(AuthStore)
  authStore!: AuthStore;

  @lazyInject(CurrentUserStore)
  currentUserStore!: CurrentUserStore;

  onMenuClick = (event: {
    key: React.Key;
    keyPath: React.Key[];
    item: React.ReactInstance;
    domEvent: React.MouseEvent<HTMLElement>;
  }) => {
    const { key } = event;

    if (key === 'logout') {
      this.authStore.logout();
      return;
    }

    history.push(`/account/${key}`);
  };

  render(): React.ReactNode {
    const menuHeaderDropdown = (
      <Menu className={styles.menu} selectedKeys={[]} onClick={this.onMenuClick}>
        {/*<Menu.Item key="settings">*/}
        {/*  <SettingOutlined />*/}
        {/*  Настройки*/}
        {/*</Menu.Item>*/}

        {/*<Menu.Divider />*/}

        <Menu.Item key="logout">
          <LogoutOutlined />
          Выход
        </Menu.Item>
      </Menu>
    );

    return !this.currentUserStore.loading && this.currentUserStore.user ? (
      <HeaderDropdown overlay={menuHeaderDropdown}>
        <span className={`${styles.action} ${styles.account}`}>
          <Avatar size="small" className={styles.avatar} icon={<UserOutlined />} alt="avatar" />
          <span className={`${styles.name} anticon`}>{this.currentUserStore.user.fullName}</span>
        </span>
      </HeaderDropdown>
    ) : (
      <span className={`${styles.action} ${styles.account}`}>
        <Spin
          size="small"
          style={{
            marginLeft: 8,
            marginRight: 8,
          }}
        />
      </span>
    );
  }
}

export default AvatarDropdown;
