import { lazyInject, provide } from '../../utils/IoC';
import { CablesModel } from './model/CablesModel';
import { HttpClientV1 } from '../../utils/api/HttpClientV1';
import { CablesResponseDTO } from './dto/response/cables.response.dto';
import { BaseTableStore } from '../../utils/store/BaseTableStore';
import { Moment } from 'moment';
import { FactoryAutocompleteResponseDTO } from '../../components/AutoCompleteSelect/FactoryAutoCompleteSelect/dto/FactoryAutocompleteResponseDTO';

@provide.singleton()
export class CablesTableStore extends BaseTableStore<CablesModel, CablesResponseDTO> {
  @lazyInject(HttpClientV1)
  protected readonly httpClientV1!: HttpClientV1;

  protected readonly path = '/cables';
  protected readonly httpClient = this.httpClientV1;

  protected readonly download_xlsx_path = '/cables/xlsx';

  protected get searchDTO(): object {
    return {
      id: this.filters.id ? this.filters.id[0] : undefined,
      name: this.filters.otk ? this.filters.otk[0] : undefined,
      status: this.filters.status ? this.filters.status[0] : undefined,
      length: this.filters.length ? this.filters.length[0] : undefined,
      number_tumb: this.filters.drum_number ? this.filters.drum_number[0] : undefined,
      startDate: this.filters.date_created
      ? {
        from: this.filters.date_created[0]
          ? (this.filters.date_created[0] as Moment)
            .toDate()
            .getTime()
          : undefined,
        to: this.filters.date_created[1]
          ? (this.filters.date_created[1] as Moment)
            .toDate()
            .getTime()
          : undefined,
      }
      : undefined,
      releasedDate: this.filters.date_released
      ? {
        from: this.filters.date_released[0]
          ? (this.filters.date_released[0] as Moment)
            .toDate()
            .getTime()
          : undefined,
        to: this.filters.date_released[1]
          ? (this.filters.date_released[1] as Moment)
            .toDate()
            .getTime()
          : undefined,
      } : undefined,
    };
  }

  protected get filterDTO(): object {
	  const factoryIds: string[] | undefined = this.filters.factory_name?.map((i) => { return (i as FactoryAutocompleteResponseDTO).id });
    return {
      name: this.filters.otk
        ? (this.filters.otk as Array<{ id: string }>).map((v) => v.id)
        : undefined,

        type: this.filters.type ? this.filters.type[0] : undefined,

        codes: this.filters.codes ? this.filters.codes[0] : undefined,
		factoryId: factoryIds,
    };
  }

  protected createModelFromResponseDTO(dto: CablesResponseDTO): CablesModel {
    return CablesModel.createFromDTO(dto);
  }
}
