import React, { Component } from 'react';
import { Button, Modal, notification } from 'antd';
import { observer } from 'mobx-react';
import PricesModalForm, { PriceModalFormValue } from '../PricesModalForm/index';
import { action, observable } from 'mobx';
import { FormError } from '../../../../../utils/error/FormError';
import { FormInstance } from 'antd/lib/form';
import { PriceModel } from '../../../../../stores/finances/prices/model/PriceModel';
import { lazyInject } from '../../../../../utils/IoC';
import { PriceCreateModalStore } from '../../../../../stores/finances/prices/PriceCreateModalStore';

interface Props {
  visible: boolean;
  onClose: () => void;
}

@observer
class PricesCreateModal extends Component<Props> {
  @lazyInject(PriceCreateModalStore)
  store!: PriceCreateModalStore;

  formRef = React.createRef<FormInstance>();

  @observable loading: boolean = false;
  @observable error?: FormError;

  @action
  resetError = (key: string) => {
    delete this.error?.errors[key];
  }

  handleCancel = () => {
    this.props.onClose();
  };

  @action
  handleCreate = () => {
    const value = this.formRef.current?.getFieldsValue() as PriceModalFormValue | undefined;
    if (!value) {
      return;
    }

    const { name, datamatrix, barcode, startDate, endDate, barcode_pricing } = value;
    const model = new PriceModel({
      name,
      datamatrix: datamatrix?.toString(),
      barcode: barcode?.toString(),
      startDate,
      endDate,
	  barcode_pricing,
    });

    this.loading = true;
    this.store.createPrice(model).then(this.handleCreateSuccess).catch(this.handleCreateError)
  };

  @action.bound
  handleCreateSuccess(model: PriceModel) {
    this.loading = false;
    this.formRef.current?.resetFields();

    notification.success({ message: `Тариф успешно создан (id: ${model.id})` });

    this.props.onClose();
  }

  @action.bound
  handleCreateError(error: FormError | Error) {
    if (error instanceof FormError) {
      this.error = error;
    } else {
      notification.error({ message: error.message });
    }

    this.loading = false;
  }

  render() {
    const { visible } = this.props;

    return (
      <Modal
        title='Добавление тарифа'
        visible={visible}
        centered
        onCancel={this.handleCancel}
        footer={[
          <Button
            key="back"
            onClick={this.handleCancel}
          >
            Отмена
          </Button>,

          <Button
            key="submit"
            type="primary"
            disabled={this.loading}
            loading={this.loading}
            onClick={this.handleCreate}
          >
            Создать
          </Button>,
        ]}
      >
        <PricesModalForm
          formRef={this.formRef}
          error={this.error}
          onResetError={this.resetError}
          showDates
        />
      </Modal>
    );
  }
}

export default PricesCreateModal;
