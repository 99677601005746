import { lazyInject, provide } from '../../utils/IoC';
import { HttpClientV1 } from '../../utils/api/HttpClientV1';
import { action, computed } from 'mobx';
import { EquipmentModel } from './model/EquipmentModel';
import { RcFile } from 'antd/lib/upload';
import { EquipmentResponseDTO } from './dto/response/equipment.response.dto';
import { AxiosResponse } from 'axios';
import { FormError } from '../../utils/error/FormError';
import { EquipmentTableStore } from './EquipmentTableStore';
import { UpdateEquipmentRequestDTO } from './dto/request/update-equipment.request.dto';
import { EquipmentFileMetadataResponseDTO } from './dto/response/equipment-file-metadata.response.dto';
import { EquipmentFileModel } from './model/EquipmentFileModel';
import { RenameEquipmentFileRequestDTO } from './dto/request/rename-equipment-file.request.dto';

@provide.singleton()
export class EquipmentEditModalStore {
  @lazyInject(HttpClientV1)
  protected readonly httpClientV1!: HttpClientV1;

  @lazyInject(EquipmentTableStore)
  tableStore!: EquipmentTableStore;

  @computed
  get itemsMap() {
    return this.tableStore.itemsMap;
  }

  @action
  updateEquipment(id: number, model: EquipmentModel) {
    return this.httpClientV1.put<UpdateEquipmentRequestDTO, EquipmentResponseDTO>(
      `/equipment/${id}`,
      {
        serialNumber: model.serialNumber,
        factoryId: model.factory?.id,
        name: model.name,
        deliveryDate: (model.deliveryDate?.toDate().getTime() || null) as number,
        leaseEndDate: (model.leaseEndDate?.toDate().getTime() || null) as number,
        comment: model.comment || '',
        deviceUuid: (model.deviceUuid && model.deviceUuid.length > 0) ? model.deviceUuid : undefined,
      },
    ).then(this.onUpdateEquipmentSuccess).catch(this.onUpdateEquipmentError) as Promise<EquipmentModel>;
  }

  @action.bound
  protected onUpdateEquipmentSuccess(data: EquipmentResponseDTO) {
    const model = EquipmentModel.createFromDTO(data);

    if (this.itemsMap.has(data.id)) {
      this.itemsMap.set(model.id as number, model);
    }

    return model;
  }

  @action.bound
  protected onUpdateEquipmentError(reason: AxiosResponse) {
    if (reason.status === 400) {
      const data = Object.entries(reason.data as Record<string, string>)
        .map(([ field, value ]) => [ field.replace('body.', ''), value ])
        .reduce((obj, [field, value]) => ({ ...obj, [field]: value }), {})
      ;

      throw FormError.createFromBadRequestResponseDTO(data);
    } else {
      throw reason;
    }
  }

  @action
  uploadEquipmentFiles(id: number, files: { name: string, content: RcFile }[]) {
    const formData = new FormData();
    files.forEach(f => formData.append('files', f.content, f.name))

    return this.httpClientV1.post<{}, EquipmentFileMetadataResponseDTO[]>(`/equipment/${id}/files`,
      {},
      {
        data: formData,
        headers: {'Content-Type': 'multipart/form-data' }
      }
    ).then((data) => this.onUploadEquipmentFilesSuccess(id, data));
  }

  @action.bound
  protected onUploadEquipmentFilesSuccess(id: number, data: EquipmentFileMetadataResponseDTO[]) {
    const model = this.itemsMap.get(id);
    if (!model) {
      return;
    }

    model.files?.push(...data.map((dto) => EquipmentFileModel.createFromDTO(dto)));
  }

  @action
  deleteEquipmentFile(id: number, fileId: string) {
    return this.httpClientV1.delete(`/equipment/${id}/files/${fileId}`)
      .then(() => this.onDeleteEquipmentFileSuccess(id, fileId))
      ;
  }

  @action.bound
  protected onDeleteEquipmentFileSuccess(id: number, fileId: string) {
    const model = this.itemsMap.get(id);
    if (!model) {
      return;
    }

    model.files = model.files!.filter(f => f.id !== fileId);
  }

  @action
  renameEquipmentFile(id: number, fileId: string, fileName: string) {
    return this.httpClientV1.put<RenameEquipmentFileRequestDTO>(`/equipment/${id}/files/${fileId}`,
      {
        fileName,
      }
    ).then(() => this.onRenameEquipmentFileSuccess(id, fileId, fileName))
  }

  @action.bound
  protected onRenameEquipmentFileSuccess(id: number, fileId: string, fileName: string) {
    const model = this.itemsMap.get(id);
    if (!model) {
      return;
    }

    const file = model.files?.find(f => f.id === fileId);
    if (!file) {
      return;
    }

    file.fileName = fileName;
  }
}
