import { lazyInject, provide } from '../../utils/IoC';
import { FactoryModel } from './model/FactoryModel';
import { HttpClientV1 } from '../../utils/api/HttpClientV1';
import { FactoryResponseDTO } from './dto/response/factory.response.dto';
import { BaseTableStore } from '../../utils/store/BaseTableStore';

@provide.singleton()
export class FactoryTableStore extends BaseTableStore<FactoryModel, FactoryResponseDTO> {
  @lazyInject(HttpClientV1)
  protected readonly httpClientV1!: HttpClientV1;

  protected readonly path = '/factories';
  protected readonly httpClient = this.httpClientV1;

  protected readonly download_xlsx_path = '/factories/xlsx';

  protected get searchDTO(): object {
    return {
      id: this.filters.id ? this.filters.id[0] : undefined,
      name: this.filters.name ? this.filters.name[0] : undefined,
      fullName: this.filters.fullName ? this.filters.fullName[0] : undefined,
      country: this.filters.country ? this.filters.country[0] : undefined,
      city: this.filters.city ? this.filters.city[0] : undefined,
      address: this.filters.address ? this.filters.address[0] : undefined,
      otk: this.filters.otk ? this.filters.otk[0] : undefined,
      
    };
  }

  protected get filterDTO(): object {
    return {
      name: this.filters.name
        ? (this.filters.name as Array<{ id: string }>).map((v) => v.id)
        : undefined,

      fullName: this.filters.fullName
      ? (this.filters.fullName as Array<{ id: string }>).map((v) => v.id)
      : undefined,
    };
  }

  protected createModelFromResponseDTO(dto: FactoryResponseDTO): FactoryModel {
    return FactoryModel.createFromDTO(dto);
  }
}
