import React, { Component } from "react";
import { Button } from "antd";
import { SearchOutlined } from "@ant-design/icons/lib";
import { StatusProduct } from "../../stores/factory/const/status-product.enum";

interface Props {
  handleSearch(): void;
  handleReset?(): void;
}

class TableButtonFiltersDropdown extends Component<Props> {

  
  render() {
    const { handleSearch, handleReset } = this.props;

    return (
      <div style={{ display: "flex" }}>
        <Button
          type="primary"
          onClick={() => handleSearch()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: "50%", marginRight: 8 }}
        >
          Поиск
        </Button>
        <Button
          onClick={() => handleReset && handleReset()}
          size="small"
          style={{ width: "50%" }}
        >
          Сбросить
        </Button>
      </div>
    );
  }
}

export default TableButtonFiltersDropdown;
