import React, { Component, useState } from 'react';
import TableButtonFiltersDropdown from './TableButtonFiltersDropdown';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { getStatusProductName, StatusProduct } from '../../stores/factory/const/status-product.enum';
import StatusSelect from '../StatusSelect';
import TableButtonStatusFiltersDropdown from './TableButtonStatusFiltersDropdown';

interface Props extends FilterDropdownProps {
  placeholder?: string;
}

export const getTableStatusProductDropdownProps = () => ({
  filterDropdown: (props: FilterDropdownProps) => (
    <TableStatusProductFilterDropdown {...props} />
  ),
});

class TableStatusProductFilterDropdown extends Component<Props> {
  handleChange = (status: StatusProduct) => {

    this.props.setSelectedKeys(
      !status ? [] : [(status as unknown) as string]

    );
  };




  render() {
    const { clearFilters, confirm } = this.props;


    return (
      <div >
      <div style={{ display: "flex", padding: '8px' }}>
        <StatusSelect
          mode="multiple"
          placeholder='Выберите статус'
          style={{ minWidth: '300px', width: "100%", marginBottom: "8px", marginRight: '8px' }}
          onChange={this.handleChange}
          allowClear
        />

        <TableButtonStatusFiltersDropdown

          handleSearch={confirm}
        />
      </div>
      </div>
    );
  }
}

export default TableStatusProductFilterDropdown;
