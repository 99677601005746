import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { lazyInject } from '../utils/IoC';
import { CategoryAccessStore } from '../stores/users/CategoryAccessStore';
import { Redirect } from 'react-router';
import ForbiddenPage from './exception/403';

@observer
class IndexPage extends Component {
  @lazyInject(CategoryAccessStore)
  categoryAccessStore!: CategoryAccessStore;

  render() {
    if (this.categoryAccessStore.loading) {
      return null;
    }

    if (this.categoryAccessStore.hasUsersAccess) {
      return <Redirect to='/users' />;
    }

    if (this.categoryAccessStore.hasFinancesAccess) {
      return <Redirect to='/finances/prices' />;
    }

    if (this.categoryAccessStore.hasEquipmentAccess) {
      return <Redirect to='/equipment' />;
    }

    return <ForbiddenPage />;
  }
}

export default IndexPage;
