import React, { Component } from 'react';
import { InboxOutlined } from '@ant-design/icons';

class EquipmentUploadContent extends Component {
  render() {
    return (
      <>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Кликните или перетащите файлы сюда</p>
        <p className="ant-upload-hint">
          Вы можете выбрать или перетащить сразу несколько файлов
        </p>
      </>
    );
  }
}

export default EquipmentUploadContent;
