import { lazyInject, provide } from '../../utils/IoC';
import { HttpClientV1TwoApi } from '../../utils/api/HttpClientV1twoApi';
import { action } from 'mobx';
import { RibbonImportModel } from './model/RibbonImportModel';
import { RcFile } from 'antd/lib/upload';
import { AddFactoryRequestDTO } from './dto/request/add-factory.request.dto';
import { FactoryResponseDTO } from './dto/response/factory.response.dto';
import { AxiosResponse } from 'axios';
import { FormError } from '../../utils/error/FormError';
import { RibbonImportTableStore } from './RibbonImportTableStore';

@provide.singleton()
export class RibbonImportCreateModalStore {
  @lazyInject(HttpClientV1TwoApi)
  protected readonly httpClientV1!: HttpClientV1TwoApi;

  @lazyInject(RibbonImportTableStore)
  tableStore!: RibbonImportTableStore;

  @action
  createRibbonImport(model: RibbonImportModel) {
    const formData = new FormData();
    if(model.file) {
      formData.append("file", model.file);
    }

    return this.httpClientV1.put<{}, FactoryResponseDTO>(
      '/ribbon/import',
      formData,
      {
        headers: {'Content-Type': 'multipart/form-data' }
      }
    ).then(this.onCreateRibbonImportSuccess).catch(this.onCreateRibbonImportError) as Promise<RibbonImportModel>;
  }

  @action.bound
  protected onCreateRibbonImportSuccess(data: FactoryResponseDTO) {
    this.tableStore.fetchAll();
    return RibbonImportModel.createFromDTO(data);
  }

  @action.bound
  protected onCreateRibbonImportError(reason: AxiosResponse) {
    if (reason.status === 400) {
      const data = Object.entries(reason.data as Record<string, string>)
        .map(([ field, value ]) => [ field.replace('body.', ''), value ])
        .reduce((obj, [field, value]) => ({ ...obj, [field]: value }), {})
      ;

      throw FormError.createFromBadRequestResponseDTO(data);
    }

    throw new FormError({ });
  }
}
