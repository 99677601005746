import { lazyInject, provide } from '../../utils/IoC';
import { HttpClientV1 } from '../../utils/api/HttpClientV1';
import { UsersTableStore } from './UsersTableStore';
import { UserModel } from './model/UserModel';
import { UserResponseDTO } from './dto/response/user.response.dto';
import { AxiosResponse } from 'axios';
import { action } from 'mobx';
import { UserRegisterRequestDTO } from './dto/request/user-register.request.dto';
import { FormError } from '../../utils/error/FormError';

@provide.singleton()
export class UserCreateModalStore {
  @lazyInject(HttpClientV1)
  protected readonly httpClientV1!: HttpClientV1;

  @lazyInject(UsersTableStore)
  tableStore!: UsersTableStore;

  @action
  createUser(model: UserModel) {
    return this.httpClientV1.post<UserRegisterRequestDTO, UserResponseDTO>(
      '/users',
      {
        fullName: model.fullName!,
        phoneNumber: model.phoneNumber!,
        email: model.email!,
        role: model.role!,
        pin: model.pin,
        factoryId: model.factory?.id,
        permissions: {
          hasProductsAccess: model.permissions?.hasProductsAccess,
          hasAnalyticsAccess: model.permissions?.hasAnalyticsAccess,
          hasAdsAccess: model.permissions?.hasAdsAccess,
          hasUsersAccess: model.permissions?.hasUsersAccess,
          hasFinancesAccess: model.permissions?.hasFinancesAccess,
          hasActOfReconciliationAccess: model.permissions?.hasActOfReconciliationAccess,
        },
		password: model.password,
      }
    ).then(this.onCreateUserSuccess).catch(this.onCreateUserError) as Promise<UserModel>;
  }

  @action.bound
  protected onCreateUserSuccess(data: UserResponseDTO) {
    this.tableStore.fetchAll();
    return UserModel.createFromDTO(data);
  }

  @action.bound
  protected onCreateUserError(reason: AxiosResponse) {
    if (reason.status === 400) {
      throw FormError.createFromBadRequestResponseDTO(reason.data);
    }

    const { description } = reason.data.message[0];

    if (reason.status === 409) {
      throw new FormError({ email: description })
    }

    throw new Error(description);
  }
}
