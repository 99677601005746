import React from 'react';
import Avatar from './AvatarDropdown';
import styles from './index.module.less';

const GlobalHeaderRight: React.FC = () => {
  let className = styles.right;

  return (
    <div className={className}>
      <Avatar />
    </div>
  );
};

export default GlobalHeaderRight;
