import React, { Component } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons/lib';
import { Divider, Popconfirm } from 'antd';

interface Props {
  id: string;
  onEdit(id: string): void;
  onDelete(id: string): void;
}

class PricesTableRowAction extends Component<Props> {
  render() {
    const { id, onDelete, onEdit } = this.props;

    const isDefaultPrice = id === "1";
    return (
      <>
        <EditOutlined onClick={() => onEdit(id)} />
        {!isDefaultPrice &&
          <>
            <Divider type="vertical" />
            <Popconfirm
              title="Вы действительно хотите удалить запись?"
              cancelText="Отмена"
              okText="Подтвердить"
              onConfirm={() => onDelete(id)}
            >
              <DeleteOutlined />
            </Popconfirm>
          </>
        }
      </>
    );
  }
}

export default PricesTableRowAction;
