import { lazyInject, provide } from '../../../utils/IoC';
import { HttpClientV1 } from '../../../utils/api/HttpClientV1';
import { FactoriesPricesTableStore } from './FactoriesPricesTableStore';
import { action, computed } from 'mobx';
import { AxiosResponse } from 'axios';
import { FormError } from '../../../utils/error/FormError';
import { FactoryPriceModel } from './model/FactoryPriceModel';
import { FactoryPriceResponseDTO } from './dto/response/factory-price.response.dto';
import { UpdateFactoryPriceRequestDTO } from './dto/request/update-factory-price.request.dto';

@provide.singleton()
export class FactoryPriceEditModalStore {
  @lazyInject(HttpClientV1)
  protected readonly httpClientV1!: HttpClientV1;

  @lazyInject(FactoriesPricesTableStore)
  tableStore!: FactoriesPricesTableStore;

  @computed
  get itemsMap() {
    return this.tableStore.itemsMap;
  }

  @action
  updateFactoryPrice(id: string, payload: UpdateFactoryPriceRequestDTO) {
    return this.httpClientV1.put<UpdateFactoryPriceRequestDTO, FactoryPriceResponseDTO>(
      `/factories-prices/${id}`, payload,
    )
      .then(this.onUpdateFactoryPriceSuccess)
      .catch(this.onUpdateFactoryPriceError) as Promise<FactoryPriceModel>;
  }

  @action.bound
  protected onUpdateFactoryPriceSuccess(data: FactoryPriceResponseDTO) {
    const model = FactoryPriceModel.createFromDTO(data);

    if (this.itemsMap.has(data.id)) {
      this.itemsMap.set(model.id as string, model);
    }

    return model;
  }

  @action.bound
  protected onUpdateFactoryPriceError(reason: AxiosResponse) {
    if (reason.status === 400) {
      throw FormError.createFromBadRequestResponseDTO(reason.data);
    }

    const { description } = reason.data.message[0];
    throw new Error(description);
  }
}
