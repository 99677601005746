import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import GlobalFooter from '../../components/GlobalFooter/index';
import BigLogoLight from '../../assets/images/logo/big-logo-light.svg';
import styles from './index.module.less';
import { observer } from 'mobx-react';
import { lazyInject } from '../../utils/IoC';
import { AuthStore } from '../../stores/auth/AuthStore';
import { Redirect } from 'react-router';

@observer
class AuthLayout extends Component {
  @lazyInject(AuthStore)
  authStore!: AuthStore;

  render() {
    if (this.authStore.isRefreshTokenValid) {
      return <Redirect to='/' />;
    }

    const { children } = this.props;
    return (
      <HelmetProvider>
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.header}>
              <Link to="/">
                <img alt="logo" className={styles.logo} src={BigLogoLight} />
              </Link>
            </div>

            <div className={styles.content}>
              {children}
            </div>
          </div>

          <GlobalFooter />
        </div>
      </HelmetProvider>
    );
  }
}

export default AuthLayout;
