import { observable } from 'mobx';
import { FactoryResponseDTO } from '../dto/response/factory.response.dto';

export class FactoryModel {
  @observable id?: string;
  @observable name?: string;
  @observable fullName?: string;
  @observable country?: string;
  @observable city?: string;
  @observable address?: string;
  @observable otk?: string;

  constructor(data: FactoryModel) {
    this.id = data.id;
    this.name = data.name;
    this.fullName = data.fullName;
    this.country = data.country;
    this.city = data.city;
    this.address = data.address;
    this.otk = data.otk;
  }

  static createFromDTO(dto: FactoryResponseDTO) {
    return new FactoryModel({
      id: dto.id,
      name: dto.name,
      fullName: dto.fullName,
      country: dto.country,
      city: dto.city,
      address: dto.address,
      otk: dto.otk,
    })
  }
}
