import React, { Component } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { observer } from 'mobx-react';
import { getStatusProductName, StatusProduct } from '../../stores/factory/const/status-product.enum';

interface Props extends Omit<SelectProps<StatusProduct>, 'children'> {
  onlyAllowedByStatus?: boolean;
}

@observer
class StatusSelect extends Component<Props> {

  render() {

    return (
      <Select {...this.props}>
        
          <>
            {Object.values(StatusProduct).map((status) => (
              
                <Select.Option key={status}  value={status}>
                  {getStatusProductName(status)}
                </Select.Option>
              
              ))
            }
          </>

      </Select>
    );
  }
}

export default StatusSelect;
