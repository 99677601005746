import { ConfigProvider } from 'antd';
import ruRU from 'antd/es/locale/ru_RU';
import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { history, renderRoutes } from './utils/router';
import { routes } from './routes';

class App extends Component {
  render() {
    return (
      <ConfigProvider locale={ruRU}>
        <Router history={history}>
          {renderRoutes(routes)}
        </Router>
      </ConfigProvider>
    )
  }
}

export default App;
