import React, { Component } from 'react';
import { Button, Modal, notification } from 'antd';
import { observer } from 'mobx-react';
import { lazyInject } from '../../../../utils/IoC';
import { FormInstance } from 'antd/lib/form';
import { action, computed, observable } from 'mobx';
import { UserModel } from '../../../../stores/users/model/UserModel';
import UserModalForm, { UserModalFormValue } from '../UserModalForm/index';
import { FormError } from '../../../../utils/error/FormError';
import { UserStatus } from '../../../../stores/users/const/user-status.enum';
import { UserEditModalStore } from '../../../../stores/users/UserEditModalStore';

interface Props {
  visible: boolean;
  id: string;
  onClose(): void;
}

@observer
class UserEditModal extends Component<Props> {
  @lazyInject(UserEditModalStore)
  store!: UserEditModalStore;

  formRef = React.createRef<FormInstance>();

  @observable loading: boolean = false;
  @observable error?: FormError;

  @computed
  get user(): UserModel | undefined {
    return this.store.itemsMap.get(this.props.id);
  }

  @action
  resetError = (key: string) => {
    delete this.error?.errors[key];
  }

  handleCancel = () => {
    this.props.onClose();
  };

  @action
  handleSave = () => {
    const value = this.formRef.current?.getFieldsValue() as UserModalFormValue | undefined;
    if (!value) {
      return;
    }

    const { fullName, email, phoneNumber, role, factory, pin, permissions, blocked,
			resetPassword, password } = value;
    const model = new UserModel({ fullName, email, phoneNumber, role, factory, pin, permissions });
    model.status = blocked ? UserStatus.Blocked : UserStatus.Active;

    this.loading = true;
    this.store.updateUser(this.props.id, model, resetPassword, password).then(this.handleSaveSuccess).catch(this.handleSaveError);
  };

  @action.bound
  handleSaveSuccess(model: UserModel) {
    this.loading = false;
    this.formRef.current?.resetFields();

    notification.success({ message: `Пользователь успешно сохранен` });

    this.props.onClose();
  }

  @action.bound
  handleSaveError(error: FormError | Error) {
    if (error instanceof FormError) {
      this.error = error;
    } else {
      notification.error({ message: error.message });
    }

    this.loading = false;
  }

  render() {
    const { visible } = this.props;
    const { user } = this;

    if (!user) {
      this.handleCancel();
      return null;
    }

    return (
      <Modal
        title="Изменение пользователя"
        visible={visible}
        width="600px" centered
        onCancel={this.handleCancel}
        footer={[
          <Button
            key="back"
            onClick={this.handleCancel}
          >
            Отмена
          </Button>,

          <Button
            key="submit"
            type="primary"
            disabled={this.loading}
            loading={this.loading}
            onClick={this.handleSave}
          >
            Сохранить
          </Button>,
        ]}
      >
        <UserModalForm
          formRef={this.formRef}
          error={this.error}
          onResetError={this.resetError}
          initialValues={user}
          showBlockSwitch
          showResetPassword
        />
      </Modal>
    );
  }
}

export default UserEditModal;
