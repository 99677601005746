import { observable } from 'mobx';
import { PriceResponseDTO } from '../../prices/dto/response/prices.response.dto';

export class FactoryPricePriceModel {
  @observable id?: string;
  @observable name?: string;
  @observable datamatrix?: string;
  @observable barcode?: string;

  constructor(model: FactoryPricePriceModel) {
    this.id = model.id;
    this.name = model.name;
    this.datamatrix = model.datamatrix;
    this.barcode = model.barcode;
  }

  static createFromDTO(dto: PriceResponseDTO) {
    return new FactoryPricePriceModel({
      id: dto.id,
      name: dto.name,
      datamatrix: dto.datamatrix,
      barcode: dto.barcode,
    });
  }
}
