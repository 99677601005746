import React, { Component } from 'react';
import { Divider, Popconfirm } from 'antd';
import { DeleteOutlined,} from '@ant-design/icons';
import { EditOutlined } from '@ant-design/icons/lib';

interface Props {
  id: number | string;
  onEdit?: (id: number | string) => void;
  onDelete?: (id: number | string) => void;
}

class EquipmentTableRowAction extends Component<Props> {
  render() {
    const { id, onEdit, onDelete } = this.props;

    return (
      <>
        
        <EditOutlined onClick={() => onEdit && onEdit(id)} />

<Divider type="vertical" />
        <Popconfirm
          title="Вы действительно хотите удалить запись?"
          cancelText="Отмена"
          okText="Подтвердить"
          onConfirm={() => onDelete && onDelete(id)}
        >
          <DeleteOutlined />
        </Popconfirm>
      </>
    );
  }
}

export default EquipmentTableRowAction;
