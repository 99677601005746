import { observable } from 'mobx';
import { EquipmentFactoryResponseDTO } from '../dto/response/equipment-factory.response.dto';

export class EquipmentFactoryModel {
  @observable id?: string;
  @observable name: string;

  constructor(data: EquipmentFactoryModel) {
    this.id = data.id;
    this.name = data.name;
  }

  static createFromDTO(dto: EquipmentFactoryResponseDTO) {
    return new EquipmentFactoryModel({
      id: dto.id,
      name: dto.name || 'Название не указано',
    });
  }
}
