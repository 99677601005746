import React, { Component } from 'react';
import { Col, Row } from 'antd';
import EquipmentFilesList, { EquipmentFileListItem, } from '../EquipmentFilesList/index';
import { EquipmentFileModel } from '../../../../stores/equipment/model/EquipmentFileModel';

interface Props {
  id: number;
  comment: string;
  files?: EquipmentFileModel[];
}

class EquipmentTableExpandedRow extends Component<Props> {
  render() {
    const { id, comment, files } = this.props;

    const newFiles = files?.map(
      (f, key) => ({ key, ...f } as EquipmentFileListItem)
    );

    const hasComment = !!(comment && comment.length > 0);
    const hasFiles = !!(newFiles && newFiles.length > 0);

    return (
      <Row gutter={16}>
        <Col span={24}>
          {hasComment && (
            <>
              <h3>Комменатрий</h3>
              <p>{comment}</p>
            </>
          )}

          {hasFiles && (
            <>
              <h3>Прикрепленные файлы</h3>
              <EquipmentFilesList id={id} files={newFiles || []} />
            </>
          )}
        </Col>
      </Row>
    );
  }
}

export default EquipmentTableExpandedRow;
