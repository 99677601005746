import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { Button, Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import { MailOutlined } from '@ant-design/icons';
import AuthFormContainer from '../../../components/AuthFormContainer/index';
import { lazyInject } from '../../../utils/IoC';
import { StartResetPasswordStore } from '../store/reset-password/StartResetPasswordStore';
import { FormError } from '../../../utils/error/FormError';

import styles from '../index.module.less';

@observer
class StartResetPasswordPage extends Component {
  @lazyInject(StartResetPasswordStore)
  store!: StartResetPasswordStore;

  @observable sent = false;
  @observable error?: FormError;

  @action
  handleChange = (property: string) => {
    delete this.error?.errors[property];
  };

  @action
  handleFinish = ({ email }: { email: string }) => {
    this.error = undefined;

    this.store.resetPasswordAttempt(email)
      .then(this.handleResetPasswordAttemptSuccess)
      .catch(this.handleResetPasswordAttemptError)
    ;
  };

  @action.bound
  handleResetPasswordAttemptSuccess() {
    this.sent = true;
  }

  @action.bound
  handleResetPasswordAttemptError(error: FormError) {
    this.error = error;
  }

  renderSentResetPasswordForm() {
    return (
      <Form>
        <Form.Item>
          <h1>Восстановление пароля</h1>
          <p>Мы отправили ссылку для восстановления пароля на вашу электронную почту</p>
        </Form.Item>

        <Form.Item>
          <p>Для продолжения перейдите по ссылке из полученного письма</p>
        </Form.Item>

        <div className={styles.cancel}>
          <Link to='/auth/login'>
            Отмена
          </Link>
        </div>
      </Form>
    );
  }

  renderResetPasswordForm() {
    const hasEmailError = !!(this.error?.errors?.email);

    return (
      <Form
        onFinish={this.handleFinish}
      >
        <Form.Item>
          <h1>Восстановление пароля</h1>
          <p>Укажите вашу зарегистрированную эл. почту для получения ссылки на восстановления пароля</p>
        </Form.Item>

        <Form.Item
          name="email"
          hasFeedback={hasEmailError}
          validateStatus={hasEmailError ? 'error' : undefined}
          help={this.error?.errors?.email}
        >
          <Input
            id="email"
            placeholder='Введите эл. почту'
            size='large'
            prefix={<MailOutlined style={{ color: '#D21919' }}/>}
          />
        </Form.Item>

        <Form.Item>
          <Button
            loading={this.store.loading}
            disabled={this.store.loading}
            size="large"
            className={styles.submit}
            type="primary"
            htmlType="submit"
          >
            Продолжить
          </Button>
        </Form.Item>

        <div className={styles.cancel}>
          <Link to='/auth/login'>
            Назад
          </Link>
        </div>
      </Form>
    );
  }

  render() {
    return (
      <AuthFormContainer>
        {this.sent
          ? this.renderSentResetPasswordForm()
          : this.renderResetPasswordForm()
        }
      </AuthFormContainer>
    );
  }
}

export default StartResetPasswordPage;
