import React, { Component } from 'react';
import { InputNumber } from 'antd';
import TableButtonFiltersDropdown from './TableButtonFiltersDropdown';
import { FilterDropdownProps } from 'antd/lib/table/interface';

interface Params {
  min?: number;
  max?: number;
  precision?: number;
  step?: number;
}

interface Props extends FilterDropdownProps, Params {
  dataIndex?: string;
}

class TableNumberRangeFilterDropdown extends Component<Props> {
  handleFromChange = (value: number | string | undefined | null) => {
    this.props.setSelectedKeys([
      (value === null || value === undefined ? undefined : +value as unknown) as number,
      this.props.selectedKeys[1]
    ]);
  };

  handleToChange = (value: number | string | undefined | null) => {
    this.props.setSelectedKeys( [
      this.props.selectedKeys[0],
      (value === null || value === undefined ? undefined : +value as unknown) as number
    ]);
  };

  render() {
    const { clearFilters, selectedKeys, confirm } = this.props;
    const { min, max, precision, step } = this.props;

    return (
      <div style={{ padding: "8px" }}>
        <InputNumber
          placeholder="От"

          min={min}
          max={Math.min(max || Infinity, +selectedKeys[1] || Infinity)}
          precision={precision}
          step={step}

          value={selectedKeys[0] as number | undefined}
          onChange={this.handleFromChange}
          style={{ marginBottom: 8, display: "flex", width: '100%' }}
        />

        <InputNumber
          placeholder="До"

          precision={precision}
          min={Math.max(min || -Infinity, +selectedKeys[0] || -Infinity)}
          max={max}
          step={step}

          value={selectedKeys[1] as number | undefined}
          onChange={this.handleToChange}
          style={{ marginBottom: 8, display: "flex", width: '100%' }}
        />

        <TableButtonFiltersDropdown
          handleReset={clearFilters}
          handleSearch={confirm}
        />
      </div>
    );
  }
}

export const getTableNumberRangeFilterDropdownProps = (params: Params = {}) => ({
  filterDropdown: (props: FilterDropdownProps) => (
    <TableNumberRangeFilterDropdown {...params} {...props} />
  ),
});

export default TableNumberRangeFilterDropdown;
