import React, { Component } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { observer } from 'mobx-react';
import { getStatusPrinterControllerName, StatusPrinterController } from '../../stores/equipment/const/status-printer-controller.enum';

interface Props extends Omit<SelectProps<StatusPrinterController>, 'children'> {
	onlyAllowedByStatus?: boolean;
}

@observer
class PrinterControllerStatusSelect extends Component<Props> {
	render() {
		return (
			<Select {...this.props}>
					<>
						{Object.values(StatusPrinterController).map((status) => (
								<Select.Option key={status}	value={status}>
									{getStatusPrinterControllerName(status)}
								</Select.Option>
							))
						}
					</>
			</Select>
		);
	}
}

export default PrinterControllerStatusSelect;
