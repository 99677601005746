import React, { Component } from "react";
import { Button } from "antd";
import { SearchOutlined } from "@ant-design/icons/lib";
import { StatusProduct } from "../../stores/factory/const/status-product.enum";

interface Props {
  handleSearch(): void;
  handleReset?(): void;
}

class TableButtonFiltersDropdown extends Component<Props> {

  
  render() {
    const { handleSearch, handleReset } = this.props;

    return (
      <div 
      style={{ minWidth: '100px', width: "100%", marginBottom: "8px" }}>
        <Button
          type="primary"
          onClick={() => handleSearch()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: "100%", marginRight: 8, height: "32px" }}
        >
          Поиск
        </Button>
      </div>
    );
  }
}

export default TableButtonFiltersDropdown;
