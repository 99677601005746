import React from 'react';
import { CopyrightOutlined } from '@ant-design/icons';
import styles from './index.module.less';

const GlobalFooter = () => {
  const startYear = 2020;
  const currentYear = new Date().getFullYear();

  const copyrightYears = currentYear > startYear
    ? `${startYear}-${currentYear}`
    : `${startYear}`;

  return (
    <footer className={styles.footer}>
      <div className={styles.copyright}>
        Copyright <CopyrightOutlined /> {copyrightYears} Integrity
      </div>
    </footer>
  )
};

export default GlobalFooter;
