import React from 'react';

import styles from './index.module.less';

const AuthFormContainer: React.FC = ({ children }) =>
  <div className={styles.container}>
    {children}
  </div>
;

export default AuthFormContainer;
