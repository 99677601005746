import React, { ChangeEvent, Component } from 'react';
import { action, observable } from 'mobx';
import { CheckOutlined, DeleteOutlined, EditOutlined, FileOutlined } from '@ant-design/icons';
import { Avatar, Input, List, notification, Spin } from 'antd';
import { observer } from 'mobx-react';
import FileDownload from 'js-file-download';
import fileSize from 'filesize';
import { EquipmentFileListItem } from '../EquipmentFilesList/index';
import { lazyInject } from '../../../../utils/IoC';
import { HttpClientV1 } from '../../../../utils/api/HttpClientV1';

interface Props {
  id?: number;
  isEditable?: boolean;
  onDeleteItem(): void;
  onFileNameChange?(key: number, fileName: string): void;
  item: EquipmentFileListItem;
}

@observer
class EquipmentFilesListItem extends Component<Props> {
  @lazyInject(HttpClientV1)
  httpClientV1!: HttpClientV1;

  @observable isEditActive: boolean = false;
  @observable fileName: string = "";
  @observable loading: boolean = false;

  @action
  handleDownload = () => {
    const { id: equipmentId, item: file } = this.props;

    this.loading = true;

    this.httpClientV1.get(`/equipment/${equipmentId}/files/${file.id}`, {
      responseType: 'blob',
    })
      .then((data: Blob) => this.handleDownloadSuccess(data, file.fileName))
      .catch(this.handleDownloadError);
  };

  @action.bound
  handleDownloadSuccess(data: Blob, fileName: string) {
    FileDownload(data, fileName);
    this.loading = false;
  }

  @action.bound
  handleDownloadError() {
    notification.error({ message: 'Не удалось загрузить файл' });
    this.loading = false;
  }

  @action
  handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.fileName = e.target.value;
  };

  @action
  handleStartEditClick = () => {
    this.isEditActive = true;
  };

  @action
  handleEndEditClick = () => {
    const { onFileNameChange, item } = this.props;
    this.isEditActive = false;

    onFileNameChange && onFileNameChange(item.key, this.fileName);
  };

  render() {
    const { item, isEditable, onDeleteItem } = this.props;

    return (
      <>
        <List.Item
          actions={
            isEditable
              ? [
                  !this.isEditActive ? (
                    <EditOutlined onClick={this.handleStartEditClick} />
                  ) : (
                    <CheckOutlined onClick={this.handleEndEditClick} />
                  ),
                  <DeleteOutlined onClick={() => onDeleteItem()} />,
                ]
              : []
          }
        >
          {this.isEditActive ? (
            <Input
              size="small"
              autoFocus
              bordered={false}
              placeholder={item.fileName}
              onChange={this.handleInputChange}
            />
          ) : (
            <List.Item.Meta
              avatar={
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a onClick={this.handleDownload}>
                  <Avatar icon={this.loading ? <Spin /> : <FileOutlined />}/>
                </a>
              }
              description={fileSize(item.fileSize)}
              title={
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a onClick={this.handleDownload}>
                  {this.fileName || item.fileName}
                </a>
              }
            />
          )}
        </List.Item>
      </>
    );
  }
}

export default EquipmentFilesListItem;
