import React, { Component } from 'react';
import { FormInstance } from 'antd/lib/form';
import { lazyInject } from '../../../../../utils/IoC';
import { FactoryPriceEditModalStore } from '../../../../../stores/finances/factories-prices/FactoryPriceEditModalStore';
import { action, computed, observable } from 'mobx';
import { FormError } from '../../../../../utils/error/FormError';
import { FactoryPriceModel } from '../../../../../stores/finances/factories-prices/model/FactoryPriceModel';
import { Button, Modal, notification } from 'antd';
import FactoryPriceModalForm, { FactoryPriceModalFormValue } from '../FactoryPriceModalForm/index';
import { observer } from 'mobx-react';

interface Props {
  visible: boolean;
  id: string;
  onClose(): void;
}

@observer
class FactoryPriceEditModal extends Component<Props> {
  @lazyInject(FactoryPriceEditModalStore)
  store!: FactoryPriceEditModalStore;

  formRef = React.createRef<FormInstance>();

  @observable loading: boolean = false;
  @observable error?: FormError;

  @computed
  get factoryPrice(): FactoryPriceModel | undefined {
    return this.store.itemsMap.get(this.props.id);
  }

  @action
  resetError = (key: string) => {
    delete this.error?.errors[key];
  }

  handleCancel = () => {
    this.props.onClose();
  };

  @action
  handleSave = () => {
    const value = this.formRef.current?.getFieldsValue() as FactoryPriceModalFormValue | undefined;
    if (!value) {
      return;
    }

    const { factory, price, startDate, endDate } = value;

    this.loading = true;
    this.store.updateFactoryPrice(this.props.id, {
      factoryId: factory?.id,
      priceId: price?.id,
      startDate: startDate ? startDate.toDate().getTime() : undefined,
      endDate: endDate ? endDate.toDate().getTime() : undefined,
    }).then(this.handleSaveSuccess).catch(this.handleSaveError);
  };

  @action.bound
  handleSaveSuccess(model: FactoryPriceModel) {
    this.loading = false;
    this.formRef.current?.resetFields();

    notification.success({ message: `Привязка тарифа успешно сохранена` });

    this.props.onClose();
  }

  @action.bound
  handleSaveError(error: FormError | Error) {
    if (error instanceof FormError) {
      this.error = error;
    } else {
      notification.error({ message: error.message });
    }

    this.loading = false;
  }

  render() {
    const { visible } = this.props;
    const { factoryPrice } = this;

    if (!factoryPrice) {
      this.handleCancel();
      return null;
    }

    return (
      <Modal
        title="Редактирование привязки тарифа"
        visible={visible}
        centered
        onCancel={this.handleCancel}
        footer={[
          <Button
            key="back"
            onClick={this.handleCancel}
          >
            Отмена
          </Button>,

          <Button
            key="submit"
            type="primary"
            disabled={this.loading}
            loading={this.loading}
            onClick={this.handleSave}
          >
            Сохранить
          </Button>,
        ]}
      >
        <FactoryPriceModalForm
          formRef={this.formRef}
          error={this.error}
          onResetError={this.resetError}
          initialValues={factoryPrice}
        />
      </Modal>
    );
  }
}

export default FactoryPriceEditModal;
