import React, { Component } from 'react';
import { Button, Col,  Form, Image, Input, Modal, notification, Row} from 'antd';
import { observer } from 'mobx-react';
import { lazyInject } from '../../../../utils/IoC';
import { action, computed, observable } from 'mobx';
import { FormInstance } from 'antd/lib/form';
import { FormError } from '../../../../utils/error/FormError';
import { FactoryModel } from '../../../../stores/factory/model/FactoryModel';
import { FactoryEditModalStore } from '../../../../stores/factory/FactoryEditModalStore';
const DATAMatrix = require("./datamatrix");
const saveSvgAsPng = require('save-svg-as-png');

type FormParams = {
  name?: string;
  fullName?: string;
  country?: string;
  city?: string;
  address?: string;
  otk?: string;
}

interface Props {
  visible: boolean;
  onClose: () => void;
  id: string;
}

// noinspection DuplicatedCode
@observer
class FactoryEditModal extends Component<Props> {
  @lazyInject(FactoryEditModalStore)
  store!: FactoryEditModalStore;

  @observable loading: boolean = false;
  @observable error?: FormError;

  refForm = React.createRef<FormInstance>();

  generateCodeInterval?: number = undefined;

  @computed
  get model() {
    return this.store.itemsMap.get(this.props.id);
  }


  @action
  handleChange = (key: string) => {
    delete this.error?.errors[key];
  }


  @action
  handleSave = () => {
    const value = this.refForm.current?.getFieldsValue() as FormParams;
    if (!value) {
      return
    }
    const id = this.model!.id!;
    const { name = '', fullName = '', country = '', city = '', address = '', otk = '' } = value;
    const model = new FactoryModel({
      name,
      fullName,
      country,
      city,
      address,
      otk,

    });


    this.loading = true;
    this.store.updateFactory(id, model)
    .then(this.handleSaveSuccess)
    .catch(this.handleSaveError);
  };



  @action.bound
  handleSaveSuccess() {
    this.loading = false;
    this.refForm.current?.resetFields();

    notification.success({ message: `Завод успешно сохранен` });
    this.props.onClose();
  }

  @action.bound
  handleSaveError(error: FormError) {
    this.error = error;
    this.loading = false;
  }

  @action
  handleCancel = () => {
    this.loading = false;
    this.refForm.current?.resetFields();

    this.props.onClose();
  }

  @action
  generateCode = () => {
    var data = {
      uuid: this.model?.id,
      name: this.model?.name
    }
     var url_add = JSON.stringify(data).replace(/[\u007F-\uFFFF]/g, (chr) => {return "\\u" + ("0000" + chr.charCodeAt(0).toString(16)).substr(-4)})
	const pad = 5;
	const svgnode = DATAMatrix({
		msg: url_add,
		dim : 180,	// dimensions
		rct : 0,	// keep datamatrix square
		pad,	// padding
		pal : ["#000000", "#ffffff"],	// pallette
		vrb : 1,	// verbose
	});
	var e = document.getElementById("factory-barcode");
	if(!e) {
        return false;
    }
    while(e.childNodes[0]) {
        e.removeChild(e.childNodes[0]);
    }
    e.appendChild(svgnode);

	// add text
	var text = {
		x: pad,
		y: svgnode.viewBox.baseVal["height"]-1,
		font_size: pad-1,
		text: data.name || "",
	};
	const svgns = "http://www.w3.org/2000/svg";
	var newtext = document.createElementNS(svgns, "text");
	newtext.setAttributeNS(null, "x", ""+text.x)
	newtext.setAttributeNS(null, "y", ""+text.y)
	newtext.setAttributeNS(null, "font-size", ""+text.font_size)

	var textnode = document.createTextNode(text.text);
	newtext.appendChild(textnode);
	svgnode.appendChild(newtext);
    return true;
  }

  @action
  downloadCodeImage = () => {
  	const name: string = this.model?.name || "undefined";
	this.generateCode();
	var e = document.getElementById("factory-barcode");
	if(!e || e.childNodes.length == 0) {
		console.log("no SVG element");
		return;
	}
  	saveSvgAsPng.saveSvgAsPng(e.childNodes[0], "factory_"+name+".png", {
		scale: 4,
	}).catch((e: any) => console.log(e));
  }

  render() {
    const { visible } = this.props;
    const { model,  } = this;
    const hasNameError = !!(this.error?.errors?.name);
    const hasFullNameError = !!(this.error?.errors?.fullName);
    const hasCountryError = !!(this.error?.errors?.country);
    const hasCityError = !!(this.error?.errors?.city);
    const hasAddressError = !!(this.error?.errors?.address);
    const hasOTKError = !!(this.error?.errors?.otk);

    if(!this.generateCodeInterval) {
        this.generateCodeInterval = setInterval(() => {
        	if(this.generateCode()) {
                clearInterval(this.generateCodeInterval);
                this.generateCodeInterval = undefined;
            }
        }, 200) as unknown as number;
    }

    return (
      <Modal
        title="Изменение завода"
        visible={visible}
        width="500px" centered
        onCancel={this.handleCancel}
        footer={[
          <Button
            key="back"
            onClick={this.handleCancel}
          >
            Отмена
          </Button>,

          <Button
            key="submit"
            type="primary"
            disabled={this.loading}
            loading={this.loading}
            onClick={this.handleSave}
          >
            Сохранить
          </Button>,
        ]}
      >
        <Form ref={this.refForm} layout="vertical">
          <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
              <Form.Item
                label="Название"
                name="name"
                key="name"

                hasFeedback={hasNameError}
                validateStatus={hasNameError ? 'error' : undefined}
                help={this.error?.errors?.name}

                initialValue={model?.name}
              >
                <Input
                  placeholder="Введите название завода"
                  onChange={() => this.handleChange("name")}
                />
              </Form.Item>

              <Form.Item
                label="Полное название"
                name="fullName"
                key="fullName"

                hasFeedback={hasFullNameError}
                validateStatus={hasFullNameError ? 'error' : undefined}
                help={this.error?.errors?.fullName}

                initialValue={model?.fullName}
              >
                <Input
                  placeholder="Введите полное наименование завода"
                  onChange={() => this.handleChange("fullName")}
                />
              </Form.Item>

              <Form.Item
                label="Страна"
                name="country"
                key="country"

                hasFeedback={hasCountryError}
                validateStatus={hasCountryError ? 'error' : undefined}
                help={this.error?.errors?.country}

                initialValue={model?.country}
              >
                <Input
                  placeholder="Введите страну"
                  onChange={() => this.handleChange("country")}
                />
              </Form.Item>

              <Form.Item
                label="Город"
                name="city"
                key="city"

                hasFeedback={hasCityError}
                validateStatus={hasCityError ? 'error' : undefined}
                help={this.error?.errors?.city}
                 
                initialValue={model?.city}
              >
                <Input
                  placeholder="Введите название города"
                  onChange={() => this.handleChange("city")}
                />
              </Form.Item>

              <Form.Item
                label="Адрес"
                name="address"
                key="address"

                hasFeedback={hasAddressError}
                validateStatus={hasAddressError ? 'error' : undefined}
                help={this.error?.errors?.address}

                initialValue={model?.address}
              >
                <Input
                  placeholder="Введите адрес завода"
                  onChange={() => this.handleChange("address")}
                />
              </Form.Item>

              <Form.Item
                label="Начальник ОТК"
                name="otk"
                key="otk"

                hasFeedback={hasOTKError}
                validateStatus={hasOTKError ? 'error' : undefined}
                help={this.error?.errors?.otk}

                initialValue={model?.otk}
              >
                <Input
                  placeholder="Введите ФИО начальника ОТК"
                  onChange={() => this.handleChange("otk")}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Row gutter={[16, 16]}>
              <Col xs={{ span: 12 }} sm={{ span: 12}} md={{ span: 12 }}>
              <Button onClick={this.generateCode}>Генерация DataMatrix</Button>
			  <Button onClick={this.downloadCodeImage}>Скачать изображение</Button>
              </Col>
              <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }}>
			  <div id="factory-barcode" className='align-right' style={{alignContent: 'right'}} />
              </Col>
              </Row>
      </Modal>
    );
  }
}

export default FactoryEditModal;
