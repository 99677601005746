import { observable } from 'mobx';
import moment, { Moment } from 'moment';
import { PrinterControllerResponseDTO } from '../dto/response/printer-controller.response.dto';
import { EquipmentFactoryModel } from './EquipmentFactoryModel';
import { PrinterControllerConfigModel } from './PrinterControllerConfigModel';

export class PrinterControllerModel {
	@observable id!: string;
	@observable factory?: EquipmentFactoryModel;
	@observable serial_number!: string;
	@observable status!: string;
	@observable version?: number;
	@observable update_to_version?: number;
	@observable last_online?: Moment;
	@observable last_sync?: Moment;
	@observable num_codes_to_sync!: number;
	@observable description?: string;
	@observable update_log?: string;
	@observable printer_model?: string;
	@observable config?: PrinterControllerConfigModel;

	constructor(data: PrinterControllerModel) {
		this.id = data.id;
		this.factory = data.factory;
		this.serial_number = data.serial_number;
		this.status = data.status;
		this.version = data.version;
		this.update_to_version = data.update_to_version;
		this.last_online = data.last_online;
		this.last_sync = data.last_sync;
		this.num_codes_to_sync = data.num_codes_to_sync;
		this.description = data.description;
		this.update_log = data.update_log;
		this.config = data.config;
		this.printer_model = data.printer_model;
	}

	static createFromDTO(dto: PrinterControllerResponseDTO) {
		return new PrinterControllerModel({
			id: dto.uuid,
			factory: dto.factory ? EquipmentFactoryModel.createFromDTO(dto.factory) : undefined,
			serial_number: dto.serial_number,
			status: dto.status,
			version: dto.version,
			update_to_version: dto.update_to_version,
			last_online: dto.last_online ? moment(dto.last_online) : undefined,
			last_sync: dto.last_sync ? moment(dto.last_sync) : undefined,
			num_codes_to_sync: dto.num_codes_to_sync,
			description: dto.description,
			update_log: dto.update_log,
			printer_model: dto.printer_model,
			config: dto.config ? PrinterControllerConfigModel.createFromDTO(dto.config) : undefined,
		});
	}

	static createRequestDTO(m: PrinterControllerModel) {
		return ({
			uuid: m.id,
			factory: m.factory,
			serial_number: m.serial_number,
			description: m.description,
			config: m.config,
		} as PrinterControllerResponseDTO);
	}
};
