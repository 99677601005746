import { lazyInject, provide } from '../../../utils/IoC';
import { HttpClientV1 } from '../../../utils/api/HttpClientV1';
import { PricesTableStore } from './PricesTableStore';
import { action } from 'mobx';
import { PriceModel } from './model/PriceModel';
import { PriceResponseDTO } from './dto/response/prices.response.dto';
import { AxiosResponse } from 'axios';
import { FormError } from '../../../utils/error/FormError';
import { AddPriceRequestDTO } from './dto/request/add-price.request.dto';

@provide.singleton()
export class PriceCreateModalStore {
  @lazyInject(HttpClientV1)
  protected readonly httpClientV1!: HttpClientV1;

  @lazyInject(PricesTableStore)
  tableStore!: PricesTableStore;

  @action
  createPrice(model: PriceModel) {
    return this.httpClientV1.post<AddPriceRequestDTO, PriceResponseDTO>(
      '/prices',
      {
        name: model.name!,
        datamatrix: model.datamatrix!,
        barcode: model.barcode!,
        startDate: model.startDate
          ? model.startDate.toDate().getTime()
          : undefined,
        endDate: model.endDate
          ? model.endDate.toDate().getTime()
          : undefined,
		barcode_pricing: model.barcode_pricing,
      }
    ).then(this.onCreatePriceSuccess).catch(this.onCreatePriceError) as Promise<PriceModel>;
  }

  @action.bound
  protected onCreatePriceSuccess(data: PriceResponseDTO) {
    this.tableStore.fetchAll();
    return PriceModel.createFromDTO(data);
  }

  @action.bound
  protected onCreatePriceError(reason: AxiosResponse) {
    if (reason.status === 400) {
      throw FormError.createFromBadRequestResponseDTO(reason.data);
    }

    const { description } = reason.data.message[0];
    throw new Error(description);
  }
}
