import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { getTableTextSearchDropdownProps } from '../../../components/Table/TableTextSearchDropdown';
import { Button, Card, Col, notification, Row, Skeleton, Space, Switch, Table } from 'antd';
import { action, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { lazyInject } from '../../../utils/IoC';
import { PricesTableStore } from '../../../stores/finances/prices/PricesTableStore';
import { getTableDateFilterDropdownProps } from '../../../components/Table/TableDateFilterDropdown';
import { Moment } from 'moment';
import { MomentDateFormat } from '../../../utils/MomentDateFormat';
import { PriceModel } from '../../../stores/finances/prices/model/PriceModel';
import PricesTableRowAction from './components/PricesTableRowAction';
import { getTableNumberRangeFilterDropdownProps } from '../../../components/Table/TableNumberRangeFilterDropdown';
import PricesCreateModal from './components/PricesCreateModal/index';
import PriceEditModal from './components/PricesEditModal/index';
import { CategoryAccessStore } from '../../../stores/users/CategoryAccessStore';
import { Redirect } from 'react-router';

@observer
class PricesPage extends Component {
  @lazyInject(PricesTableStore)
  store!: PricesTableStore;

  @lazyInject(CategoryAccessStore)
  categoryAccessStore!: CategoryAccessStore;

  @observable isModalVisible = { create: false, edit: false };
  @observable currentEditId?: string = undefined;

  componentDidMount() {
    this.store.fetchAll();
  }

  //region Handlers
  deleteRecord = (id: string) => {
    this.store.deleteItem(id).catch(() => notification.error({ message: 'Не удалось удалить тариф' }));
  };

  @action
  openCreateModal = () => {
    this.isModalVisible.create = true;
  };

  @action
  closeCreateModal = () => {
    this.isModalVisible.create = false;
  };

  @action
  openEditModal = (id: string) => {
    this.currentEditId = id;
    this.isModalVisible.edit = true;
  };

  @action
  closeEditModal = () => {
    this.isModalVisible.edit = false;
    this.currentEditId = undefined;
  };

  @action
  handleDownloadXlsx = () => {
  	this.store.downloadXlsx("prices_list.xlsx");
  };
  //endregion

  render() {
    if (!this.categoryAccessStore.loading && !this.categoryAccessStore.hasFinancesAccess) {
      return <Redirect to='/' />;
    }

    const columns = [
      {
        title: null,
        key: "index",
        render: (value: any, item: any, index: number) => {
            const p = this.store.tablePagination;
            return (<a href="#" onClick={() => this.openEditModal(item.id)}>{p.pageSize * (p.current-1)+1+index}</a>);
        },
      },
      {
        title: "Наименование тарифа",
        dataIndex: "name",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["fullName"],
        filteredValue: (this.store.filters.name as string[]) || null,
        ...getTableTextSearchDropdownProps("Наименование тарифа"),
      },
      {
        title: "Цена за код нанесенный принтером",
        dataIndex: "barcode",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["barcode"],
        filteredValue: (this.store.filters.barcode as string[]) || null,
        ...getTableNumberRangeFilterDropdownProps({ min: 0, precision: 2, step: 0.01 }),
        render: (value: string) => {
          return (
            <div className="editable-cell-value-wrap">
              {Number(value).toFixed(2)}
            </div>
          );
        },
      },
      {
        title: "Цена за код на ленте",
        dataIndex: "datamatrix",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["datamatrix"],
        filteredValue: (this.store.filters.datamatrix as string[]) || null,
        ...getTableNumberRangeFilterDropdownProps({ min: 0, precision: 2, step: 0.01 }),
        render: (value: string) => {
          return (
            <div className="editable-cell-value-wrap">
              {Number(value).toFixed(2)}
            </div>
          );
        },
      },
      {
        title: "Дата начала действия",
        dataIndex: "startDate",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["startDate"],
        filteredValue: (this.store.filters.startDate as string[]) || null,
        ...getTableDateFilterDropdownProps(),
        render: (date: Moment) => {
          return (
            <div className="editable-cell-value-wrap">
              {date ? date.format(MomentDateFormat) : 'Отсутствует'}
            </div>
          );
        },
      },
      {
        title: "Дата окончания действия",
        dataIndex: "endDate",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["endDate"],
        filteredValue: (this.store.filters.endDate as string[]) || null,
        ...getTableDateFilterDropdownProps(),
        render: (date: Moment) => {
          return (
            <div className="editable-cell-value-wrap">
              {date ? date.format(MomentDateFormat) : 'Отсутствует'}
            </div>
          );
        },
      },
      {
        title: "Действия",
        render: (text: string, record: PriceModel) => (
          <PricesTableRowAction
            id={record.id as string}
            onEdit={(id) => this.openEditModal(id)}
            onDelete={(id) => this.deleteRecord(id)}
          />
        ),
      },
    ];

    return (
      <PageHeaderWrapper>
        {this.isModalVisible.create &&
          <PricesCreateModal
            visible={this.isModalVisible.create}
            onClose={this.closeCreateModal}
          />
        }

        {this.isModalVisible.edit && this.currentEditId &&
          <PriceEditModal
            visible={this.isModalVisible.edit}
            onClose={this.closeEditModal}
            id={this.currentEditId}
          />
        }

        <Card>
          <Row gutter={[16, 16]}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Button
                size="large"
                block
                type="primary"
                onClick={this.openCreateModal}
              >
                Добавить тариф
              </Button>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
              xl={{ span: 6 }}

              style={{ display: 'flex' }}
            >
              <Space align='center'>
                <Switch checked={this.store.isActual} onChange={this.store.setIsActual} />
                <p style={{ marginBottom: 0 }}>Только актуальные</p>
              </Space>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 5 }}
              lg={{ span: 4, offset: 2 }}
              xl={{ span: 4, offset: 2 }}
            >
              <Button block onClick={this.store.handleTableRefresh}>
                Обновить список
              </Button>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 5 }}
              lg={{ span: 4 }}
              xl={{ span: 4 }}
            >
              <Button block onClick={this.store.handleTableReset}>
                Сбросить фильтры
              </Button>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 5 }}
              lg={{ span: 4 }}
              xl={{ span: 2 }}
            >
              <Button block onClick={this.handleDownloadXlsx}>
			  	XLS
              </Button>
            </Col>
          </Row>

          <Table
            loading={this.store.loading}
            columns={columns}
            rowKey={(record) => record.id as string}
            dataSource={toJS(this.store.items)}

            pagination={{
              position: ["bottomRight"],
              showSizeChanger: true,
              ...this.store.tablePagination,
            }}
            locale={{
              emptyText: this.store.loading ? (
                <>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </>
              ) : (
                <p>Данные отсутствуют</p>
              ),
            }}
            onChange={this.store.handleTableChange}
            onRow={(record, rowIndex) => {
                return {
                    onDoubleClick: event => {
                      this.openEditModal(record.id as string);
                    },
                };
            }}
          />
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default PricesPage;
