import React, { Component } from 'react';
import TableButtonFiltersDropdown from './TableButtonFiltersDropdown';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import FactoryAutoCompleteSelect from '../AutoCompleteSelect/FactoryAutoCompleteSelect/index';

interface Props extends FilterDropdownProps {
  placeholder?: string;
}

export const getTableFactoryFilterDropdownProps = () => ({
  filterDropdown: (props: FilterDropdownProps) => (
    <TableFactoryFilterDropdown {...props} />
  ),
});

class TableFactoryFilterDropdown extends Component<Props> {
  handleChange = (factory?: any/*{ id: string; name: string }*/) => {
    this.props.setSelectedKeys(
//      !factory ? [] : [(factory as unknown) as string]
      !factory ? [] : factory.map((i: any) => { return ((i as unknown) as string)/*return i.name*/ })
    );
  };

  render() {
    const { clearFilters, confirm } = this.props;

    return (
      <div style={{ padding: "8px" }}>
        <FactoryAutoCompleteSelect
          style={{ width: "100%", marginBottom: "8px" }}
          onChange={this.handleChange}
		  mode="multiple"
        />
        <TableButtonFiltersDropdown
          handleReset={clearFilters}
          handleSearch={confirm}
        />
      </div>
    );
  }
}

export default TableFactoryFilterDropdown;
