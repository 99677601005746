import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { RouteConfig } from 'react-router-config';
import { createBrowserHistory } from 'history';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { container } from './IoC';

export interface RouteSyntax<PropsType = object> extends RouteConfig {
  component?: React.ComponentType<any>;
  componentProps?: object;
  path?: string;
  icon?: React.ReactNode;
  hideInMenu?: boolean;
  name?: string;
  breadCrumbName?: string;
  routes?: RouteSyntax[];
  hasAccess?: () => boolean;
}

const browserHistory = createBrowserHistory();
const routingStore = container.get(RouterStore);

export const history = syncHistoryWithStore(browserHistory, routingStore);

function renderRoute(route: RouteSyntax, index: number) {
  const routeProps = {
    key: route.key || index,
    exact: route.exact,
    strict: route.strict,
    sensitive: route.sensitive,
    path: route.path,
  };

  if (route.redirect) {
    return <Redirect {...routeProps} from={route.path} to={route.redirect}/>;
  }

  function render(route: RouteSyntax, props: object) {
    const routes = renderRoutes(route.routes || []);
    const { component: Component } = route;

    if (!Component) {
      return routes;
    }

    const componentProps = {
      ...props,
      ...(route.componentProps || {}),
      route,
    };

    return <Component {...componentProps}>{routes}</Component>;
  }

  return <Route {...routeProps} render={(props: object) => render(route, props)}/>;
}

export function renderRoutes(routes: RouteSyntax[]) {
  if (!routes) {
    return null;
  }

  return (
    <Switch>
      {routes.map((route, index) => renderRoute(route, index))}
    </Switch>
  );
}
