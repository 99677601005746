import React, { Component } from 'react';
import { Button, Col, Form, Input, Modal, notification, Row, Select } from 'antd';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Moment } from 'moment';
import { FormInstance } from 'antd/lib/form';
import { lazyInject } from '../../../../utils/IoC';
import { CablesModel } from '../../../../stores/factory/model/CablesModel';
import { FormError } from '../../../../utils/error/FormError';
import styles from './index.module.less';
import { CablesCreateModalStore } from '../../../../stores/factory/CablesCreateModalStore';
import FactoryAutoCompleteSelect from '../../../../components/AutoCompleteSelect/FactoryAutoCompleteSelect';
import { FactoryResponseDTO } from '../../../../stores/factory/dto/response/factory.response.dto';
import { UserResponseDTO } from '../../../../stores/users/dto/response/user.response.dto';
import { CurrentUserStore } from '../../../../stores/users/CurrentUserStore';
import axios from 'axios';
import { ACCESS_TOKEN_STORAGE_KEY } from '../../../../stores/auth/const/AuthTokensStorageKeys';

export const MomentDateFormat = 'DD.MM.YYYY';

const api_url = process.env.REACT_APP_API_SERVER_HOST
const { Option } = Select;

type FormParams = {
  factory?: FactoryResponseDTO;
  length: number;
  price?: number;
  codes?: number;
  type?: string;
  drum_number?: string;
  otk?: UserResponseDTO;
  time_created: string;
  date_created: Moment;
  joints?: Array<Array<string>>;
  bounding_codes_0?: string;
  bounding_codes_1?: string;
  number_tumb?: string;
}

interface Props {
  visible: boolean;
  onClose: () => void;
  id?: number;
}

// noinspection DuplicatedCode
@observer
class CablesCreateModal extends Component<Props> {
  @lazyInject(CablesCreateModalStore)
  store!: CablesCreateModalStore;

  @lazyInject(CurrentUserStore)
  currentUserStore!: CurrentUserStore;
  @observable loading: boolean = false;
  @observable error?: FormError;

  refForm = React.createRef<FormInstance>();
  state = {
    number_tumb: '',
    type: '',
    joints: [],
    load: false,
    length_joints: '0'
  }
  @action
  addJoints = () => {
    if (!this.state) {
      return
    } else {
	  const value = this.refForm.current?.getFieldsValue() as FormParams;
	  let {joints} = value;
	  if(!joints) { joints = []; }
	  joints.push(["", ""]);

      this.setState({ joints, length_joints: parseInt(this.state.length_joints) + 1 },
		() => {
			this.forceUpdate();
			this.refForm.current?.setFieldsValue(this.state)
	  });
    }
  };
  @action
  deleteJoints = (i: number) => {
    if (!this.state) {
      return
    } else {
	  const value = this.refForm.current?.getFieldsValue() as FormParams;
	  let {joints} = value;

      if (i > -1) {
        joints?.splice(i, 1);
      }
      this.setState({ joints, length_joints: parseInt(this.state.length_joints) - 1 },
	  	() => {
	      this.refForm.current?.setFieldsValue(this.state)
        });
    }
  };
  @action
  handleChange = (key: string) => {
    if (key === 'type') {
      const value = this.refForm.current?.getFieldsValue() as FormParams;
      if (value.type === 'datamatrix') {
        this.addJoints()
      } else {
        this.setState({ joints: [], length_joints: parseInt(this.state.length_joints) - 1 })
      }
    };
    delete this.error?.errors[key];
  }

  @action
  handleCreate = () => {
    const value = this.refForm.current?.getFieldsValue() as FormParams;
    if (!value) {
      return
    }
    const bounding_codes: any = [value.bounding_codes_0, value.bounding_codes_1]
    const { factory, length, codes, type, number_tumb, otk, date_created, joints, time_created, price } = value;
    const model = new CablesModel({
      factory,
      price,
      date_created,
//      time_created,
      length,
      codes,
      type,
      drum_number: number_tumb,
      otk,
      joints,
      bounding_codes
    });

    this.loading = true;
    const product = {
      length: +model.length,
      codes: model.codes,
      type: model.type,
      drum_number: model.drum_number,
      price: model.price,
      joints: model.joints,
      bounding_codes: model.bounding_codes,
    };

    const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) || undefined
	    axios.interceptors.response.use(undefined, function (error) {
        (error as any).originalMessage = error.message;
        Object.defineProperty(error, "message", { get: function () {
            if (!error.response) {
                return (error as any).originalMessage;
            }
            return JSON.stringify(error.response.data);
        }});
        return Promise.reject(error);
    })
    axios
      .put(
        api_url + "/v1/admin/product",
        {
          product_id: null,
          create_new: true,
          factory_id: model.factory?.id,
          product: product,
        },
        {
          headers: {
            Authorization:
              "Bearer " + token,
          },
        }
      )
      .then(resp => (this.handleCreateSuccess(resp.data)))
	  .catch((error) => {console.log(JSON.stringify(error.message));
	  notification.error({message: JSON.parse(error.message).message})})
//      .catch(this.handleCreateError)
  }

  @action.bound
  handleCreateSuccess(data: any) {
    this.loading = false;
    this.refForm.current?.resetFields();

    notification.success({ message: `Успешно добавлено изделие (id: ${data.product_id})` });

    this.props.onClose();
  }

  @action.bound
  handleCreateError(error: FormError) {
    this.loading = false;
    this.error = error;
	notification.error({message: `Ошибка сохранения`});
  }

  @action
  handleCancel = () => {


    this.props.onClose();
  };

  render() {
    const { visible } = this.props;
    const currentUser = this.currentUserStore.user;
    const hasLengthError = !!(this.error?.errors?.length);
    const hasTumbError = !!(this.error?.errors?.number_tumb);
    const hasTypeError = !!(this.error?.errors?.type);
    var i = 0;
    let button;
    if (this.refForm.current?.getFieldsValue().type === 'datamatrix') {
      button = <Button
        key="add_joint"
        type="primary"
        disabled={this.loading}
        loading={this.loading}
        onClick={this.addJoints}
      >
        Добавить соединение
      </Button>
    }
    else {
      button = ''
    }
    return (
      <Modal
        title="Добавление изделия"
        visible={visible}
        width="500px" centered
        onCancel={this.handleCancel}
        footer={[
          <Button
            key="back"
            onClick={this.handleCancel}
          >
            Отмена
          </Button>,

          <Button
            key="submit"
            type="primary"
            disabled={this.loading}
            loading={this.loading}
            onClick={this.handleCreate}
          >
            Добавить
          </Button>,
        ]}
      >
        <Form ref={this.refForm} layout="vertical">
          <Row gutter={[16, 16]}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
              <Form.Item
                label="Тип кода"
                name="type"
                key="type"

                rules={[{ required: true, message: 'Поле не может быть пустым' }]}
                hasFeedback={hasTypeError}
                validateStatus={hasTypeError ? 'error' : undefined}
                help={this.error?.errors?.type}
              >
                <Select
                  placeholder="Тип кода"
                  onChange={() => this.handleChange("type")}

                >
                  <Option value="code128">Code128</Option>
                  <Option value="datamatrix">DataMatrix</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Первый код"
                name="bounding_codes_0"
                key="bounding_codes_0"
                rules={[{ required: true, message: 'Поле не может быть пустым' }]}
              >
                <Input
                  placeholder="Первый код"
                  onChange={() => this.handleChange("fullName")}
                />
              </Form.Item>
              <Form.Item
                label="Последний код"
                name="bounding_codes_1"
                key="bounding_codes_1"
                rules={[{ required: true, message: 'Поле не может быть пустым' }]}
              >
                <Input
                  placeholder="Последний код"
                  onChange={() => this.handleChange("country")}
                />
              </Form.Item>
              {this.state.joints.map((item: Array<string>, id: number) => {
                return <div key={"joints_"+id}>
                  <Form.Item
                    label="Список соединений"

                    rules={[{ required: true, message: 'Поле не может быть пустым' }]}
                  >
                    <Input.Group >
                      {
                        item.map((code: string, id_code: number) => {
                          var name = 'joints';
                          return <div key={"joints_"+id+"_"+id_code}>
                            <Form.Item
                              name={[name, id, id_code]}
                              initialValue={code}
                            >
                              <Input placeholder="Соединение" />
                            </Form.Item><p></p>
                          </div>
                        })
                      }
                    </Input.Group>
                    <Button
                      key={"delete_joint_"+id}
                      type="primary"
                      disabled={this.loading}
                      loading={this.loading}
                      onClick={() => this.deleteJoints(id)}
                    >
                      Удалить соединение
                      </Button>
                  </Form.Item>
                  <p></p>
                </div>
              })}
              {button}
              <Form.Item
                label="Завод"
                name="factory"
                key="factory"
                rules={[{ required: true, message: 'Поле не может быть пустым' }]}
              >
                <FactoryAutoCompleteSelect
                  className={styles.datepicker}
                  allowClear

                  onChange={() => this.handleChange("factory")}
                />
              </Form.Item>
              <Form.Item
                label="Длина"
                name="length"
                key="length"

                rules={[{ required: true, message: 'Поле не может быть пустым' }]}
                hasFeedback={hasLengthError}
                validateStatus={hasLengthError ? 'error' : undefined}
                help={this.error?.errors?.length}
              >
                <Input
                  type='number'
                  placeholder="Введите длину"
                  onChange={() => this.handleChange("length")}
                />
              </Form.Item>
              <Form.Item
                label="Номер барабана"
                name="number_tumb"
                key="number_tumb"

                hasFeedback={hasTumbError}
                validateStatus={hasTumbError ? 'error' : undefined}
                help={this.error?.errors?.number_tumb}
              >
                <Input
                  placeholder="Номер барабана"
                  onChange={() => this.handleChange("number_tumb")}
                />
              </Form.Item>
              <Form.Item
                label="Оператор ОТК"
                name="otk"
                key="otk"
                hidden
                initialValue={currentUser?.id}
              >
                <Input
                  hidden
                  className={styles.datepicker}

                  onChange={() => this.handleChange("otk")}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default CablesCreateModal;
