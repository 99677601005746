import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { Button, Card, Col, notification, Row, Skeleton, Table } from 'antd';
import { action, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { lazyInject } from '../../utils/IoC';
import { UsersTableStore } from '../../stores/users/UsersTableStore';
import { getTableTextSearchDropdownProps } from '../../components/Table/TableTextSearchDropdown';
import { UserModel } from '../../stores/users/model/UserModel';
import { getTableFactoryFilterDropdownProps } from '../../components/Table/TableFactoryFilterDropdown';
import UserTableRowAction from './component/UserTableRowAction';
import UserCreateModal from './component/UserCreateModal/index';
import UserEditModal from './component/UserEditModal/index';
import { getUserRoleName, UserRole } from '../../stores/users/const/user-role.enum';
import { CurrentUserStore } from '../../stores/users/CurrentUserStore';
import { getTableUserRoleFilterDropdownProps } from '../../components/Table/TableUserRoleFilterDropdown';
import { CategoryAccessStore } from '../../stores/users/CategoryAccessStore';
import { Redirect } from 'react-router';

@observer
class UsersPage extends Component {
  @lazyInject(UsersTableStore)
  store!: UsersTableStore;

  @lazyInject(CurrentUserStore)
  currentUserStore!: CurrentUserStore;

  @lazyInject(CategoryAccessStore)
  categoryAccessStore!: CategoryAccessStore;

  @observable isModalVisible = { create: false, edit: false };
  @observable currentEditId = "";

  componentDidMount() {
    this.store.fetchAll();
  }

  @action
  openCreateModal = () => {
    this.isModalVisible.create = true;
  };

  @action
  closeCreateModal = () => {
    this.isModalVisible.create = false;
    this.store.fetchAll();
  };

  @action
  openEditModal = (id: string) => {
    if (!this.hasAccessToUser(id)) {
      notification.error({ message: 'У вас не хватает прав для редактирования этого пользователя' })
      return;
    }

    this.currentEditId = id;
    this.isModalVisible.edit = true;
  };

  @action
  closeEditModal = () => {
    this.currentEditId = "";
    this.isModalVisible.edit = false;
  };

  deleteRecord = (id: string) => {
    if (!this.hasAccessToUser(id)) {
      notification.error({ message: 'У вас не хватает прав для удаления этого пользователя' })
      return;
    }

    this.store.deleteItem(id).catch(() => notification.error({ message: 'Не удалось удалить пользователя' }));
  };

  hasAccessToUser(id: string) {
    const userToEdit = this.store.itemsMap.get(id);
    if (!userToEdit) {
      return false;
    }

    const currentUserRole = this.currentUserStore.user?.role;
    const userToEditRole = userToEdit.role;

    return !(
      [UserRole.IntegrityManager, UserRole.IntegrityAdmin].includes(userToEditRole!)
      && currentUserRole !== UserRole.IntegrityAdmin
    );
  }

  @action
  handleDownloadXlsx = () => {
  	this.store.downloadXlsx("user_list.xlsx");
  };

  render() {
    if (!this.categoryAccessStore.loading && !this.categoryAccessStore.hasUsersAccess) {
      return <Redirect to='/' />;
    }

    const columns = [
      {
        title: null,
        key: "index",
        render: (value: any, item: any, index: number) => {
            const p = this.store.tablePagination;
            return (<a href="#" onClick={() => this.openEditModal(item.id)}>{p.pageSize * (p.current-1)+1+index}</a>);
        },
      },
      {
        title: "ФИО",
        dataIndex: "fullName",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["fullName"],
        filteredValue: (this.store.filters.fullName as string[]) || null,
        ...getTableTextSearchDropdownProps("ФИО"),
      },
      {
        title: "E-mail",
        dataIndex: "email",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["email"],
        filteredValue: (this.store.filters.email as string[]) || null,
        ...getTableTextSearchDropdownProps("E-mail"),
      },
      {
        title: "Телефон",
        dataIndex: "phoneNumber",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["phoneNumber"],
        filteredValue: (this.store.filters.phoneNumber as string[]) || null,
        ...getTableTextSearchDropdownProps("Телефон"),
      },
      {
        title: "Роль",
        dataIndex: "role",
        filteredValue: (this.store.filters.role as string[]) || null,
        ...getTableUserRoleFilterDropdownProps(),

        render: (role: UserRole) => {
          return (
            <div className="editable-cell-value-wrap">
              {getUserRoleName(role)}
            </div>
          );
        },
      },
      {
        title: "Завод",
        dataIndex: "factory",
        filteredValue: (this.store.filters.factory as string[]) || null,
        ...getTableFactoryFilterDropdownProps(),

        render: (factory: { id: string; name: string }) => {
          return (
            <div className="editable-cell-value-wrap">
              {factory?.name || "Отсутствует"}
            </div>
          );
        },
      },
      {
        title: "Действия",
        render: (text: string, record: UserModel) => (
          <UserTableRowAction
            id={record.id as string}
            onEdit={(id) => this.openEditModal(id)}
            onDelete={(id) => this.deleteRecord(id)}
          />
        ),
      },
    ];

    return (
      <PageHeaderWrapper>
        {this.isModalVisible.create && (
          <UserCreateModal
            visible={this.isModalVisible.create}
            onClose={this.closeCreateModal}
          />
        )}

        {this.isModalVisible.edit &&
          ((this.currentEditId as unknown) as number) !== -1 && (
            <UserEditModal
              visible={this.isModalVisible.edit}
              onClose={this.closeEditModal}
              id={this.currentEditId}
            />
          )}
        <Card>
          <Row gutter={[16, 16]}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Button
                size="large"
                block
                type="primary"
                onClick={this.openCreateModal}
              >
                Добавить пользователя
              </Button>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 5, offset: 6 }}
              lg={{ span: 4, offset: 8 }}
              xl={{ span: 4, offset: 8 }}
            >
              <Button block onClick={this.store.handleTableRefresh}>
                Обновить список
              </Button>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 5 }}
              lg={{ span: 4 }}
              xl={{ span: 4 }}
            >
              <Button block onClick={this.store.handleTableReset}>
                Сбросить фильтры
              </Button>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 5 }}
              lg={{ span: 4 }}
              xl={{ span: 2 }}
            >
              <Button block onClick={this.handleDownloadXlsx}>
			  	XLS
              </Button>
            </Col>
          </Row>
          <Table
            loading={this.store.loading}
            columns={columns}
            rowKey={(record) => record.id as string}
            dataSource={toJS(this.store.items)}
            pagination={{
              position: ["bottomRight"],
              showSizeChanger: true,
              ...this.store.tablePagination,
            }}
            locale={{
              emptyText: this.store.loading ? (
                <>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </>
              ) : (
                <p>Данные отсутствуют</p>
              ),
            }}
            onChange={this.store.handleTableChange}
            onRow={(record, rowIndex) => {
                return {
                    onDoubleClick: event => {
                      this.openEditModal(record.id as string);
                    },
                };
            }}
          />
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default UsersPage;
