import { lazyInject, provide } from '../../utils/IoC';
import { action, computed } from 'mobx';
import { HttpClientV1TwoApi } from '../../utils/api/HttpClientV1twoApi';
import { PrinterControllerTableStore } from './PrinterControllerTableStore';
import { PrinterControllerModel } from './model/PrinterControllerModel';
import { PrinterControllerResponseDTO } from './dto/response/printer-controller.response.dto';
import { UpdatePrinterControllerRequestDTO } from './dto/request/update-printer-controller.request.dto';
import { UpdatePrinterControllerVersionRequestDTO } from './dto/request/update-printer-controller-version.request.dto';
import { GetPrinterControllerUpdateVersionsResponseDTO } from './dto/response/get-printer-controller-update-versions.response.dto';

@provide.singleton()
export class PrinterControllerEditModalStore {
	@lazyInject(HttpClientV1TwoApi)
	protected readonly httpClientV1Two!: HttpClientV1TwoApi;

	@lazyInject(PrinterControllerTableStore)
	tableStore!: PrinterControllerTableStore;

	@computed
	get itemsMap() {
		return this.tableStore.itemsMap;
	}

	@action
	updatePrinterController(model: PrinterControllerModel) {
		return this.httpClientV1Two.put<UpdatePrinterControllerRequestDTO, PrinterControllerResponseDTO>(
			'/equipment/printer_controller', {
				uuid: model.id,
				factory_id: model.factory?.id,
				printer_controller: PrinterControllerModel.createRequestDTO(model),
			}
		);
	}

	@action
	fetchPrinterControllerUpgradeVersions(model: PrinterControllerModel) {
		return this.httpClientV1Two.get<GetPrinterControllerUpdateVersionsResponseDTO>(
			'/equipment/printer_controller_updates', {
				params: {
					uuid: model.id,
					version: model.version,
				},
			}
		);
	}

	@action
	handleUpdateRequest(model: PrinterControllerModel, version: number) {
		return this.httpClientV1Two.put<UpdatePrinterControllerVersionRequestDTO, PrinterControllerResponseDTO>(
			'/equipment/printer_controller_update', {
				uuid: model.id,
				version,
			}
		);
	}

	@action
	fetchUpgradeLog(model: PrinterControllerModel) {
		return this.httpClientV1Two.get<string>(
			'/equipment/printer_controller_upgrade_log', {
				params: {
					uuid: model.id,
				},
			},
		);
	}
};
