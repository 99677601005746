import { lazyInject, provide } from '../../utils/IoC';
import { RibbonImportModel } from './model/RibbonImportModel';
import { HttpClientV1 } from '../../utils/api/HttpClientV1';
import { HttpClientV1TwoApi } from '../../utils/api/HttpClientV1twoApi';
import { RibbonImportResponseDTO } from './dto/response/ribbon_import.response.dto';
import { BaseTableStore } from '../../utils/store/BaseTableStore';

@provide.singleton()
export class RibbonImportTableStore extends BaseTableStore<RibbonImportModel, RibbonImportResponseDTO> {
  @lazyInject(HttpClientV1TwoApi)
  protected readonly httpClientV1!: HttpClientV1TwoApi;

  protected readonly path = '/ribbon/import/list_admin_lk';
  protected readonly delete_path = "/ribbon/import";
  protected readonly httpClient = this.httpClientV1;

  protected get searchDTO(): object {
    return {
      id: this.filters.id ? this.filters.id[0] : undefined,
    };
  }

  protected get filterDTO(): object {
    return {
      id: undefined,
    };
  }

  protected createModelFromResponseDTO(dto: RibbonImportResponseDTO): RibbonImportModel {
    return RibbonImportModel.createFromDTO(dto);
  }
}
