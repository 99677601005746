import React, { Component } from 'react';
import { Button, Col, DatePicker, Form, Input, Modal, notification, Row, Upload } from 'antd';
import { observer } from 'mobx-react';
import FactoryAutoCompleteSelect from '../../../../components/AutoCompleteSelect/FactoryAutoCompleteSelect/index';
import EquipmentUploadContent from '../EquipmentUploadContent/index';

import styles from './index.module.less';
import { lazyInject } from '../../../../utils/IoC';
import EquipmentFilesList, { EquipmentFileListItem, } from '../EquipmentFilesList/index';
import { action, computed, observable } from 'mobx';
import { FormInstance } from 'antd/lib/form';
import { RcFile } from 'antd/lib/upload';
import { EquipmentFileModel } from '../../../../stores/equipment/model/EquipmentFileModel';
import moment, { Moment } from 'moment';
import filesize from 'filesize';
import { FormError } from '../../../../utils/error/FormError';
import { EquipmentFactoryModel } from '../../../../stores/equipment/model/EquipmentFactoryModel';
import { EquipmentModel } from '../../../../stores/equipment/model/EquipmentModel';
import { EquipmentEditModalStore } from '../../../../stores/equipment/EquipmentEditModalStore';
import { MomentDateFormat } from '../../../../utils/MomentDateFormat';

type FormParams = {
  name?: string;
  serialNumber?: string;
  factory?: EquipmentFactoryModel;
  deliveryDate?: Moment;
  leaseEndDate?: Moment;
  comment?: string;
  deviceUuid?: string;
}

interface Props {
  visible: boolean;
  onClose: () => void;
  id: number;
}

// noinspection DuplicatedCode
@observer
class EquipmentEditModal extends Component<Props> {
  @lazyInject(EquipmentEditModalStore)
  store!: EquipmentEditModalStore;

  @observable filesToUpload: EquipmentFileListItem[] = [];
  @observable loading: boolean = false;
  @observable error?: FormError;

  refForm = React.createRef<FormInstance>();

  @computed
  get model() {
    return this.store.itemsMap.get(this.props.id);
  }

  @computed
  get alreadyUploadedFiles() {
    return this.model?.files
      ?.map((f, key) => ({ key, ...f } as EquipmentFileListItem)) || [];
  }

  @computed
  get files() {
    return [...this.alreadyUploadedFiles, ...this.filesToUpload]
      .map((f, key) => ({ ...f, key }));
  }

  @action
  handleBeforeUpload = (file: RcFile) => {
    this.handleChange("files");

    const fileSizeInBytes = file.size;
    const maxFileSizeInBytes = 25 * 1024 * 1024;

    if (fileSizeInBytes > maxFileSizeInBytes) {
      notification.error({
        message: `Файл ${file.name} превышает макс. размер (${
          filesize(maxFileSizeInBytes, {  })
        })`
      });
      return false;
    }

    const maxFilesCount = 3;
    if (this.files.length >= maxFilesCount) {
      notification.error({
        message: `Файл ${file.name} не может быть добавлен (макс. количество файлов: ${maxFilesCount})`
      })
      return false;
    }

    this.filesToUpload = ([
      ...this.filesToUpload,
      {
        content: file,
        ...new EquipmentFileModel({
          creationDate: moment(),
          fileName: file.name,
          fileSize: file.size,
        }),
      },
    ]).map((f, key) => ({ key, ...f }));

    return false;
  };

  @action
  handleChange = (key: string) => {
    delete this.error?.errors[key];
  }

  @action
  handleDeleteItem = (key: number) => {
    const file = this.files[key];

    const isUploadedFile = !!(file.id);
    if (!isUploadedFile) {
      this.filesToUpload = this.filesToUpload.filter((file) =>
        file.key !== key - this.alreadyUploadedFiles.length);

      return;
    }

    this.store.deleteEquipmentFile(this.model!.id!, file.id!)
      .catch(() => this.handleDeleteEquipmentFileError(file));
  };

  @action.bound
  handleDeleteEquipmentFileError(file: EquipmentFileModel) {
    notification.error({ message: `Не удалось удалить файл (${file.fileName})` });
  }

  @action
  handleFileNameChange = (key: number, fileName: string) => {
    const file = this.files[key];

    const isUploadedFile = !!(file.id);
    if (!isUploadedFile) {
      this.files[key].fileName = fileName;
      return;
    }

    this.store.renameEquipmentFile(this.model!.id!, file.id!, fileName)
      .catch(() => this.handleRenameEquipmentFileError(file));
  };

  @action.bound
  handleRenameEquipmentFileError(file: EquipmentFileModel) {
    notification.error({ message: `Не удалось переименовать файл (${file.fileName})` });
  }

  @action
  handleSave = () => {
    const value = this.refForm.current?.getFieldsValue() as FormParams;
    if (!value) {
      return
    }

    const id = this.model!.id!;
    const { name = '', serialNumber = '', factory, deliveryDate, leaseEndDate, comment = '',
      deviceUuid = undefined } = value;
    const model = new EquipmentModel({
      name,
      serialNumber,
      factory,
      deliveryDate: deliveryDate ? deliveryDate.set({hour: 0, minute: 0, seconds: 0, milliseconds: 0}) : undefined,
      leaseEndDate: leaseEndDate ? leaseEndDate.set({hour: 23, minute: 59, seconds: 59, milliseconds: 999}) : undefined,
      comment,
      deviceUuid,
    });

    const files = this.filesToUpload.map(f => ({ name: f.fileName, content: f.content! }));

    this.loading = true;

    if (files.length < 1) {
      this.store.updateEquipment(id, model)
        .then(this.handleSaveSuccess)
        .catch(this.handleSaveError);

      return;
    }

    this.store.uploadEquipmentFiles(id, files)
      .then(() => this.handleUploadFilesSuccess(id, model))
      .catch(this.handleUploadFilesError);
  };

  @action.bound
  handleUploadFilesSuccess(id: number, model: EquipmentModel) {
    this.filesToUpload = [];

    this.store.updateEquipment(id, model)
      .then(this.handleSaveSuccess)
      .catch(this.handleSaveError);
  }

  @action.bound
  handleUploadFilesError() {
    notification.error({ message: 'Не удалось загрузить файлы' });
    this.loading = false;
  }

  @action.bound
  handleSaveSuccess() {
    this.filesToUpload = [];
    this.loading = false;
    this.refForm.current?.resetFields();

    notification.success({ message: `Оборудование успешно сохранено` });
    this.props.onClose();
  }

  @action.bound
  handleSaveError(error: FormError) {
    this.error = error;
    this.loading = false;

    if(!(error instanceof FormError)) {
      console.log(error);
      let err = 'Не удалось сохранить оборудование';
      let e = error as any;
      console.log(e);
      if(e.data?.message) {
        err = err + ": " + e.data?.message;
      }
      notification.error({ message: err});
    }
  }

  @action
  handleCancel = () => {
    this.filesToUpload = [];
    this.loading = false;
    this.refForm.current?.resetFields();

    this.props.onClose();
  }

  render() {
    const { visible } = this.props;
    const { model, files } = this;

    const hasNameError = !!(this.error?.errors?.name);
    const hasFactoryError = !!(this.error?.errors?.factoryId);
    const hasSerialNumberError = !!(this.error?.errors?.serialNumber);
    const hasDeliveryDateError = !!(this.error?.errors?.deliveryDate);
    const hasLeaseEndDateError = !!(this.error?.errors?.leaseEndDate);
    const hasCommentError = !!(this.error?.errors?.comment);
    const hasFilesError = !!(this.error?.errors?.files);
    const hasDeviceUuidError = !!(this.error?.errors?.deviceUuid);

    return (
      <Modal
        title="Изменение оборудования"
        visible={visible}
        width="1000px" centered
        onCancel={this.handleCancel}
        footer={[
          <Button
            key="back"
            onClick={this.handleCancel}
          >
            Отмена
          </Button>,

          <Button
            key="submit"
            type="primary"
            disabled={this.loading}
            loading={this.loading}
            onClick={this.handleSave}
          >
            Сохранить
          </Button>,
        ]}
      >
        <Form ref={this.refForm} layout="vertical">
          <Row gutter={[16, 16]}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item
                label="Название"
                name="name"
                key="name"

                hasFeedback={hasNameError}
                validateStatus={hasNameError ? 'error' : undefined}
                help={this.error?.errors?.name}

                initialValue={model?.name}
              >
                <Input
                  placeholder="Введите название оборудования"
                  onChange={() => this.handleChange("name")}
                />
              </Form.Item>

              <Form.Item
                label="Серийный номер"
                name="serialNumber"
                key="serialNumber"

                hasFeedback={hasSerialNumberError}
                validateStatus={hasSerialNumberError ? 'error' : undefined}
                help={this.error?.errors?.serialNumber}

                initialValue={model?.serialNumber}
              >
                <Input
                  placeholder="Введите серийный номер"
                  onChange={() => this.handleChange("serialNumber")}
                />
              </Form.Item>

              <Form.Item
                label="Завод"
                name="factory"
                key="factory"

                hasFeedback={hasFactoryError}
                validateStatus={hasFactoryError ? 'error' : undefined}
                help={this.error?.errors?.factoryId}
              >
                <FactoryAutoCompleteSelect
                  initialValue={model?.factory ? {
                    id: model.factory.id as string,
                    name: model.factory.name as string,
                  } : undefined}

                  onChange={() => this.handleChange("factoryId")}
                  allowClear
                />
              </Form.Item>

              <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                  <Form.Item
                    label="Дата поставки"
                    key="deliveryDate"
                    name="deliveryDate"

                    hasFeedback={hasDeliveryDateError}
                    validateStatus={hasDeliveryDateError ? 'error' : undefined}
                    help={this.error?.errors?.deliveryDate}

                    initialValue={model?.deliveryDate}
                  >
                    <DatePicker
                      placeholder="Выберите дату"
                      format={MomentDateFormat}
                      className={styles.datepicker}
                      onChange={() => this.handleChange("deliveryDate")}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={12}>
                  <Form.Item
                    label="Дата окончания аренды"
                    key="leaseEndDate"
                    name="leaseEndDate"

                    hasFeedback={hasLeaseEndDateError}
                    validateStatus={hasLeaseEndDateError ? 'error' : undefined}
                    help={this.error?.errors?.leaseEndDate}

                    initialValue={model?.leaseEndDate}
                  >
                    <DatePicker
                      placeholder="Выберите дату"
                      format={MomentDateFormat}
                      className={styles.datepicker}
                      onChange={() => this.handleChange("leaseEndDate")}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                label="UUID устройства эмиссии кодов"
                key="deviceUuid"
                name="deviceUuid"

                hasFeedback={hasDeviceUuidError}
                validateStatus={hasDeviceUuidError ? 'error' : undefined}
                help={this.error?.errors?.deviceUuid}

                initialValue={model?.deviceUuid}
              >
                <Input
                  placeholder="Введите UUID устройства эмиссии кодов"
                  onChange={() => this.handleChange("deviceUuid")}
                />
              </Form.Item>

              <Form.Item
                label="Комментарий"
                key="comment"
                name="comment"

                hasFeedback={hasCommentError}
                validateStatus={hasCommentError ? 'error' : undefined}
                help={this.error?.errors?.comment}

                initialValue={model?.comment}
              >
                <Input.TextArea
                  placeholder="Комментарий..."
                  autoSize={{ minRows: 6 }}
                  onChange={() => this.handleChange("comment")}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item
                label="Прикрепленные файлы"
                key="files"

                hasFeedback={hasFilesError}
                validateStatus={hasFilesError ? 'error' : undefined}
                help={this.error?.errors?.files}
              >
                <Upload.Dragger
                  multiple
                  beforeUpload={this.handleBeforeUpload}
                  showUploadList={false}
                  disabled={files.length >= 3}
                >
                  <EquipmentUploadContent />
                </Upload.Dragger>

                <EquipmentFilesList
                  id={this.props.id}
                  files={files}
                  isEditable
                  onDeleteItem={this.handleDeleteItem}
                  onFileNameChange={this.handleFileNameChange}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default EquipmentEditModal;
