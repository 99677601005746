import React, { Component } from 'react';
import { Button, Modal, notification } from 'antd';
import { observer } from 'mobx-react';
import { lazyInject } from '../../../../../utils/IoC';
import { FormInstance } from 'antd/lib/form';
import { action, observable } from 'mobx';
import { FormError } from '../../../../../utils/error/FormError';
import FactoryPriceModalForm, { FactoryPriceModalFormValue } from '../FactoryPriceModalForm/index';
import { FactoryPriceModel } from '../../../../../stores/finances/factories-prices/model/FactoryPriceModel';
import { FactoryPriceCreateModalStore } from '../../../../../stores/finances/factories-prices/FactoryPriceCreateModalStore';

interface Props {
  visible: boolean;
  onClose: () => void;
}

@observer
class FactoryPriceCreateModal extends Component<Props> {
  @lazyInject(FactoryPriceCreateModalStore)
  store!: FactoryPriceCreateModalStore;

  formRef = React.createRef<FormInstance>();

  @observable loading: boolean = false;
  @observable error?: FormError;

  @action
  resetError = (key: string) => {
    delete this.error?.errors[key];
  }

  handleCancel = () => {
    this.props.onClose();
  };

  @action
  handleCreate = () => {
    const value = this.formRef.current?.getFieldsValue() as FactoryPriceModalFormValue | undefined;
    if (!value) {
      return;
    }

    const { factory, price, startDate, endDate } = value;

    this.loading = true;
    this.store.createFactoryPrice({
      factoryId: factory?.id,
      priceId: price?.id,
      startDate: startDate ? startDate.toDate().getTime() : undefined,
      endDate: endDate ? endDate.toDate().getTime() : undefined,
    }).then(this.handleCreateSuccess).catch(this.handleCreateError);
  };

  @action.bound
  handleCreateSuccess(model: FactoryPriceModel) {
    this.loading = false;
    this.formRef.current?.resetFields();

    notification.success({ message: `Тариф успешно добавлен к заводу` });

    this.props.onClose();
  }

  @action.bound
  handleCreateError(error: FormError | Error) {
    if (error instanceof FormError) {
      this.error = error;
    } else {
      notification.error({ message: error.message });
    }

    this.loading = false;
  }

  render() {
    const { visible } = this.props;

    return (
      <Modal
        title='Установка тарифа для завода'
        visible={visible}
        centered
        onCancel={this.handleCancel}

        footer={[
          <Button
            key="back"
            onClick={this.handleCancel}
          >
            Отмена
          </Button>,

          <Button
            key="submit"
            type="primary"
            disabled={this.loading}
            loading={this.loading}
            onClick={this.handleCreate}
          >
            Сохранить
          </Button>,
        ]}
      >
       <FactoryPriceModalForm
         formRef={this.formRef}
         error={this.error}
         onResetError={this.resetError}
       />
      </Modal>
    );
  }
}

export default FactoryPriceCreateModal;
