import React, { Component } from 'react';
import { Button, Col,  Form,  Modal, notification, Row, Select, } from 'antd';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { FormInstance } from 'antd/lib/form';
import { FormError } from '../../../../utils/error/FormError';
import axios from 'axios';
import TextArea from 'antd/lib/input/TextArea';
import { ACCESS_TOKEN_STORAGE_KEY } from '../../../../stores/auth/const/AuthTokensStorageKeys';

const api_url = process.env.API_SERVER_HOST

type FormParams = {
  id: string;
  bounding_codes_0: string;

  bounding_codes_1: string;
  type: string;
  number_tumb: string;
  joints: string;

}

interface Props {
  visible: boolean;
  onClose: () => void;
  id: string;
  factory: string;
}

// noinspection DuplicatedCode
@observer
class CablesViewModal extends Component<Props> {


  @observable loading: boolean = false;
  @observable error?: FormError;

  refForm = React.createRef<FormInstance>();
  
  state = {
    bounding_codes_0 : '',
    bounding_codes_1 : '',
    number_tumb : '',
    type :'',
    joints : [],
    load: false,
  }
  
  @action
  handleChange = (key: string) => {
    delete this.error?.errors[key];
  }


  @action
  handleSave = () => {
	const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) || undefined
    const value = this.refForm.current?.getFieldsValue() as FormParams;
    if (!value) {
      return
    }
    const { type = '', bounding_codes_0 = '', bounding_codes_1 = '',number_tumb = '', joints = '', } = value;
    const bounding_codes = [bounding_codes_0, bounding_codes_1]
    const product = {bounding_codes: bounding_codes,type: type, drum_number:number_tumb, joints: joints,  }
    this.loading = true;
    axios
    .put(api_url+'/v1/admin/product',{factory_id: this.props.factory, product_id:this.props.id, product: product}, {headers:{'Authorization':'Bearer ' + token}})
    .then(() =>(this.handleSaveSuccess()))
    .catch(() => (this.handleSaveError()))

  };



  @action.bound
  handleSaveSuccess() {
    this.loading = false;
    this.refForm.current?.resetFields();

    notification.success({ message: `Завод успешно сохранен` });
    this.props.onClose();
  }
  @action
  load(){
    const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) || undefined
    axios
    .get(api_url+'/v1/admin/product',{params:{factory_id: this.props.factory, product_id:this.props.id}, headers:{'Authorization':'Bearer ' + token}})
    .then(resp =>(

      
      this.setState({
          bounding_codes_0:resp.data.bounding_codes[0],

          bounding_codes_1:resp.data.bounding_codes[1],
          number_tumb:resp.data.drum_number,
          length:resp.data.length,
          type:resp.data.type,
          joints:resp.data.joints,
          load: true,
      }),
      this.refForm.current?.setFieldsValue(this.state),
      this.state.joints.map((item: string, i: number) => {
        return <Form.Item
        label="Список соединений"
        name={i}
        key={i}
        initialValue={item}
      >
        <TextArea
          placeholder="Список соединений"
          onChange={() => this.handleChange("joints")}
        />
      </Form.Item>
      })
      ));
  }

  @action.bound
  handleSaveError() {
    this.loading = false;
  }

  @action
  handleCancel = () => {
    this.loading = false;
    this.refForm.current?.resetFields();

    this.props.onClose();
  }

  render() {

    if (this.state.load === false){
      this.load()
    }

    const { visible } = this.props;

    return (
      <Modal
        title="Изменение изделия"
        visible={visible}
        width="600px" centered
        onCancel={this.handleCancel}
        footer={[
          <Button
            key="back"
            onClick={this.handleCancel}
          >
            Отмена
          </Button>,

          <Button
            key="submit"
            type="primary"
            disabled={this.loading}
            loading={this.loading}
            onClick={this.handleSave}
          >
            Сохранить
          </Button>,
        ]}
      >
                <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
            uuid
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
            
          </Col>
          </Row>

      </Modal>
    );
  }
}

export default CablesViewModal;
