import { observable } from 'mobx';
import { FactoryPriceFactoryResponseDTO } from '../dto/response/factory-price-factory.response.dto';

export class FactoryPriceFactoryModel {
  @observable id?: string;
  @observable name: string;

  constructor(data: FactoryPriceFactoryModel) {
    this.id = data.id;
    this.name = data.name;
  }

  static createFromDTO(dto: FactoryPriceFactoryResponseDTO) {
    return new FactoryPriceFactoryModel({
      id: dto.id,
      name: dto.name || 'Название не указано',
    });
  }
}
