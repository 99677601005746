import React, { Component } from 'react';
import { List } from 'antd';
import { EquipmentFileModel } from '../../../../stores/equipment/model/EquipmentFileModel';
import EquipmentFilesListItem from '../EquipmentFilesListItem/index';
import { observer } from 'mobx-react';
import { RcFile } from 'antd/lib/upload';

interface Props {
  id?: number;
  files: EquipmentFileListItem[];
  onDeleteItem?(key: number): void;
  onFileNameChange?(key: number, fileName: string): void;
  isEditable?: boolean;
}

export interface EquipmentFileListItem extends EquipmentFileModel {
  key: number;
  content?: RcFile;
}

@observer
class EquipmentFilesList extends Component<Props> {
  render() {
    const { id, isEditable = false, files, onDeleteItem } = this.props;
    const hasFiles = (files && files.length > 0);

    return (
      <>
        {hasFiles &&
          <List
            itemLayout="horizontal"
            dataSource={files}
            renderItem={(item) => (
              <EquipmentFilesListItem
                id={id}
                item={item}
                isEditable={isEditable}
                onDeleteItem={() => onDeleteItem && onDeleteItem(item.key)}
                onFileNameChange={this.props.onFileNameChange}
              />
            )}
          />
        }
      </>
    );
  }
}

export default EquipmentFilesList;
