import React, { Component } from 'react';
import { Moment } from 'moment';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { Button, Card, Col, notification, Row, Skeleton, Table } from 'antd';
import CableTableRowAction from './component/CableTableRowAction/index';
import { action, observable, toJS } from 'mobx';
import CablesCreateModal from './component/CableCreateModal/index';
import EquipmentEditModal from './component/CableEditModal';
import CablesViewModal from './component/CableViewModal/index';
import { MomentDateTimeTzFormat } from '../../utils/MomentDateFormat';

import { observer } from 'mobx-react';
import { getTableTextSearchDropdownProps } from '../../components/Table/TableTextSearchDropdown';
import { lazyInject } from '../../utils/IoC';
import { CablesTableStore } from '../../stores/factory/CablesTableStore';
import { CablesModel } from '../../stores/factory/model/CablesModel';
import { CategoryAccessStore } from '../../stores/users/CategoryAccessStore';
import { Redirect } from 'react-router';
import { getTableDateFilterDropdownProps } from '../../components/Table/TableDateFilterDropdown';
import { getTableStatusProductDropdownProps } from '../../components/Table/TableStatusProductDropdown';
import { getTableTypeCodeDropdownProps } from '../../components/Table/TableTypeCodeDropdown';
import { getTableFactoryFilterDropdownProps } from '../../components/Table/TableFactoryFilterDropdown';
@observer
class TableProduct extends Component {
  @lazyInject(CablesTableStore)
  store!: CablesTableStore;

  @lazyInject(CategoryAccessStore)
  categoryAccessStore!: CategoryAccessStore;

  @observable isModalVisible = { create: false, edit: false, view: false };
  @observable currentEditId = '';
  @observable currentEditFactory = '';

  componentDidMount() {
    this.store.fetchAll();
  }

  //region Handlers
  deleteRecord = (id: string) => {
    this.store.deleteItem(id).catch(() => notification.error({ message: 'Не удалось удалить оборудование' }));
    //this.render();
  };

  @action
  openCreateModal = () => {
    this.isModalVisible.create = true;
  };

  @action
  closeCreateModal = () => {
    this.isModalVisible.create = false;
  };

  @action
  openEditModal = (id: string, factory: string) => {
    this.currentEditId = id;
    this.currentEditFactory = factory;
    this.isModalVisible.edit = true;
  };

  @action
  openViewModal = (id: string, factory: string) => {
    this.currentEditId = id;
    this.currentEditFactory = factory;
    this.isModalVisible.edit = true;
  };
  @action
  closeEditModal = () => {
    this.isModalVisible.edit = false;
    this.currentEditId = '';
    this.currentEditFactory = '';
  };

  @action
  closeViewModal = () => {
    this.isModalVisible.edit = false;
    this.currentEditId = '';
    this.currentEditFactory = '';
  };

  @action
  handleDownloadXlsx = () => {
  	this.store.downloadXlsx("products_list.xlsx");
  };
  //endregion

  render() {
    if (!this.categoryAccessStore.loading && !this.categoryAccessStore.hasFactyoryAccess) {
      return <Redirect to='/' />;
    }
    const columns = [
	  {
	  	title: null,
		key: "index",
		render: (value: any, item: any, index: number) => {
			const p = this.store.tablePagination;
			return (<a href="#" onClick={() => this.openEditModal(item.id, item.factory)}>{p.pageSize * (p.current-1)+1+index}</a>);
		},
	  },
      {
        title: "Дата создания",
        dataIndex: "date_created",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["date_created"],
        filteredValue: (this.store.filters.date_created as string[]) || null,
        ...getTableDateFilterDropdownProps(),
		render: (date: Moment) => {
			return (
				<div className="editable-cell-value-wrap">
					{(date) ? date.format(MomentDateTimeTzFormat) : ""}
				</div>
		   );
		},
      },
/*      {
        title: "Время",
        dataIndex: "time_created",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["time_created"],
        filteredValue: (this.store.filters.time_created as string[]) || null,
      },*/

      {
        title: "Дата выпуска",
        dataIndex: "date_released",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["date_released"],
        filteredValue: (this.store.filters.date_released as string[]) || null,
        ...getTableDateFilterDropdownProps(),
		render: (date: Moment) => {
			return (
				<div className="editable-cell-value-wrap">
					{(date) ? date.format(MomentDateTimeTzFormat) : ""}
				</div>
		   );
		},
      },
/*      {
        title: "Время",
        dataIndex: "time_released",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["time_released"],
        filteredValue: (this.store.filters.time_released as string[]) || null,
      },*/

      {
        title: "Длина",
        dataIndex: "length",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["length"],
        filteredValue: (this.store.filters.length as string[]) || null,
        ...getTableTextSearchDropdownProps('Поиск по длине')
      },

      {
        title: "Кодов",
        dataIndex: "codes",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["codes"],
        filteredValue: (this.store.filters.codes as string[]) || null,
        ...getTableTextSearchDropdownProps('Поиск по количеству кодов')
      },

      {
        title: "Тип кода",
        dataIndex: "type",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["type"],
        filteredValue: (this.store.filters.type as string[]) || null,

        ...getTableTypeCodeDropdownProps()
      },
	  {
	  	title: "Завод",
		dataIndex: "factory_name",
		sorter: { multiple: -1 },
		sortOrder: this.store.tableAppliedSorters["factory"],
		filteredValue: (this.store.filters.factory_name as string[]) || null,
		...getTableFactoryFilterDropdownProps()
	  },
      {
        title: "Номер барабана",
        dataIndex: "drum_number",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["drum_number"],
        filteredValue: (this.store.filters.drum_number as string[]) || null,
        ...getTableTextSearchDropdownProps('Поиск по номеру барабана')
      },
      {
        title: "Оператор",
        dataIndex: "otk",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["otk"],
        filteredValue: (this.store.filters.otk as string[]) || null,
        ...getTableTextSearchDropdownProps("Поиск по оператору"),
      },
      {
        title: "Статус",
        dataIndex: "status",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["status"],
        filteredValue: (this.store.filters.status as string[]) || null,
        ...getTableStatusProductDropdownProps()
      },
      {
        title: "Действия",
        render: (text: string, record: CablesModel) => (
          <CableTableRowAction
            id={record.id as any}
            factory={record.factory as any}
            onEdit={(id, factory) => this.openEditModal(id as string, factory as string)}

            //onView={(id, factory) => this.openViewModal(id as string, factory as string)}
            //onDelete={(id) => this.deleteRecord(id as string)}
          />
        ),
      },
    ];

    return (
      <PageHeaderWrapper>
        <CablesCreateModal
          visible={this.isModalVisible.create}
          onClose={this.closeCreateModal}
        />

        {this.isModalVisible.edit && this.currentEditId !== '' &&
          <EquipmentEditModal
            visible={this.isModalVisible.edit}
            onClose={this.closeEditModal}
            id={this.currentEditId}
            factory={this.currentEditFactory}
          />
        }

        {this.isModalVisible.view && this.currentEditId !== '' &&

          <CablesViewModal
            visible={this.isModalVisible.view}
            onClose={this.closeEditModal}
            id={this.currentEditId}
            factory={this.currentEditFactory}
          />
        }

        <Card>
          <Row gutter={[16, 16]}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Button
                size="large"
                block
                type="primary"
                onClick={this.openCreateModal}
              >
                Добавить изделие
              </Button>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 5, offset: 6 }}
              lg={{ span: 4, offset: 8 }}
              xl={{ span: 4, offset: 8 }}
            >
              <Button block onClick={this.store.handleTableRefresh}>
                Обновить список
              </Button>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 5 }}
              lg={{ span: 4 }}
              xl={{ span: 4 }}
            >
              <Button block onClick={this.store.handleTableReset}>
                Сбросить фильтры
              </Button>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 5 }}
              lg={{ span: 4 }}
              xl={{ span: 2 }}
            >
              <Button block onClick={this.handleDownloadXlsx}>
			  	XLS
              </Button>
            </Col>
          </Row>
          <div style={{overflow: "auto"}}>
          <Table
            loading={this.store.loading}
            columns={columns}
            rowKey={(record) => record.id as string}
            dataSource={toJS(this.store.items)}

            pagination={{
              position: ["bottomRight"],
              showSizeChanger: true,
              ...this.store.tablePagination,
            }}
            locale={{
              emptyText: this.store.loading ? (
                <>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </>
              ) : (
                <p>Данные отсутствуют</p>
              ),
            }}
            onChange={this.store.handleTableChange}
            onRow={(record, rowIndex) => {
                return {
                    onDoubleClick: event => {
                      this.openEditModal(record.id as string, record.factory as unknown as string);
                    },
                };
            }}
          />
          </div>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default TableProduct;

