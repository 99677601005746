import { computed, observable } from 'mobx';
import { FactoryPriceFactoryModel } from './FactoryPriceFactoryModel';
import { FactoryPricePriceModel } from './FactoryPricePriceModel';
import { FactoryPriceResponseDTO } from '../dto/response/factory-price.response.dto';
import moment, { Moment } from 'moment';

interface Params {
  id?: string;
  factory: FactoryPriceFactoryModel;
  price: FactoryPricePriceModel;
  startDate: Moment;
  endDate: Moment;
}

export class FactoryPriceModel implements Params {
  @observable id?: string;
  @observable factory!: FactoryPriceFactoryModel;
  @observable price!: FactoryPricePriceModel;
  @observable startDate!: Moment;
  @observable endDate!: Moment;

  @computed
  get factoryName(): string {
    return this.factory.name;
  }

  @computed
  get priceName(): string {
    return this.price.name!;
  }

  @computed
  get datamatrix(): string {
    return this.price.datamatrix!;
  }

  @computed
  get barcode(): string {
    return this.price.barcode!;
  }

  constructor(model: Params) {
    this.id = model.id;
    this.factory = new FactoryPriceFactoryModel(model.factory);
    this.price = new FactoryPricePriceModel(model.price);
    this.startDate = model.startDate;
    this.endDate = model.endDate;
  }

  static createFromDTO(dto: FactoryPriceResponseDTO) {
    return new FactoryPriceModel({
      id: dto.id,
      factory: FactoryPriceFactoryModel.createFromDTO(dto.factory),
      price: FactoryPricePriceModel.createFromDTO(dto.price),
      startDate: moment(dto.startDate),
      endDate: moment(dto.endDate),
    });
  }
}
