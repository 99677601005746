export enum TypeCode {
  code128 = "code128",
  datamatrix = "datamatrix",
  multiset = "multiset",
}

export const TypeCodeName: Record<TypeCode, string> = {
  [TypeCode.code128]: 'Code128',
  [TypeCode.datamatrix]: 'DataMatrix',
  [TypeCode.multiset]: "Multiset",
};

export function getTypeCodeName(type?: TypeCode) {
  return type ? TypeCodeName[type] : type;
}
