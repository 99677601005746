import React, { Component, useState } from 'react';
import TableButtonFiltersDropdown from './TableButtonFiltersDropdown';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { StatusPrinterController } from '../../stores/equipment/const/status-printer-controller.enum';
import PrinterControllerStatusSelect from '../PrinterControllerStatusSelect';
import TableButtonStatusFiltersDropdown from './TableButtonStatusFiltersDropdown';

interface Props extends FilterDropdownProps {
	placeholder?: string;
}

export const getTableStatusPrinterControllerFilterDropdownProps = () => ({
	filterDropdown: (props: FilterDropdownProps) => (
		<TableStatusPrinterControllerFilterDropdown {...props} />
	),
});

class TableStatusPrinterControllerFilterDropdown extends Component<Props> {
	handleChange = (status: StatusPrinterController) => {
		this.props.setSelectedKeys(
			!status ? [] : [(status as unknown) as string]
		);
	};

	render() {
		const { clearFilters, confirm } = this.props;

		return (
			<div >
			<div style={{ display: "flex", padding: '8px' }}>
				<PrinterControllerStatusSelect
					mode="multiple"
					placeholder='Выберите статус'
					style={{ minWidth: '300px', width: "100%", marginBottom: "8px", marginRight: '8px' }}
					onChange={this.handleChange}
					allowClear
				/>

				<TableButtonStatusFiltersDropdown
					handleSearch={confirm}
				/>
			</div>
			</div>
		);
	}
}

export default TableStatusPrinterControllerFilterDropdown;
