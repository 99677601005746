import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { FormInstance } from 'antd/lib/form';
import { observer } from 'mobx-react';
import { lazyInject } from '../../utils/IoC';
import { RibbonImportTableStore } from '../../stores/factory/RibbonImportTableStore';
import { CategoryAccessStore } from '../../stores/users/CategoryAccessStore';
import { Redirect } from 'react-router';
import { getTableTextSearchDropdownProps } from '../../components/Table/TableTextSearchDropdown';
import { Button, Card, Col, notification, Row, Skeleton, Table } from 'antd';
import { action, observable, toJS } from 'mobx';
import { RibbonImportModel } from '../../stores/factory/model/RibbonImportModel';
import EquipmentTableRowAction from './component/FactoryTableRowAction/index';
import RibbonImportCreateModal from './component/RibbonImportCreateModal/index';
import { HttpClientV1TwoApi } from '../../utils/api/HttpClientV1twoApi';

@observer
class RibbonImportList extends Component {
  @lazyInject(RibbonImportTableStore)
  store!: RibbonImportTableStore;

  @lazyInject(HttpClientV1TwoApi)
  protected readonly httpClientV1!: HttpClientV1TwoApi;

  @lazyInject(CategoryAccessStore)
  categoryAccessStore!: CategoryAccessStore;

  @observable isModalVisible = { create: false, edit: false };
  @observable currentEditId = '';
  @observable option = [];

  @observable allCabel = 0;

  @observable countCode = 0;
  @observable printCode = 0;

  refForm = React.createRef<FormInstance>();

  componentDidMount() {
    this.store.fetchAll();
  }

  //region Handlers
  deleteRecord = (id: string) => {
    this.store.deleteItem(id).catch(() => notification.error({ message: 'Не удалось удалить' }));
  };

  @action
  openCreateModal = () => {
    this.isModalVisible.create = true;
  };

  @action
  closeCreateModal = () => {
    this.isModalVisible.create = false;
  };

  @action
  openEditModal = (id: string) => {
    this.currentEditId = id;
    this.isModalVisible.edit = true;

    this.httpClientV1.post<{}, {}>(
      `/ribbon/import/${id}/retry`,
      {},
      {}
    ).then((res) => {
      console.log("then:");
      console.log(res);
    }).catch((res) => {
      console.log("catch:");
      console.log(res);
    });
  };

  @action
  closeEditModal = () => {
    this.isModalVisible.edit = false;
    this.currentEditId = '';
  };
  //endregion

  state = {
  };

  render() {
    if (!this.categoryAccessStore.loading && !this.categoryAccessStore.hasFactyoryAccess) {
      return <Redirect to='/' />;
    }

    const columns = [
      {
        title: null,
        key: "index",
        render: (value: any, item: any, index: number) => {
            const p = this.store.tablePagination;
            return (<span>{p.pageSize * (p.current-1)+1+index}</span>);
        },
      },
      {
        title: "Дата",
        dataIndex: "insert_date",
        key: "insert_date",
        filteredValue: (this.store.filters.insert_time as string[]) || null,
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["insert_date"],
      },
      {
        title: "Дата",
        dataIndex: "insert_time",
        key: "insert_time",
        filteredValue: (this.store.filters.insert_time as string[]) || null,
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["insert_time"],
      },
      {
        title: "Пользователь",
        dataIndex: "user",
        key: 'user',
        filteredValue: (this.store.filters.user as string[]) || null,
        ...getTableTextSearchDropdownProps("Пользователь"),
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["user"],
       
      },
      {
        title: "Состояние",
        dataIndex: "status",
        key: "status",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["status"],
        filteredValue: (this.store.filters.status as string[]) || null,
        ...getTableTextSearchDropdownProps("Состояние"),
      },
      {
        title: "№ лент",
        dataIndex: "num_ribbons",
        key: "num_ribbons",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["status"],
        filteredValue: (this.store.filters.status as string[]) || null,
      },
      {
        title: "№ кодов",
        dataIndex: "num_codes",
        key: "num_codes",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["status"],
        filteredValue: (this.store.filters.status as string[]) || null,
      },
      {
        title: "Действия",
        render: (text: string, record: RibbonImportModel) => (
          <EquipmentTableRowAction
            id={record.id as any}
            onEdit={(id) => this.openEditModal(id as string)}
            onDelete={(id) => this.deleteRecord(id as string)}
          />
        ),
      },
    ];

    return (
      <PageHeaderWrapper>
        <RibbonImportCreateModal
          visible={this.isModalVisible.create}
          onClose={this.closeCreateModal}
        />

        <Card>
          <Row gutter={[16, 16]}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Button
                size="large"
                block
                type="primary"
                onClick={this.openCreateModal}
              >
                Загрузить ленты из файла
              </Button>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 5, offset: 6 }}
              lg={{ span: 4, offset: 8 }}
              xl={{ span: 4, offset: 8 }}
            >
              <Button block onClick={this.store.handleTableRefresh}>
                Обновить список
              </Button>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 5 }}
              lg={{ span: 4 }}
              xl={{ span: 4 }}
            >
              <Button block onClick={this.store.handleTableReset}>
                Сбросить фильтры
              </Button>
            </Col>

			<Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 5 }}
              lg={{ span: 4 }}
              xl={{ span: 2 }}
            >
			</Col>
          </Row>
          <Table
            loading={this.store.loading}
            columns={columns}
            rowKey={(record) => record.id as string}
            dataSource={toJS(this.store.items)}
            
            pagination={{
              position: ["bottomRight"],
              showSizeChanger: true,
              ...this.store.tablePagination,
            }}
            locale={{
              emptyText: this.store.loading ? (
                <>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </>
              ) : (
                <p>Данные отсутствуют</p>
              ),
            }}
            onChange={this.store.handleTableChange}
          />
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default RibbonImportList;
