import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { Button, Card, Col, DatePicker, notification, Row, Form, Select } from 'antd';
import { action, observable} from 'mobx';
import EquipmentCreateModal from './component/FactoryCreateModal/index';
import EquipmentEditModal from './component/FactoryEditModal/index';

import { FormInstance } from 'antd/lib/form';
import FactoryAutoCompleteSelect from '../../components/AutoCompleteSelect/FactoryAutoCompleteSelect/index';
import { observer } from 'mobx-react';
import { lazyInject } from '../../utils/IoC';
import { FactoryTableStore } from '../../stores/factory/FactoryTableStore';
import { CategoryAccessStore } from '../../stores/users/CategoryAccessStore';
import { Redirect } from 'react-router';
import Chart from 'react-apexcharts';
import styles from './index.module.less';
import axios from 'axios';
import moment from 'moment';
import { ACCESS_TOKEN_STORAGE_KEY } from '../../stores/auth/const/AuthTokensStorageKeys';
import TableFactoryFilterDropdown from '../../components/Table/TableFactoryFilterDropdown';
export const MomentDateFormat = 'DD.MM.YYYY';

// colour palette for graphs, to make every graph look distinct
const graph_colour_palette = [
	"#000000", "#aa0000", "#005500", "#aa5500", "#00aa00", "#aaaa00", "#00ff00", "#aaff00",
	"#00007f", "#aa007f", "#00557f", "#aa557f", "#00aa7f", "#aaaa7f", "#00ff7f", "#aaff7f",
	"#0000ff", "#aa00ff", "#0055ff", "#aa55ff", "#00aaff", "#aaaaff", "#00ffff", "#aaffff",
	"#550000", "#ff0000", "#555500", "#ff5500", "#55aa00", "#ffaa00", "#55ff00", "#ffff00",
	"#55007f", "#ff007f", "#55557f", "#ff557f", "#55aa7f", "#ffaa7f", "#55ff7f", "#ffff7f",
	"#5500ff", "#ff00ff", "#5555ff", "#ff55ff", "#55aaff", "#ffaaff", "#55ffff",
];

const api_url = process.env.REACT_APP_API_SERVER_HOST
const { Option } = Select;
@observer
class FactoryStatPage extends Component {
  @lazyInject(FactoryTableStore)
  store!: FactoryTableStore;

  @lazyInject(CategoryAccessStore)
  categoryAccessStore!: CategoryAccessStore;

  @observable isModalVisible = { create: false, edit: false };
  @observable currentEditId = '';
  @observable option = [];

  @observable values = [];

  @observable allCabel = 0;

  @observable countCode = 0;
  @observable printCode = 0;


  refForm = React.createRef<FormInstance>();
  componentDidMount() {
    this.store.fetchAll();
	this.loadDataChart();
  }

  //region Handlers
  deleteRecord = (id: string) => {
    this.store.deleteItem(id).catch(() => notification.error({ message: 'Не удалось удалить оборудование' }));
  };

  @action
  openCreateModal = () => {
    this.isModalVisible.create = true;
  };

  @action
  closeCreateModal = () => {
    this.isModalVisible.create = false;
  };
  @action
  loadDataChart = () => {
    this.data_printer = []
    this.data_ribbon = []

    this.value_printer = []
    this.value_ribbon = []

    const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) || undefined
    this.cat_printer = []
    this.cat_ribbon = []
    const value = this.refForm.current?.getFieldsValue();
	const factory_ids = value.id?.map((i: any) => (i.id as string));
    const start_time = value.start_time.utc().format('YYYY-MM-DD')
    const end_time = value.end_time.utc().format('YYYY-MM-DD')
	const group_by_factories: boolean = (value.sum_factories == "no");

    axios
    .get(api_url+'/v1/admin/factory/stats_summary',{params:{factories: factory_ids, date_start: start_time, date_end: end_time} , headers:{'Authorization':'Bearer ' + token}})
    .then(resp =>(this.allCabel=resp.data.products_released, this.countCode=resp.data.ribbon_codes_used, this.printCode=resp.data.printer_codes_used));

    axios
      .get(api_url+'/v1/admin/factory/codes_stats', { params: { factories: factory_ids, date_start: start_time, date_end: end_time, timeframe: value.period, group_by_factories }, headers: { 'Authorization': 'Bearer ' + token } })
      .then((resp) => {

		const types: string[] = ["ribbon", "printer"];
		const types_map: any = {};
		types.forEach((t) => { types_map[t] = null; });

		const series_name_from_type: any = {
			"ribbon": "лента",
			"printer": "принтер",
		};
		let factory_names: any = {};
		let series: any = {};
		// x-axis - dates
		let axis: any = {};
		let points: any = {};	// factory_id -> type -> time -> value
		resp.data.data.forEach((v: any) => {
			if(!(v.type in types_map)) { return; }
			v.times.forEach((t: string) => {
				axis[t] = null;
			});

			if(!(v.factory_id in factory_names)) {
				factory_names[v.factory_id] = v.factory_name;
			}
			if(!(v.factory_id in points)) {
				points[v.factory_id] = {
					printer: {},
					ribbon: {},
				};
				series[v.factory_id] = {};
				types.forEach((t) => {
					series[v.factory_id][t] = {
						name: (v.factory_id != 0)
							? v.factory_name + ": " + series_name_from_type[t]
							: series_name_from_type[t],
						data: [],
					};
				});
			}

			for(let i = 0; i < v.times.length; ++i) {
				points[v.factory_id][v.type][v.times[i]] = v.values[i];
			}
		});

		let categories: string[] = [];
		let used_series: any = {};
		Object.keys(axis).sort().forEach((i) => {
			categories.push(i.toString().slice(0, 10));
			Object.keys(points).forEach((f) => {
				types.forEach((t) => {
					const v = points[f][t][i] ?? 0;
					series[f][t].data.push(v);
					if(v != 0) {
						used_series[""+f+"_"+t] = series[f][t];
					}
				});
			});
		});
		axis = undefined;

		// rotate series to graph_series
		let graph_series: any = [];
		graph_series = Object.values(used_series);
		series = undefined;
		used_series = undefined;

        this.setState({
          options: {
            ...this.state.options,
            xaxis: {
              categories,
            }
          },
          series: graph_series,
		  colors: graph_colour_palette,
        })
      });
  };

  @action
  closeEditModal = () => {
    this.isModalVisible.edit = false;
    this.currentEditId = '';
  };
  //endregion
  data_printer = new Array()
  data_ribbon = new Array()

  value_printer = new Array()
  value_ribbon = new Array()

  cat_printer = new Array()
  cat_ribbon = new Array()
  state = {
    factory_id: '',
    start_date: '',
    end_date: '',
    period: 'day',
    options: {
      classNames: styles.datepicker,
      chart: {
        id: "basic-bar"
      },
      xaxis: {
        categories: this.cat_printer
      }
    },
    series: [
      {
        name: "Принтер",
        data: this.value_printer
      },

      {
        name: "Лента",
        data: this.value_ribbon
      }
    ], colors: ['##6C5DD3'],
	sum_factories: "no",
  };
  render() {
    if (!this.categoryAccessStore.loading && !this.categoryAccessStore.hasFactyoryAccess) {

      return <Redirect to='/' />;

    }


    let today = moment()
    let start_time= moment().add(-30,'days');

    return (
      <PageHeaderWrapper>
        <EquipmentCreateModal
          visible={this.isModalVisible.create}
          onClose={this.closeCreateModal}
        />

        {this.isModalVisible.edit && this.currentEditId !== '' &&
          <EquipmentEditModal
            visible={this.isModalVisible.edit}
            onClose={this.closeEditModal}
            id={this.currentEditId}
          />
        }

        <Card>

          <Form ref={this.refForm} layout="vertical">
            <Row gutter={[16, 16]}>
              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Form.Item
                  label="Завод"
                  name="id"
                  key="id"
				  rules={[{ type: 'array' }]}
				  required
                  >
                  <FactoryAutoCompleteSelect
                    allowClear
					mode="multiple"
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 8 }}
                sm={{ span: 8 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                xl={{ span: 4 }}
              >
                <Form.Item

                  label="Дата начала"
                  name="start_time"
                  key="start_time"
                  initialValue={start_time}>
                  <DatePicker
                    placeholder="Выберите дату"
                    format={MomentDateFormat}
                    className={styles.datepicker}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 8 }}
                sm={{ span: 8 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                xl={{ span: 4 }}
              >
                <Form.Item
                  label="Дата окончания"
                  name="end_time"
                  key="end_time"
                  initialValue={today}>
                  <DatePicker
                    placeholder="Выберите дату"
                    format={MomentDateFormat}
                    className={styles.datepicker}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 8 }}
                sm={{ span: 8 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                xl={{ span: 4 }}
              >
                <Form.Item
                  label="Период"
                  name="period"
                  key="period"
                  initialValue={this.state.period}>
                  <Select
                    placeholder="Период"
                    value={this.state.period}

                  >
                    <Option value="day" >День</Option>
					<Option value="week">Неделя</Option>
                    <Option value="month">Месяц</Option>
                    <Option value="year">Год</Option>
                  </Select></Form.Item>
              </Col>

              <Col
                xs={{ span: 8 }}
                sm={{ span: 8 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                xl={{ span: 4 }}
              >
                <Form.Item
                  label="Суммировать заводы"
                  name="sum_factories"
                  key="sum_factories"
                  initialValue={this.state.sum_factories}>
				  <Select
                    placeholder="Суммировать заводы"
                    value={this.state.sum_factories}
                  >
                    <Option value="yes" >Суммировать</Option>
					<Option value="no">Не суммировать</Option>
                  </Select>
                  </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Button
                  size="large"
                  block
                  type="primary"
                  onClick={this.loadDataChart}
                >
                  Показать
              </Button>
              </Col>
            </Row>
          </Form>
          <Row gutter={[16, 16]}>
            <Col
              xs={{ span:  24}}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
            >
              <Chart options={this.state.options} series={this.state.series} type="line" height="600px" />
            </Col>
            <Col
            xs={{ span: 24 }}
            sm={{ span: 24}}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            style={{paddingLeft:50}}
          >
          
            <span><h1 style={{ fontSize: 50, marginBottom:-15}}>{this.allCabel}</h1>Всего выпущено кабеля</span>
            <span><h1 style={{ fontSize: 50, marginBottom:-15}}>{this.countCode}</h1>Количество используемых кодов на ленте</span>
            <span><h1 style={{ fontSize: 50, marginBottom:-15}}>{this.printCode}</h1>Количество кодов, напечатаных на принтере</span>

            </Col>
          </Row>
        </Card>
      </PageHeaderWrapper>
    );
  }
  handleChange = (event: Event) => {
//    console.log(event.target)
  }
}

export default FactoryStatPage;
