import React, { Component } from 'react';
import { Divider, Popconfirm } from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { EditOutlined } from '@ant-design/icons/lib';

interface Props {
  id: number | string;

  factory: number | string;
  onEdit?: (id: number | string,factory: number | string) => void;

  onView?: (id: number | string,factory: number | string) => void;
  onDelete?: (id: number | string) => void;
}

class CableTableRowAction extends Component<Props> {
  render() {
    const { id,factory, onEdit, onView,onDelete } = this.props;

    return (
      <>
        <EditOutlined onClick={() => onEdit && onEdit(id, factory)} />

      </>

/*        <Divider type="vertical" />
        <Popconfirm
          title="Вы действительно хотите удалить запись?"
          cancelText="Отмена"
          okText="Подтвердить"
          onConfirm={() => onDelete && onDelete(id)}
        >
          <DeleteOutlined />
        </Popconfirm>*/

    );
  }
}

export default CableTableRowAction;
