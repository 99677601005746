import React, { Component } from 'react';
import { Button, Col, Form, Input, Modal, notification, Row} from 'antd';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { FormInstance } from 'antd/lib/form';
import { lazyInject } from '../../../../utils/IoC';
import { FactoryModel } from '../../../../stores/factory/model/FactoryModel';
import { FormError } from '../../../../utils/error/FormError';

import { FactoryCreateModalStore } from '../../../../stores/factory/FactoryCreateModalStore';

type FormParams = {
  name?: string;
  fullName?: string;
  country?: string;
  city?: string;
  address?: string;
  otk?: string;
}

interface Props {
  visible: boolean;
  onClose: () => void;
  id?: number;
}

// noinspection DuplicatedCode
@observer
class FactoryCreateModal extends Component<Props> {
  @lazyInject(FactoryCreateModalStore)
  store!: FactoryCreateModalStore;

  @observable loading: boolean = false;
  @observable error?: FormError;

  refForm = React.createRef<FormInstance>();


  @action
  handleChange = (key: string) => {
    delete this.error?.errors[key];
  }

  @action
  handleCreate = () => {
    const value = this.refForm.current?.getFieldsValue() as FormParams;
    if (!value) {
      return
    }

    const { name = '', fullName = '', country = '', city = '', address = '', otk = '' } = value;
    const model = new FactoryModel({
      name,
      fullName,
      country,
      city,
      address,
      otk,

    });

    this.loading = true;
    this.store.createFactory(
      model
    ).then(this.handleCreateSuccess).catch(this.handleCreateError);
  };

  @action.bound
  handleCreateSuccess(model: FactoryModel) {
    this.loading = false;
    this.refForm.current?.resetFields();

    notification.success({ message: `Успешно добавлен завод (id: ${model.id})` });

    this.props.onClose();
  }

  @action.bound
  handleCreateError(error: FormError) {
    this.loading = false;
    this.error = error;
  }

  @action
  handleCancel = () => {
    

    this.props.onClose();
  };

  render() {
    const { visible } = this.props;

    const hasNameError = !!(this.error?.errors?.name);
    const hasFullNameError = !!(this.error?.errors?.fullName);
    const hasCountryError = !!(this.error?.errors?.country);
    const hasCityError = !!(this.error?.errors?.city);
    const hasAddressError = !!(this.error?.errors?.address);
    const hasOTKError = !!(this.error?.errors?.otk);

    return (
      <Modal
        title="Добавление завода"
        visible={visible}
        width="500px" centered
        onCancel={this.handleCancel}
        footer={[
          <Button
            key="back"
            onClick={this.handleCancel}
          >
            Отмена
          </Button>,

          <Button
            key="submit"
            type="primary"
            disabled={this.loading}
            loading={this.loading}
            onClick={this.handleCreate}
          >
            Добавить
          </Button>,
        ]}
      >
        <Form ref={this.refForm} layout="vertical">
          <Row gutter={[16, 16]}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
              <Form.Item
                label="Название"
                name="name"
                key="name"

                hasFeedback={hasNameError}
                validateStatus={hasNameError ? 'error' : undefined}
                help={this.error?.errors?.name}

              >
                <Input
                  placeholder="Введите название завода"
                  onChange={() => this.handleChange("name")}
                />
              </Form.Item>

              <Form.Item
                label="Полное название"
                name="fullName"
                key="fullName"

                hasFeedback={hasFullNameError}
                validateStatus={hasFullNameError ? 'error' : undefined}
                help={this.error?.errors?.fullName}
              >
                <Input
                  placeholder="Введите полное наименование завода"
                  onChange={() => this.handleChange("fullName")}
                />
              </Form.Item>

              <Form.Item
                label="Страна"
                name="country"
                key="country"

/*                hasFeedback={hasCountryError}
                validateStatus={hasCountryError ? 'error' : undefined}
                help={this.error?.errors?.country}*/
              >
                <Input
                  placeholder="Введите страну"
                  onChange={() => this.handleChange("country")}
                />
              </Form.Item>

              <Form.Item
                label="Город"
                name="city"
                key="city"

/*                hasFeedback={hasCityError}
                validateStatus={hasCityError ? 'error' : undefined}
                help={this.error?.errors?.city}*/
              >
                <Input
                  placeholder="Введите название города"
                  onChange={() => this.handleChange("city")}
                />
              </Form.Item>

              <Form.Item
                label="Адрес"
                name="address"
                key="address"

/*                hasFeedback={hasAddressError}
                validateStatus={hasAddressError ? 'error' : undefined}
                help={this.error?.errors?.address}*/
              >
                <Input
                  placeholder="Введите адрес завода"
                  onChange={() => this.handleChange("address")}
                />
              </Form.Item>

              <Form.Item
                label="Начальник ОТК"
                name="otk"
                key="otk"

/*                hasFeedback={hasOTKError}
                validateStatus={hasOTKError ? 'error' : undefined}
                help={this.error?.errors?.otk}*/
              >
                <Input
                  placeholder="Введите ФИО начальника ОТК"
                  onChange={() => this.handleChange("otk")}
                />
              </Form.Item>
              
            </Col>
            
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default FactoryCreateModal;
