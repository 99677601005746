import { lazyInject, provide } from '../../utils/IoC';
import { HttpClientV1 } from '../../utils/api/HttpClientV1';
import { UsersTableStore } from './UsersTableStore';
import { UserModel } from './model/UserModel';
import { action, computed } from 'mobx';
import { FormError } from '../../utils/error/FormError';
import { AxiosResponse } from 'axios';
import { UserResponseDTO } from './dto/response/user.response.dto';
import { UserStatus } from './const/user-status.enum';
import { UpdateUserRequestDTO } from './dto/request/user.update-user.request.dto';

@provide.singleton()
export class UserEditModalStore {
  @lazyInject(HttpClientV1)
  protected readonly httpClientV1!: HttpClientV1;

  @lazyInject(UsersTableStore)
  tableStore!: UsersTableStore;

  @computed
  get itemsMap() {
    return this.tableStore.itemsMap;
  }

  @action
  updateUser(id: string, model: UserModel, resetPassword?: boolean, password?: string) {
    return this.httpClientV1.put<UpdateUserRequestDTO, UserResponseDTO>(
      `users/${id}`,
      {
        email: model.email,
        role: model.role,
        pin: model.pin,
        factoryId: model.factory?.id,
        fullName: model.fullName,
        permissions: model.permissions,
        status: model.status as UserStatus,
        phoneNumber: model.phoneNumber,
        resetPassword: resetPassword,
		password: password,
      },
    )
      .then(this.onUpdateUserSuccess)
      .catch(this.onUpdateUserError) as Promise<UserModel>;
  }

  @action.bound
  protected onUpdateUserSuccess(data: UserResponseDTO) {
    const model = UserModel.createFromDTO(data);

    if (this.itemsMap.has(data.id)) {
      this.itemsMap.set(model.id as string, model);
    }

    return model;
  }

  @action.bound
  protected onUpdateUserError(reason: AxiosResponse) {
    if (reason.status === 400) {
      throw FormError.createFromBadRequestResponseDTO(reason.data);
    }

    const { property, description } = reason.data.message[0];

    if (reason.status === 409) {
      throw new FormError({ [property]: description })
    }

    throw new Error(description);
  }
}
