import React, { Component } from 'react';
import ProLayout, { MenuDataItem } from '@ant-design/pro-layout';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import RightContent from '../../components/GlobalHeader/RightContent';
import { history } from '../../utils/router';
import GlobalFooter from '../../components/GlobalFooter/index';
import { lazyInject } from '../../utils/IoC';
import { CurrentUserStore } from '../../stores/users/CurrentUserStore';
import { CategoryAccessStore } from '../../stores/users/CategoryAccessStore';
import { reaction } from 'mobx';

import SmallLogo from '../../assets/images/logo/small-logo.svg';

import './index.less';

@observer
class DashboardLayout extends Component {
  @lazyInject(CurrentUserStore)
  currentUserStore!: CurrentUserStore;

  @lazyInject(CategoryAccessStore)
  categoryAccessStore!: CategoryAccessStore;

  reactions: Array<Function> = [];

  componentDidMount() {
    this.currentUserStore.fetchCurrentUser().finally();

    this.reactions = Object.getOwnPropertyNames(this.categoryAccessStore).map(p => reaction(
      () => (this.categoryAccessStore)[p as keyof CategoryAccessStore],
      ()  => this.forceUpdate())
    );
  }

  componentWillUnmount() {
    this.reactions.map(dispose => dispose());
  }

  menuDataRender = (menuList: MenuDataItem[]): MenuDataItem[] => {
    return menuList.map((item) => {
      return {
        ...item,
        hideInMenu: item.hasAccess && !item.hasAccess(),
        children: item.children ? this.menuDataRender(item.children) : undefined,
      };
    })
  };

  render() {
    const { children } = this.props;

    return (
      <HelmetProvider>
        <Helmet>
          <title>{'Integrity | Личный кабинет'}</title>
        </Helmet>

        <ProLayout
          logo={SmallLogo}
          title={'integrity'}

          onMenuHeaderClick={() => history.push('/')}

          menuItemRender={(menuItemProps, defaultDom) => {
            if (menuItemProps.isUrl || !menuItemProps.path) {
              return defaultDom;
            }

            return <Link to={menuItemProps.path}>{defaultDom}</Link>;
          }}

          breadcrumbRender={(routes = []) => [
            {
              path: '/',
              breadcrumbName: 'Главная',
            },
            ...routes,
          ]}

          itemRender={(route, params, routes, paths) => {
            const first = routes.indexOf(route) === 0;
            return first ? (
              <Link to={paths.join('/')}>{route.breadcrumbName}</Link>
            ) : (
              <span>{route.breadcrumbName}</span>
            );
          }}

          footerRender={() => <GlobalFooter />}
          menuDataRender={this.menuDataRender}
          rightContentRender={() => <RightContent />}
          fixSiderbar={true}
          fixedHeader={true}
          {...this.props}
        >
          {children}
        </ProLayout>
      </HelmetProvider>
    );
  }
}

export default DashboardLayout;
