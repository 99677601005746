import React, { Component } from 'react';
import { Button, Col, Form, Input, Modal, notification, Row, Upload} from 'antd';
import { RcFile } from 'antd/lib/upload';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { FormInstance } from 'antd/lib/form';
import { lazyInject } from '../../../../utils/IoC';
import { RibbonImportModel } from '../../../../stores/factory/model/RibbonImportModel';
import { FormError } from '../../../../utils/error/FormError';
import { RibbonImportCreateModalStore } from '../../../../stores/factory/RibbonImportCreateModalStore';
import EquipmentUploadContent from '../../../equipment/component/EquipmentUploadContent/index';


type FormParams = {
  name?: string;
  fullName?: string;
  country?: string;
  city?: string;
  address?: string;
  otk?: string;
}

interface Props {
  visible: boolean;
  onClose: () => void;
  id?: number;
}

// noinspection DuplicatedCode
@observer
class RibbonImportCreateModal extends Component<Props> {
  @lazyInject(RibbonImportCreateModalStore)
  store!: RibbonImportCreateModalStore;

  @observable loading: boolean = false;
  @observable error?: FormError;

  refForm = React.createRef<FormInstance>();
  file?: RcFile;

  @action
  handleChange = (key: string) => {
    delete this.error?.errors[key];
  }

  @action
  handleCreate = () => {
    const value = this.refForm.current?.getFieldsValue() as FormParams;
    if (!value) {
      return
    }

    const file = this.file;
    const model = new RibbonImportModel({
      file,
    });

    this.loading = true;
    this.store.createRibbonImport(
      model
    ).then(this.handleCreateSuccess).catch(this.handleCreateError);
  };

  @action.bound
  handleCreateSuccess(model: RibbonImportModel) {
    this.loading = false;
    this.refForm.current?.resetFields();

    notification.success({ message: `Файл загрузки лент добавлен` });

    this.props.onClose();
  }

  @action.bound
  handleCreateError(error: FormError) {
    this.loading = false;
    this.error = error;
  }

  @action
  handleCancel = () => {
    this.props.onClose();
  };

  @action
  handleBeforeUpload = (file: RcFile) => {
    this.file = file;
    return false;
  };

  render() {
    const { visible } = this.props;

    const hasNameError = !!(this.error?.errors?.name);
    const hasFullNameError = !!(this.error?.errors?.fullName);
    const hasCountryError = !!(this.error?.errors?.country);
    const hasCityError = !!(this.error?.errors?.city);
    const hasAddressError = !!(this.error?.errors?.address);
    const hasOTKError = !!(this.error?.errors?.otk);

    return (
      <Modal
        title="Добавление файла загрузки ленты"
        visible={visible}
        width="500px" centered
        onCancel={this.handleCancel}
        footer={[
          <Button
            key="back"
            onClick={this.handleCancel}
          >
            Отмена
          </Button>,

          <Button
            key="submit"
            type="primary"
            disabled={this.loading}
            loading={this.loading}
            onClick={this.handleCreate}
          >
            Добавить
          </Button>,
        ]}
      >
        <Form ref={this.refForm} layout="vertical">
          <Row gutter={[16, 16]}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
              <Form.Item
                label="Файл загрузки"
                key="file"
              >
                <Upload.Dragger
                  beforeUpload={this.handleBeforeUpload}
                >
                  <EquipmentUploadContent />
                </Upload.Dragger>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default RibbonImportCreateModal;
