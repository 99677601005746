import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { Button, Card, Col, notification, Row, Skeleton, Table } from 'antd';
import EquipmentTableRowAction from './component/EquipmentTableRowAction/index';
import { action, observable, toJS } from 'mobx';
import { Moment } from 'moment';
import { MomentDateFormat } from '../../utils/MomentDateFormat';
import EquipmentCreateModal from './component/EquipmentCreateModal/index';
import EquipmentEditModal from './component/EquipmentEditModal/index';
import { observer } from 'mobx-react';
import EquipmentTableExpandedRow from './component/EqupmentTableExpandedRow/index';
import { getTableTextSearchDropdownProps } from '../../components/Table/TableTextSearchDropdown';
import { getTableDateFilterDropdownProps } from '../../components/Table/TableDateFilterDropdown';
import { getTableFactoryFilterDropdownProps } from '../../components/Table/TableFactoryFilterDropdown';
import { lazyInject } from '../../utils/IoC';
import { EquipmentTableStore } from '../../stores/equipment/EquipmentTableStore';
import { EquipmentModel } from '../../stores/equipment/model/EquipmentModel';
import { CategoryAccessStore } from '../../stores/users/CategoryAccessStore';
import { Redirect } from 'react-router';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';

@observer
class EquipmentPage extends Component {
  @lazyInject(EquipmentTableStore)
  store!: EquipmentTableStore;

  @lazyInject(CategoryAccessStore)
  categoryAccessStore!: CategoryAccessStore;

  @observable isModalVisible = { create: false, edit: false };
  @observable currentEditId = -1;

  componentDidMount() {
    this.store.fetchAll();
  }

  //region Handlers
  deleteRecord = (id: number) => {
    this.store.deleteItem(id).catch(() => notification.error({ message: 'Не удалось удалить оборудование' }));
  };

  @action
  openCreateModal = () => {
    this.isModalVisible.create = true;
  };

  @action
  closeCreateModal = () => {
    this.isModalVisible.create = false;
  };

  @action
  openEditModal = (id: number) => {
    this.currentEditId = id;
    this.isModalVisible.edit = true;
  };

  @action
  closeEditModal = () => {
    this.isModalVisible.edit = false;
    this.currentEditId = -1;
  };
  //endregion

  render() {
    if (!this.categoryAccessStore.loading && !this.categoryAccessStore.hasEquipmentAccess) {
      return <Redirect to='/' />;
    }

    const columns = [
      {
        title: "№",
        dataIndex: "id",
		render: (value: any, item: any, index: number) => {
			const p = this.store.tablePagination;
			return (<a href="#" onClick={() => this.openEditModal(item.id)}>{item.id}</a>);
		},
      },
      {
        title: "Наименование",
        dataIndex: "name",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["name"],
        filteredValue: (this.store.filters.name as string[]) || null,
        ...getTableTextSearchDropdownProps("Поиск наименования"),
      },
      {
        title: "Завод",
        dataIndex: "factory",
        filteredValue: (this.store.filters.factory as string[]) || null,
        ...getTableFactoryFilterDropdownProps(),

        render: (factory: { id: string; name: string }) => {
          return (
            <div className="editable-cell-value-wrap">
              {factory?.name || "Отсутствует"}
            </div>
          );
        },
      },
      {
        title: "Серийный номер",
        dataIndex: "serialNumber",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["serialNumber"],
        filteredValue: (this.store.filters.serialNumber as string[]) || null,
        ...getTableTextSearchDropdownProps("Серийный номер"),
      },
      {
        title: "Комментарий",
        dataIndex: "comment",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["comment"],
        filteredValue: (this.store.filters.comment as string[]) || null,
        ...getTableTextSearchDropdownProps("Комментарий"),
      },
      {
        title: "Дата поставки",
        dataIndex: "deliveryDate",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["deliveryDate"],
        filteredValue: (this.store.filters.deliveryDate as string[]) || null,
        ...getTableDateFilterDropdownProps(),
        render: (date: Moment) => {
          return (
            <div className="editable-cell-value-wrap">
              {date.format(MomentDateFormat)}
            </div>
          );
        },
      },
      {
        title: "Дата окончания аренды",
        dataIndex: "leaseEndDate",
        sorter: { multiple: -1 },
        sortOrder: this.store.tableAppliedSorters["leaseEndDate"],
        filteredValue: (this.store.filters.leaseEndDate as string[]) || null,
        ...getTableDateFilterDropdownProps(),
        render: (date: Moment) => {
          return (
            <div className="editable-cell-value-wrap">
              {date.format(MomentDateFormat)}
            </div>
          );
        },
      },
      {
        title: "Действия",
        render: (text: string, record: EquipmentModel) => (
          <EquipmentTableRowAction
            id={record.id as any}
            onEdit={(id) => this.openEditModal(id as number)}
            onDelete={(id) => this.deleteRecord(id as number)}
          />
        ),
      },
    ];

    return (
      <PageHeaderWrapper>
        <EquipmentCreateModal
          visible={this.isModalVisible.create}
          onClose={this.closeCreateModal}
        />

        {this.isModalVisible.edit && this.currentEditId !== -1 &&
          <EquipmentEditModal
            visible={this.isModalVisible.edit}
            onClose={this.closeEditModal}
            id={this.currentEditId}
          />
        }

        <Card>
          <Row gutter={[16, 16]}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Button
                size="large"
                block
                type="primary"
                onClick={this.openCreateModal}
              >
                Добавить оборудование
              </Button>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 5, offset: 6 }}
              lg={{ span: 4, offset: 8 }}
              xl={{ span: 4, offset: 10 }}
            >
              <Button size="large" block onClick={this.store.handleTableRefresh}>
                Обновить
              </Button>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 5 }}
              lg={{ span: 4 }}
              xl={{ span: 4 }}
            >
              <Button size="large" block onClick={this.store.handleTableReset}>
                Сбросить
              </Button>
            </Col>
          </Row>
          <Table
            loading={this.store.loading}
            columns={columns}
            rowKey={(record) => record.id as number}
            dataSource={toJS(this.store.items)}
            expandable={{
              expandedRowRender: (record) => (
                <EquipmentTableExpandedRow
                  id={record.id!}
                  comment={record.comment!}
                  files={record.files}
                />
              ),

              rowExpandable: (record) =>
                !!(record.comment && record.comment.length > 0) ||
                !!(record.files && record.files.length > 0),
            }}
            pagination={{
              position: ["bottomRight"],
              showSizeChanger: true,
              ...this.store.tablePagination,
            }}
            locale={{
              emptyText: this.store.loading ? (
                <>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </>
              ) : (
                <p>Данные отсутствуют</p>
              ),
            }}
            onChange={this.store.handleTableChange}
			onRow={(record, rowIndex) => {
				return {
					onDoubleClick: event => {
					   this.openEditModal(record.id as number);
				   },
				};
			}}
          />
        </Card>
      </PageHeaderWrapper>
    );
  }
}

export default EquipmentPage;
