import { lazyInject, provide } from '../../utils/IoC';
import { BalanceModel } from './model/BalanceModel';
import { HttpClientV1 } from '../../utils/api/HttpClientV1';
import { BalanceResponseDTO } from './dto/response/balance.response.dto';
import { BaseTableStore } from '../../utils/store/BaseTableStore';
import { Moment } from 'moment';
import { FactoryAutocompleteResponseDTO } from '../../components/AutoCompleteSelect/FactoryAutoCompleteSelect/dto/FactoryAutocompleteResponseDTO';

@provide.singleton()
export class BalanceTableStore extends BaseTableStore<BalanceModel, BalanceResponseDTO> {
  @lazyInject(HttpClientV1)
  protected readonly httpClientV1!: HttpClientV1;

  protected readonly path = '/balances';
  protected readonly httpClient = this.httpClientV1;

  protected readonly download_xlsx_path = '/balances/xlsx';

  protected get searchDTO(): object {
    return {
      id: this.filters.id ? this.filters.id[0] : undefined,
      startDate: this.filters.date_created
      ? {
        from: this.filters.date_created[0]
          ? (this.filters.date_created[0] as Moment)
            .toDate()
            .getTime()
          : undefined,
        to: this.filters.date_created[1]
          ? (this.filters.date_created[1] as Moment)
            .toDate()
            .getTime()
          : undefined,
      }
      : undefined,

    };
  }

  protected get filterDTO(): object {
    return {
	  amount: this.filters.amount
		  ? {
			  from: this.filters.amount[0],
			  to: this.filters.amount[1],
		  } : undefined,
	  factoryId: this.filters.factory
		? (this.filters.factory[0] as FactoryAutocompleteResponseDTO).id
		: undefined,
	  detail: this.filters.detail
	  	? this.filters.detail[0]
		: undefined,
    };
  }

  protected createModelFromResponseDTO(dto: BalanceResponseDTO): BalanceModel {
    return BalanceModel.createFromDTO(dto);
  }
  
}
