import { lazyInject, provide } from '../../utils/IoC';
import { EquipmentModel } from './model/EquipmentModel';
import { HttpClientV1 } from '../../utils/api/HttpClientV1';
import { Moment } from 'moment';
import { EquipmentResponseDTO } from './dto/response/equipment.response.dto';
import { BaseTableStore } from '../../utils/store/BaseTableStore';

@provide.singleton()
export class EquipmentTableStore extends BaseTableStore<EquipmentModel, EquipmentResponseDTO> {
  @lazyInject(HttpClientV1)
  protected readonly httpClientV1!: HttpClientV1;

  protected readonly path = '/equipment';
  protected readonly httpClient = this.httpClientV1;

  protected get searchDTO(): object {
    return {
      id: this.filters.id ? this.filters.id[0] : undefined,
      name: this.filters.name ? this.filters.name[0] : undefined,
      serialNumber: this.filters.serialNumber
        ? this.filters.serialNumber[0]
        : undefined,
      comment: this.filters.comment ? this.filters.comment[0] : undefined,
    };
  }

  protected get filterDTO(): object {
    return {
      factoryId: this.filters.factory
        ? (this.filters.factory as Array<{ id: string }>).map((v) => v.id)
        : undefined,

      deliveryDate: this.filters.deliveryDate
        ? {
          from: this.filters.deliveryDate[0]
            ? (this.filters.deliveryDate[0] as Moment)
              .toDate()
              .getTime()
            : undefined,
          to: this.filters.deliveryDate[1]
            ? (this.filters.deliveryDate[1] as Moment)
              .toDate()
              .getTime()
            : undefined,
        }
        : undefined,

      leaseEndDate: this.filters.leaseEndDate
        ? {
          from: this.filters.leaseEndDate[0]
            ? (this.filters.leaseEndDate[0] as Moment)
              .toDate()
              .getTime()
            : undefined,
          to: this.filters.leaseEndDate[1]
            ? (this.filters.leaseEndDate[1] as Moment)
              .toDate()
              .getTime()
            : undefined,
        }
        : undefined,
    };
  }

  protected createModelFromResponseDTO(dto: EquipmentResponseDTO): EquipmentModel {
    return EquipmentModel.createFromDTO(dto);
  }
}
