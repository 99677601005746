export enum StatusPrinterController {
	online = "online",
	offline = "offline",
	unknown = "unknown",
}

export const StatusPrinterControllerName: Record<StatusPrinterController, string> = {
	[StatusPrinterController.online]: 'В сети',
	[StatusPrinterController.offline]: 'Не в сети',
	[StatusPrinterController.unknown]: 'Нет данных',
};

export function getStatusPrinterControllerName(status?: StatusPrinterController) {
	return status ? StatusPrinterControllerName[status] : status;
}

//export function getStatusPrinterControllerIcon(status?: StatusPrinterController) {}
