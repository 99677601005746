import React, { Component } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { Card, Col, notification, Row, Form, Input, Button, Table } from 'antd';
import { action, observable} from 'mobx';
import CableEditModal from './component/CableEditModal';

import { FormInstance } from 'antd/lib/form';
import { observer } from 'mobx-react';
import { lazyInject } from '../../utils/IoC';
import { FactoryTableStore } from '../../stores/factory/FactoryTableStore';
import { CategoryAccessStore } from '../../stores/users/CategoryAccessStore';
import { Redirect } from 'react-router';
import axios from 'axios';
import { ACCESS_TOKEN_STORAGE_KEY } from '../../stores/auth/const/AuthTokensStorageKeys';
import moment from 'moment';
import { MomentDateTimeTzFormat } from '../../utils/MomentDateFormat';
import { getStatusProductName, StatusProduct } from '../../stores/factory/const/status-product.enum';

const api_url = process.env.REACT_APP_API_SERVER_HOST
@observer
class CodeSearchPage extends Component {
	@lazyInject(CategoryAccessStore)
		categoryAccessStore!: CategoryAccessStore;

	@observable isModalVisible = { create: false, edit: false };
	@observable option = [];

	@observable search_result_code_usable = undefined;
	@observable currentEditId = '';
	@observable currentEditFactory = '';

	refForm = React.createRef<FormInstance>();

	@action
	openEditModal = (id: string, factory: string) => {
		this.currentEditId = id;
		this.currentEditFactory = factory;
		this.isModalVisible.edit = true;
	};

	@action
	closeEditModal = () => {
		this.isModalVisible.edit = false;
		this.currentEditId = '';
	};

	clearState = () => {
		this.setState({
			search_complete: false,
			search_result_code_found: false,
		});
	};

	@action
	searchCode = () => {
		const values = this.refForm.current?.getFieldsValue();
		this.state.code_to_search = values.code_to_search;

		const code = this.state.code_to_search.replace(/-/g, '').trim();
		if(code.length !== 32 && code.length !== 16 && code.length !== 11) {
			notification.error({
				message: "Некорректная длина кода",
			});
			this.clearState();
			return;
		}

		const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) || undefined
		axios.get(api_url+'/v1/admin/code/search', {
			headers: {'Authorization':'Bearer ' + token},
			params: {
				code,
			},
		})
			.then(resp => {
				this.setState({
					search_result_code_found: resp.data.code_found,
					search_result_code_type: resp.data.code_type,
					search_result_code_factories: resp.data.factories,
					search_result_code_products: resp.data.products,
					search_result_code_ribbon_connections: resp.data.ribbon_connections,
					products_page: 1,
					search_complete: true,
					search_result_error: '',
				});
				this.forceUpdate();
			})
			.catch(e => {
				this.clearState();
				this.setState({
					search_result_error: e,
				});

				notification.error({
					message: e.message,
				});
			});
	};

	state = {
		code_to_search: '',
		search_result_code_products: [],
		search_result_code_factories: [],
		search_result_code_ribbon_connections: [],
		search_result_code_type: '',
		search_result_code_found: false,
		search_complete: false,
		search_result_error: '',
		products_page: 1,
	};

	render() {
		if (!this.categoryAccessStore.loading && !this.categoryAccessStore.hasFactyoryAccess) {
			return <Redirect to='/' />;
		}

		const factories_code128_columns = [{
			title: "Завод",
			dataIndex: "factory_name",
		}, {
			title: "Дата",
			dataIndex: "timestamp",
			render: (date: string) => {
				return (
					<div className="editable-cell-value-wrap">
						{(date) ? moment(date).format(MomentDateTimeTzFormat) : ""}
					</div>
			   );
			},
		}, {
			title: "Принтер",
			dataIndex: "printer_description",
		}];

		const ribbon_connections_columns = [{
			title: "Дата соединения",
			dataIndex: "timestamp",
			render: (date: string) => {
				return (
					<div className="editable-cell-value-wrap">
						{(date) ? moment(date).format(MomentDateTimeTzFormat) : ""}
					</div>
			   );
			},
		}, {
			title: "Завод",
			dataIndex: "factory_name",
		}, {
			title: "Количество кодов в соединении",
			dataIndex: "num_codes",
		}];

		const products_columns = [{
			title: null,
			key: "index",
			render: (value: any, item: any, index: number) => {
				return (<a href="#" onClick={() => this.openEditModal(item.uuid, item.factory_id)}>{(this.state.products_page-1)*10 + 1+index}</a>);
			},
		}, {
			title: "Дата создания",
			dataIndex: "created_timestamp",
			render: (date: string) => {
				return (
					<div className="editable-cell-value-wrap">
						{(date) ? moment(date).format(MomentDateTimeTzFormat) : ""}
					</div>
			   );
			},
		}, {
			title: "Дата выпуска",
			dataIndex: "released_timestamp",
			render: (date: string) => {
				return (
					<div className="editable-cell-value-wrap">
						{(date) ? moment(date).format(MomentDateTimeTzFormat) : ""}
					</div>
			   );
			},
		}, {
			title: "Длина",
			dataIndex: "length",
		}, {
			title: "Номер барабана",
			dataIndex: "drum_number",
		}, {
			title: "Статус",
			dataIndex: "status",
			render: (value: string) => {
				return (
					<div className="editable-cell-value-wrap">
						{getStatusProductName(value as StatusProduct)}
					</div>);
			}
		}, {
			title: "Завод",
			dataIndex: "factory_name",
		}];

		const t = this;

		let datamatrix_import_date = "";
		if(this.state.search_result_code_type === "datamatrix" && this.state.search_result_code_factories.length > 0) {
			datamatrix_import_date = moment((this.state.search_result_code_factories[0] as any).timestamp).format(MomentDateTimeTzFormat);
		}

		return (
			<PageHeaderWrapper>
				{this.isModalVisible.edit && this.currentEditId !== '' &&
				<CableEditModal
					visible={this.isModalVisible.edit}
					onClose={this.closeEditModal}
					id={this.currentEditId}
					factory={this.currentEditFactory}
				/>
				}

				<Card>
					<Form ref={this.refForm} layout="vertical">
						<Row gutter={[16, 16]}>
							<Col xs={{ span: 12 }} sm={{ span: 12}} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
								<Form.Item name="code_to_search" key="code_to_search">
									<Input placeholder="Введите код для поиска" />
								</Form.Item>
							</Col>
							<Col xs={{ span: 12 }} sm={{ span: 12}} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
								<Button size="large" block type="primary" onClick={this.searchCode}
									style={{height: "32px", paddingTop: "4px", paddingBottom: "4px"}}
								>Поиск</Button>
							</Col>
						</Row>
					</Form>

					{!this.state.search_result_code_found && this.state.search_complete &&
					<Row gutter={[16, 16]}>
						<Col xs={{ span: 12 }} sm={{ span: 12}} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
							<h2>Код не найден</h2>
						</Col>
					</Row>
					}

					{this.state.search_result_code_found && <>
					<Row gutter={[16, 16]} style={{paddingTop: "32px"}}>
						<span><h2>Тип кода: {this.state.search_result_code_type}</h2></span>
					</Row>
					</>}

					{this.state.search_result_code_found && this.state.search_result_code_type === "code128" &&
							this.state.search_result_code_factories.length > 0 && <>
					<Row gutter={[16, 16]} style={{paddingTop: "32px"}}>
						<span><h2>Дата печати кода</h2></span>
					</Row>
					<div style={{overflow: "auto"}}>
						<Table columns={factories_code128_columns}
							dataSource={this.state.search_result_code_factories}
							style={{width: "100%"}}
						/>
					</div>
					</>}

					{this.state.search_result_code_found && this.state.search_result_code_type === "datamatrix" &&
							datamatrix_import_date != '' &&
					<Row gutter={[16, 16]} style={{paddingTop: "32px"}}>
						<span><h2>Дата импорта кода: {datamatrix_import_date}</h2></span>
					</Row>
					}

					{this.state.search_result_code_found && this.state.search_result_code_type === "datamatrix" &&
							!!this.state.search_result_code_ribbon_connections &&
							this.state.search_result_code_ribbon_connections.length > 0 && <>
					<Row gutter={[16, 16]} style={{paddingTop: "32px"}}>
						<span><h2>Фиксация отрезков</h2></span>
					</Row>
					<div style={{overflow: "auto"}}>
						<Table columns={ribbon_connections_columns}
							dataSource={this.state.search_result_code_ribbon_connections}
							style={{width: "100%"}}
						/>
					</div>
					</>}

					{this.state.search_result_code_found && !!this.state.search_result_code_products &&
							this.state.search_result_code_products.length > 0 && <>
					<Row gutter={[16, 16]} style={{paddingTop: "32px"}}>
							<span><h2>Изделия</h2></span>
					</Row>
					<div style={{overflow: "auto"}}>
						<Table columns={products_columns}
							dataSource={this.state.search_result_code_products}
							pagination={{
								onChange(current) {
									t.setState({products_page: current});
								}
							}}
							style={{width: "100%"}}
						/>
					</div>
					</>}
				</Card>
			</PageHeaderWrapper>
		);
	}
}

export default CodeSearchPage;
