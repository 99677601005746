import React from 'react';
import { observer } from 'mobx-react';
import { Select } from 'antd';
import { action, observable } from 'mobx';
import { lazyInject } from '../../../utils/IoC';
import { HttpClientV1 } from '../../../utils/api/HttpClientV1';
import { PaginatedPriceAutocompleteResponseDTO } from './dto/PaginatedPriceAutocompleteResponseDTO';
import { LabeledValue, SelectProps } from 'antd/lib/select';
import qs from 'qs';

interface Value {
  id: string;
  name: string;
}

interface Props extends Omit<SelectProps<LabeledValue>,
    | "value"
    | "onChange"
    | "labelInValue"
    | "onSearch"
    | "showSearch"
    | "filterOption"
    | "onFocus"
    | "defaultValue"
> {
  limit?: number;
  value?: Value;
  initialValue?: Value;
  onChange?: (value?: Value) => void;
}

@observer
class PriceAutoCompleteSelect extends React.Component<Props> {
  @lazyInject(HttpClientV1)
  httpClientV1!: HttpClientV1;

  @observable loading: boolean = false;
  @observable data: Value[] = [];

  handleFocus = () => {
    this.handleSearch(this.props.value?.name || '');
  }

  handleChange = (v: LabeledValue) => {
    this.props.onChange &&
      this.props.onChange(v
        ? { id: v.value as string, name: v.label as string }
        : undefined
    );
  };

  @action
  handleSearch = (input: string) => {
    this.loading = true;

    this.httpClientV1.get<PaginatedPriceAutocompleteResponseDTO>(
      '/prices/autocomplete',
      {
        params: {
          input,
          pagination: {
            limit: this.props.limit ? this.props.limit : 10
          }
        },
        paramsSerializer: params => qs.stringify(params),
      }
    )
      .then(this.handleSearchRequestSuccess)
      .catch(this.handleSearchRequestError)
  };

  @action.bound
  handleSearchRequestSuccess = (result: PaginatedPriceAutocompleteResponseDTO) => {
    this.data = result.items.map(item => ({ id: item.id, name: item.name }));
    this.loading = false;
  };

  @action.bound
  handleSearchRequestError = () => {
    this.data = [];
    this.loading = false;
  };

  render() {
    const { limit, value, onChange, ...rest } = this.props;

    return (
      <Select
        showSearch
        placeholder="Выберите тариф"
        loading={this.loading}
        onChange={this.handleChange}
        onSearch={this.handleSearch}
        onFocus={this.handleFocus}
        labelInValue={true}
        filterOption={false}
        value={value && { key: value.id, value: value.id, label: value.name }}
        {...rest}
      >
        {this.data.map((item) => (
          <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
        ))}
      </Select>
    );
  }
}

export default PriceAutoCompleteSelect;
