import { lazyInject, provide } from '../../utils/IoC';
import { HttpClientV1 } from '../../utils/api/HttpClientV1';
import { action } from 'mobx';
import { FactoryModel } from './model/FactoryModel';
import { RcFile } from 'antd/lib/upload';
import { AddFactoryRequestDTO } from './dto/request/add-factory.request.dto';
import { FactoryResponseDTO } from './dto/response/factory.response.dto';
import { AxiosResponse } from 'axios';
import { FormError } from '../../utils/error/FormError';
import { FactoryTableStore } from './FactoryTableStore';

@provide.singleton()
export class FactoryCreateModalStore {
  @lazyInject(HttpClientV1)
  protected readonly httpClientV1!: HttpClientV1;

  @lazyInject(FactoryTableStore)
  tableStore!: FactoryTableStore;

  @action
  createFactory(model: FactoryModel) {
    const body: AddFactoryRequestDTO = {
      name: model.name, 
      fullName: model.fullName,
      country: model.country,
      city: model.city,
      address: model.address,
      otk_contact_info: model.otk,
    };

    const formData = new FormData();

    return this.httpClientV1.post<{}, FactoryResponseDTO>(
      '/factories',
      {},
      {
        data: JSON.stringify(body),
        headers: {'Content-Type': 'application/json' }
      }
    ).then(this.onCreateFactorySuccess).catch(this.onCreateFactoryError) as Promise<FactoryModel>;
  }

  @action.bound
  protected onCreateFactorySuccess(data: FactoryResponseDTO) {
    this.tableStore.fetchAll();
    return FactoryModel.createFromDTO(data);
  }

  @action.bound
  protected onCreateFactoryError(reason: AxiosResponse) {
    if (reason.status === 400) {
      const data = Object.entries(reason.data as Record<string, string>)
        .map(([ field, value ]) => [ field.replace('body.', ''), value ])
        .reduce((obj, [field, value]) => ({ ...obj, [field]: value }), {})
      ;

      throw FormError.createFromBadRequestResponseDTO(data);
    }

    throw new FormError({ });
  }
}
