import { lazyInject, provide } from '../../../utils/IoC';
import { HttpClientV1 } from '../../../utils/api/HttpClientV1';
import { PriceModel } from './model/PriceModel';
import { BaseTableStore } from '../../../utils/store/BaseTableStore';
import { PriceResponseDTO } from './dto/response/prices.response.dto';
import { action, observable } from 'mobx';
import { Moment } from 'moment';

@provide.singleton()
export class PricesTableStore extends BaseTableStore<PriceModel, PriceResponseDTO> {
  @lazyInject(HttpClientV1)
  protected readonly httpClientV1!: HttpClientV1;

  @observable isActual: boolean = true;

  protected readonly path = '/prices';
  protected readonly httpClient = this.httpClientV1;

  protected readonly download_xlsx_path = '/prices/xlsx';

  protected get searchDTO(): object {
    return {
      name: this.filters.name ? this.filters.name[0] : undefined,
    };
  }

  protected get filterDTO(): object {
    return {
      isActual: this.isActual,

      datamatrix: this.filters.datamatrix
        ? {
          from: this.filters.datamatrix[0] || undefined,
          to: this.filters.datamatrix[1] || undefined
        }
        : undefined,

      barcode: this.filters.barcode
        ? {
          from: this.filters.barcode[0] || undefined,
          to: this.filters.barcode[1] || undefined
        }
        : undefined,

      startDate: this.filters.startDate
        ? {
          from: this.filters.startDate[0]
            ? (this.filters.startDate[0] as Moment)
              .toDate()
              .getTime()
            : undefined,
          to: this.filters.startDate[1]
            ? (this.filters.startDate[1] as Moment)
              .toDate()
              .getTime()
            : undefined,
        }
        : undefined,

      endDate: this.filters.endDate
        ? {
          from: this.filters.endDate[0]
            ? (this.filters.endDate[0] as Moment)
              .toDate()
              .getTime()
            : undefined,
          to: this.filters.endDate[1]
            ? (this.filters.endDate[1] as Moment)
              .toDate()
              .getTime()
            : undefined,
        }
        : undefined,
    };
  }

  protected createModelFromResponseDTO(dto: PriceResponseDTO): PriceModel {
    return PriceModel.createFromDTO(dto);
  }

  @action
  setIsActual = (value: boolean) => {
    this.isActual = value;
    this.fetchAll();
  }
}
