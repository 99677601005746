export enum UserRole {
  OTK = "otk",
  FactoryAdmin = "factory_admin",
  IntegrityManager = "admin",
  IntegrityAdmin = "head_admin",
}

export const UserRoleName: Record<UserRole, string> = {
  [UserRole.OTK]: 'ОТК',
  [UserRole.FactoryAdmin]: 'Администратор завода',
  [UserRole.IntegrityManager]: 'Менеджер Integrity',
  [UserRole.IntegrityAdmin]: 'Администратор Integrity',
};

export function getUserRoleName(role?: UserRole) {
  return role ? UserRoleName[role] : role;
}
