import React, { Component } from "react";
import { Input } from "antd";
import TableButtonFiltersDropdown from "./TableButtonFiltersDropdown";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { SearchOutlined } from '@ant-design/icons/lib';

interface Props extends FilterDropdownProps {
  placeholder?: string;
}

export const getTableTextSearchDropdownProps = (placeholder?: string) => ({
  filterDropdown: (props: FilterDropdownProps) => (
    <TableTextSearchDropdown placeholder={placeholder} {...props} />
  ),
  filterIcon: (filtered: string) => (
    <SearchOutlined style={{ color: filtered ? "#D21919" : undefined }} />
  ),
});

class TableTextSearchDropdown extends Component<Props> {
  render() {
    const {
      placeholder = "",
      selectedKeys,
      setSelectedKeys,
      confirm,
      clearFilters,
    } = this.props;
    console.log(this.props)
    return (
      <div style={{ padding: "8px" }}>
        <Input
          placeholder={placeholder}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <TableButtonFiltersDropdown
          handleReset={clearFilters}
          handleSearch={confirm}
        />
      </div>
    );
  }
}

export default TableTextSearchDropdown;
