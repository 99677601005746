import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, Form, Input } from 'antd';
import { LockTwoTone, MailOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { action, observable } from 'mobx';
import { RouterStore } from 'mobx-react-router';
import { observer } from 'mobx-react';
import { lazyInject } from '../../utils/IoC';
import AuthFormContainer from '../../components/AuthFormContainer/index';
import { FormError } from '../../utils/error/FormError';
import { LoginStore } from './store/LoginStore';

import styles from './index.module.less';

@observer
class LoginPage extends Component {
  @lazyInject(LoginStore)
  loginStore!: LoginStore;

  @lazyInject(RouterStore)
  routerStore!: RouterStore;

  @observable error?: FormError;

  @action
  handleChange = (property: string) => {
    delete this.error?.errors[property];
  };

  @action
  handleFinish = ({ email, password }: { email: string, password: string }) => {
    this.error = undefined;

    this.loginStore.loginAttempt(email, password)
      .then(this.handleLoginAttemptSuccess)
      .catch(this.handleLoginAttemptError)
    ;
  };

  @action.bound
  handleLoginAttemptSuccess() {
    this.routerStore.push('/');
  }

  @action.bound
  handleLoginAttemptError(error: FormError) {
    this.error = error;
  }

  render() {
    const hasEmailError = !!(this.error?.errors?.email);
    const hasPasswordError = !!(this.error?.errors?.password);

    return (
      <AuthFormContainer>
        <Helmet>
          <title>Integrity | Авторизация</title>
        </Helmet>

        <Form
          className={styles.form}
          onFinish={this.handleFinish}
        >
          <Form.Item>
            <h1>Авторизация</h1>
            <p>Пожалуйста войдите для получения доступа к личному кабинету</p>
          </Form.Item>

          <Form.Item
            name="email"
            hasFeedback={hasEmailError}
            validateStatus={hasEmailError ? 'error' : undefined}
            help={this.error?.errors?.email}
          >
            <Input
              id="email"
              placeholder="Введите эл. почту"
              size="large"
              prefix={<MailOutlined style={{ color: "#D21919" }} />}
              onChange={() => this.handleChange('email')}
            />
          </Form.Item>

          <Form.Item
            name="password"
            hasFeedback={hasPasswordError}
            validateStatus={hasPasswordError ? 'error' : undefined}
            help={this.error?.errors?.password}
          >
            <Input.Password
              id="password"
              placeholder="Введите пароль"
              size="large"
              prefix={<LockTwoTone twoToneColor="#D21919" />}
              onChange={() => this.handleChange('password')}
            />
          </Form.Item>

          <Form.Item className={styles.reset}>
            <Link to={"/auth/reset-password"}>Забыли пароль?</Link>
          </Form.Item>

          <Form.Item>
            <Button
              disabled={this.loginStore.loading}
              loading={this.loginStore.loading}
              size="large"
              className={styles.submit}
              type="primary"
              htmlType="submit"
            >
              Войти
            </Button>
          </Form.Item>
        </Form>
      </AuthFormContainer>
    );
  }
}

export default LoginPage;
