import { lazyInject, provide } from '../../utils/IoC';
import { HttpClientV1 } from '../../utils/api/HttpClientV1';
import { action, computed } from 'mobx';
import { FactoryModel } from './model/FactoryModel';
import { FactoryResponseDTO } from './dto/response/factory.response.dto';
import { AxiosResponse } from 'axios';
import { FormError } from '../../utils/error/FormError';
import { FactoryTableStore } from './FactoryTableStore';
import { UpdateFactoryRequestDTO } from './dto/request/update-factory.request.dto';

@provide.singleton()
export class FactoryEditModalStore {
  @lazyInject(HttpClientV1)
  protected readonly httpClientV1!: HttpClientV1;

  @lazyInject(FactoryTableStore)
  tableStore!: FactoryTableStore;

  @computed
  get itemsMap() {
    return this.tableStore.itemsMap;
  }

  @action
  updateFactory(id: string, model: FactoryModel) {
    return this.httpClientV1.post<UpdateFactoryRequestDTO, FactoryResponseDTO>(
      `/factories/${id}`,
      {
        name: model.name,
        fullName: model.fullName,
        country: model.country,
        city: model.city,
        address: model.address,
        otk_contact_info: model.otk,
      },
    ).then(this.onUpdateFactorySuccess).catch(this.onUpdateFactoryError) as Promise<FactoryModel>;
  }

  @action.bound
  protected onUpdateFactorySuccess(data: FactoryResponseDTO) {
    const model = FactoryModel.createFromDTO(data);

    if (this.itemsMap.has(data.id)) {
      this.itemsMap.set(model.id as string, model);
    }

    return model;
  }

  @action.bound
  protected onUpdateFactoryError(reason: AxiosResponse) {
    if (reason.status === 400) {
      const data = Object.entries(reason.data as Record<string, string>)
        .map(([ field, value ]) => [ field.replace('body.', ''), value ])
        .reduce((obj, [field, value]) => ({ ...obj, [field]: value }), {})
      ;

      throw FormError.createFromBadRequestResponseDTO(data);
    }
  }

}
