import React from 'react';
import { Result } from 'antd';

const ForbiddenPage: React.FC = () => (
  <Result
    status="403"
    title="403"
    subTitle="У Вас отсутствует доступ к разделам"
  />
);

export default ForbiddenPage;

